<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item>老师管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="老师关键字">
          <el-input v-model.trim="query.keyword"
                    placeholder="ID/姓名/手机号/备注"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="院校">
          <!-- <el-input
            v-model.trim="query.college"
            placeholder="请输入院校"
            @input="fetchList(true)"
            clearable
          ></el-input> -->
          <el-select style="width:150px;"
                     @clear="collegeClear"
                     @change="collegeChoose"
                     v-model.trim="query.college_code"
                     filterable
                     collapse-tags
                     placeholder="请选择"
                     clearable>
            <el-option v-for="(item, index) in $store.state.collegeList"
                       :key="index"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业">
          <el-select filterable
                     placeholder="请选择"
                     @change="fetchList(true)"
                     v-model.trim="query.major_code"
                     clearable
                     style="width: 150px">
            <el-option v-for="(item, index) in majorOptions"
                       :key="index"
                       :label="item.allnames"
                       :value="item.code">
            </el-option>
          </el-select>
          <!-- <el-input
            v-model.trim="query.major"
            placeholder="请输入专业"
            @input="fetchList(true)"
            clearable
          ></el-input> -->
        </el-form-item>
        <el-form-item label="班级">
          <el-input v-model.trim="query.grade_keyword"
                    placeholder="请输入班级关键字"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="学生关键字">
          <el-input v-model.trim="query.student_keyword"
                    placeholder="姓名/手机号"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="课酬状态">
          <el-select v-model="query.senior_course_order_statuses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="(item,index) in filters.senior_course_order_status"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!--    <el-form-item label="课时状态">
          <el-select
            v-model="query.lesson_statuses"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in filters.lesson_status"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form label-width="70px"
                 size="small"
                 style="margin-bottom:0px;display:flex;"
                 label-position="right">
          <el-form-item label-width="32px">
            <el-radio-group v-model="lesson_statuses"
                            @change="fetchList(true)">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button :label="1">待结算</el-radio-button>
              <el-radio-button :label="2">待结算（上周）</el-radio-button>
              <el-radio-button :label="999">待结算（上月）</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{fontSize: '13px' }"
                @selection-change="handleSelectionChange"
                border>
        <el-table-column type="selection"
                         width="50"
                         fixed="left"
                         align="center">
        </el-table-column>
        <el-table-column label="操作"
                         align="center"
                         fixed="left"
                         width="180">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToClass(scope.row)">班级</el-link>
            <el-link type="primary"
                     :underline="
              false"
                     @click="handleToClassHour(scope.row)">课时</el-link>
            <el-link type="primary"
                     :underline="
              false"
                     @click="handleToBill(scope.row)">课酬</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="ID"
                         align="center"
                         width="100">
        </el-table-column>

        <el-table-column prop="realname"
                         label="姓名"
                         align="center"
                         width="200"
                         fixed="left"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.realname }}{{ scope.row.hide_name_status?`（${scope.row.nickname}）`:'' }}
          </template>
        </el-table-column>
        <el-table-column label="性别"
                         align="center"
                         width="50">
          <template slot-scope="scope">
            {{scope.row.gender==1?'男':'女'}}
          </template>
        </el-table-column>
        <el-table-column label="确认状态"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.confirm_status == 1"
                    type="success">已确认</el-tag>
            <el-tag size="small"
                    v-if="scope.row.confirm_status == 0"
                    type="warning">未确认</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="考研年份"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            {{scope.row.recruit_year}}年
          </template>
        </el-table-column>
        <el-table-column label="录取信息"
                         align="left"
                         width="300"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="
              false"
                     @click="handleToOpenInfo(scope.row)">{{scope.row.recruit_college_name}}/({{scope.row.recruit_major_code}}){{scope.row.recruit_major_name}}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="课时费"
                         width="150"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="
              false"
                     @click="openTeacherClassAmountListDialog(scope.row)">{{ scope.row.teacher_average_lesson_hour_amount?scope.row.teacher_average_lesson_hour_amount.toFixed(2):0 }}元/小时</el-link>
          </template>
        </el-table-column>
        <el-table-column label="综合评分"
                         width="80"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="
              false"
                     @click="openTeacherRecordRateListDialog(scope.row)">{{ scope.row.teacher_average_student_apprise_score?(scope.row.teacher_average_student_apprise_score).toFixed(1)+'分':'暂无' }}</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="manage_remark"
                         label="备注"
                         width="300"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <!--    <el-table-column
          label="创建人"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column> -->
        <el-table-column label="创建日期"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.create_time?$moment(scope.row.create_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column label="修改日期"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{scope.row.update_time?$moment(scope.row.update_time).format("YYYY-MM-DD HH:mm"):""}}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         fixed="right"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToDetail(scope.row)">详情</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToEdit(scope.row)">编辑</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <div class="block">
          <div style="margin-right:16px;">共{{otherCount}}条<span v-if="Number(otherCount)>100">,涉及隐私仅显示前100条</span></div>
          <el-pagination background
                         layout="prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <EditTeacher :isVisible="editDialog"
                 @close="editDialog=false"
                 @submit="fetchList"
                 :id="editId" />
    <TeacherRecruitInfo :isVisible="infoDialog"
                        @close="infoDialog=false"
                        :id="editId" />
    <TeacherClassAmountList :isVisible="TeacherClassAmountListDialog"
                            @close="TeacherClassAmountListDialog=false"
                            :id="editId" />
    <TeacherRecordRateList :isVisible="TeacherRecordRateListDialog"
                           @close="TeacherRecordRateListDialog=false"
                           :id="editId" />
    <SeniorDetail :isVisible="SeniorDetailDialog"
                  @close="SeniorDetailDialog=false"
                  :id="detailId" />
  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import EditTeacher from '@/components/DialogComponents/ClassService/EditTeacher.vue'
import TeacherRecruitInfo from '@/components/DialogComponents/ClassService/TeacherRecruitInfo.vue'
import TeacherClassAmountList from '@/components/DialogComponents/ClassService/TeacherClassAmountList.vue'
import TeacherRecordRateList from '@/components/DialogComponents/ClassService/TeacherRecordRateList.vue'
import SeniorDetail from '@/components/DialogComponents/ClassService/SeniorDetail.vue'

import { teacherlist, majorlist } from '@/request/api'
export default {
  components: {
    TableTittle,
    EditTeacher,
    TeacherRecruitInfo,
    TeacherClassAmountList,
    TeacherRecordRateList,
    SeniorDetail,
  },
  data() {
    return {
      majorOptions: [],
      TableName: '老师列表',
      tableData: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      chooseList: [],
      editDialog: false,
      editId: null,
      infoDialog: false,
      TeacherClassAmountListDialog: false, // 老师列表课时费点击弹窗
      TeacherRecordRateListDialog: false, // 老师列表综合评分点击弹窗
      lesson_statuses: '',
      filters: {
        lesson_status: [
          { label: '待确认', value: 0 },
          { label: '待回访', value: 1 },
          { label: '已拒绝', value: 2 },
          { label: '已完成', value: 3 },
          { label: '已完结', value: 5 },
          { label: '已暂停', value: 6 },
        ],
        senior_course_order_status: [
          { label: '待审核', value: 0 },
          { label: '已通过', value: 1 },
          { label: '已拒绝', value: 2 },
          { label: '已提现', value: 3 },
        ],
      },
      SeniorDetailDialog: false,
      detailId: null,
      otherCount: 0,
    }
  },
  created() {
    this.parseQuery()
    this.fetchList()
    this.$store.dispatch('getAllColleges')
  },
  computed: {},
  methods: {
    collegeClear() {
      this.$set(this.query, 'major_code', '')
      this.majorOptions = []
      this.fetchList(true)
    },
    collegeChoose(e) {
      this.collegeClear()
      if (!e) {
        return
      }
      // console.log("e",e)
      let obj = {}
      obj = this.$store.state.collegeList.find((item) => {
        return item.code === e //筛选出匹配数据
      })
      let query = {
        isPaging: 0,
        college_code: obj.code,
      }
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`,
          }
        })
      })
      // this.form.apply_college_code = obj.code;
      // this.form.apply_college_name = obj.name;
      // this.getMajorOptions();
    },

    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)
      if (params.senior_course_order_statuses) {
        params.senior_course_order_statuses =
          params.senior_course_order_statuses.join(',')
      }
      if (this.lesson_statuses == 1) {
        params.lesson_log_lesson_statuses = '3'
      } else if (this.lesson_statuses == 2) {
        params.lesson_log_start_create_time = 0
        params.lesson_log_end_create_time = Number(
          this.$moment()
            .subtract(1, 'weeks') // 回退到上周
            .startOf('week') // 获取上周的周日 00:00:00
            .endOf('day') // 设置为 23:59:59.999
            .valueOf()
        ) // 获取时间戳
        params.lesson_log_settle_cycle_types = 2
        params.lesson_log_lesson_statuses = 3
      } else if (this.lesson_statuses == 999) {
        params.lesson_log_start_create_time = 0
        params.lesson_log_end_create_time =
          Number(
            this.$moment()
              .month(this.$moment().month())
              .startOf('month')
              .valueOf()
          ) - 1
        params.lesson_log_lesson_statuses = '3'
      } else {
        params.lesson_log_start_create_time = ''
        params.lesson_log_end_create_time = ''
        params.lesson_log_lesson_statuses = this.lesson_statuses
      }
      teacherlist(params).then((response) => {
        let res = response.data
        this.tableData = res.data.list || []
        if (res.data.count > 100) {
          console.log(res.data.count)
          this.otherCount = res.data.count
          this.pageTotal = 100
        } else {
          this.otherCount = res.data.count
          this.pageTotal = res.data.count || 0
        }
      })
      this.syncQuery()
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
      if (
        this.query.senior_course_order_statuses &&
        this.query.senior_course_order_statuses.length
      ) {
        this.query.senior_course_order_statuses =
          this.query.senior_course_order_statuses.split(',').map((v) => {
            return Number(v)
          })
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      if (params.senior_course_order_statuses) {
        params.senior_course_order_statuses =
          params.senior_course_order_statuses.filter((v) => v).join(',')
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    handleToEdit(row) {
      this.editId = row.id
      this.editDialog = true
    },
    handleToOpenInfo(row) {
      this.editId = row.id
      this.infoDialog = true
    },
    openTeacherClassAmountListDialog(row) {
      this.editId = row.id
      this.TeacherClassAmountListDialog = true
    },
    openTeacherRecordRateListDialog(row) {
      this.editId = row.id
      this.TeacherRecordRateListDialog = true
    },
    handleToClass(row) {
      window.open(`/classservice/class?teacher_id=${row.id}`)
    },
    handleToClassHour(row) {
      window.open(`/classservice/teacher/classhour??teacher_id=${row.id}`)
    },
    handleToBill(row) {
      window.open(`/classservice/teacher/bill?senior_id=${row.id}`)
    },
    handleToDetail(row) {
      this.detailId = row.id
      this.SeniorDetailDialog = true
    },
  },
}
</script>

<style lang="less" scoped>
.content {
}
</style>
