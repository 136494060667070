<template>
  <div>
    <el-dialog append-to-body
               top="5vh"
               :title="title"
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               :close-on-click-modal="false"
               width="50%"
               @open="open">
      <el-form :model="dataForm"
               label-position="right"
               ref="formRules"
               :rules="rules"
               label-width="100px"
               size="small">
        <el-form-item label="机构简称："
                      prop="cid">
          <el-select v-model="dataForm.cid"
                     placeholder="请选择"
                     style="width: 100%;"
                     @change="handleAccount"
                     filterable>
            <el-option v-for="item in $store.state.CompanyList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 必填项 -->
        <div class="chose"
             style="marginBottom:10px">
          <span class="blue"></span> <span style="fontSize:28rpx;font-weight: 700;">基础信息</span>（必填）
        </div>
        <el-form-item label="考研年份："
                      prop="apply_year">
          <el-select v-model="dataForm.apply_year"
                     placeholder="请选择考研年份"
                     style="width: 100%;"
                     clearable>
            <el-option label="2025级"
                       value="2025"></el-option>
            <el-option label="2026级"
                       value="2026"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考院校："
                      prop="apply_college">
          <el-select v-model="dataForm.apply_college"
                     style="width: 100%;"
                     placeholder="请选择院校"
                     filterable
                     clearable
                     @change="collegeChange">
            <el-option v-for="(item,index) in collegeList"
                       :key="index"
                       :label="item.name"
                       :value="{ value: item.code, label: item.name}">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考专业："
                      prop="apply_major">
          <el-select v-model="dataForm.apply_major"
                     placeholder="请选择专业"
                     style="width: 100%;"
                     @change="majorChange"
                     clearable
                     filterable>
            <el-option v-for="(item,index) in majorOptions"
                       :key="index"
                       :label="item.allnames"
                       :value="{ value: item.code, label: item.name}">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考学院："
                      prop="apply_academy">
          <el-select v-model="dataForm.apply_academy"
                     placeholder="请选择学院"
                     clearable
                     @change="academyChange"
                     style="width: 100%;">
            <el-option v-for="(item,index) in academyOptions"
                       :key="index"
                       :label="item.academy_name"
                       :value="{ value: item.academy_code, label: item.academy_name,directions:item.directions}">
              <span>{{ `（${item.academy_code}）${item.academy_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="辅导科目："
                      prop="apply_subjects">
          <el-select style="width:100%;"
                     v-model="dataForm.apply_subjects"
                     placeholder="请选择科目"
                     clearable
                     multiple
                     @change="$forceUpdate()">
            <el-option v-for="(item,index) in subjectOptions"
                       :key="index"
                       :label="item.subject_name"
                       :value="{ value: item.subject_code, label: item.subject_name}">
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="辅导小时："
                      prop="lesson_hours">
          <el-input placeholder="请输入辅导小时"
                    v-model="dataForm.lesson_hours"
                    class="input-with-select"
                    oninput="value=value.replace(/[^0-9.]/g,'')">
            <span slot="append">小时</span>
          </el-input>
        </el-form-item>
        <!-- 选填项 -->
        <div class="chose">
          <span class="blue"></span> <span style="fontSize:28rpx;font-weight: 700;">辅导要求</span>（选填）
        </div>
        <div class="text">
          根据您的实际需求选择对应的辅导要求，请注意辅导要求越多匹配难度就越大哦！
        </div>
        <div class="demand-wrap">
          <div class="demand-item">
            <div class="checkbox"
                 :class="{'check-checks':isDemand_max_amount}"
                 @click="isDemand_max_amountChange"></div>
            <div class="value">
              研究生时薪≤
              <el-input type="digit"
                        v-model="dataForm.demand_max_hour_amount"
                        placeholder="请输入"
                        @blur="computed()"
                        class="inputStyle"> </el-input>
              <text style="margin-left: 5rpx;">元/小时</text>
              <text style="font-size: 10px;color: rgba(0,0,0,0.6);">（默认值，可修改）</text>
            </div>
          </div>
          <div class="demand-item">
            <div class="checkbox"
                 :class="{'check-check':isDemand_direction}"
                 @click="isDemand_directionChange"></div>
            <div class="selectOrder">
              <div style="width:90px;font-size: 12px; color: black;">
                考试方向仅限是
              </div>
              <div>
                <el-select v-model="dataForm.demand_directions"
                           placeholder="请选择方向"
                           clearable
                           filterable
                           @focus="focus"
                           :disabled="disabled">
                  <el-option v-for="(item,index) in directions"
                             :key="index"
                             :label="item.direction"
                             :value="{ value: item.direction_code, label: item.direction}">
                  </el-option>
                </el-select>
                <image src="../../static/down_small.png"
                       mode=""></image>
              </div>
            </div>
          </div>

          <div class="demand-item">
            <div class="checkbox"
                 :class="{'check-check':isDemand_academy_major_status}"
                 @click="isDemand_academy_major_statusChange"></div>
            <div class="value">
              限与学员同一个学院专业
            </div>
          </div>
          <div class="demand-item">
            <div class="checkbox"
                 :class="{'check-check':demand_trial_listen_status}"
                 @click="demand_trial_listen_statusChange"></div>
            <div class="value">
              正式课程前，需要安排一节试听课
            </div>
          </div>
          <div class="demand-item">
            <div class="checkbox"
                 :class="{'check-check':demand_professional_experience_status}"
                 @click="demand_professional_experience_statusChange"></div>
            <div class="value">
              需要有对应的专业课辅导经验
            </div>
          </div>
          <div class="demand-item">
            <div class="checkbox"
                 :class="{'check-check':demand_before_first_rank}"
                 @click="demand_before_first_rankChange"></div>
            <div class="value">
              初试总分排名在前
              <div class="input-values">
                <el-input type="digit"
                          v-model="dataForm.demand_before_first_rank"
                          placeholder="请输入"
                          @input="handleRank()"
                          class="inputStyle" />
              </div>
              <text style="margin-left: 12px;">名</text>
            </div>
          </div>
          <div class="demand-item">
            <div class="checkbox"
                 :class="{'check-check':demand_min_first_total_score }"
                 @click="demand_min_first_total_scoreChange"></div>
            <div class="value">
              初试总分成绩不低于
              <div class="input-values">
                <el-input type="digit"
                          v-model="dataForm.demand_min_first_total_score "
                          placeholder="请输入"
                          class="inputStyle"
                          @input="handleStore()">
                </el-input>
              </div>
              <text style="margin-left: 12px;">分</text>
            </div>
          </div>
          <div class="demand-item">
            <div class="checkbox"
                 :class="{'check-check':demand_min_first_professional_score}"
                 @click="demand_min_first_professional_scoreChange"></div>
            <div class="value">
              专业课成绩不低于
              <div class="input-values">
                <el-input type="digit"
                          v-model="dataForm.demand_min_first_professional_score"
                          placeholder="请输入"
                          class="inputStyle"
                          @input="handleProfessional()">
                </el-input>
              </div>
              <text style="margin-left: 12px;">分</text>
            </div>
          </div>
          <div class="demand-item">
            <div class="checkbox"
                 :class="{'check-check':demand_same_reference_book_status}"
                 @click="demand_same_reference_book_statusChange"></div>
            <div class="value">
              允许其他院校研究生根据参考书或者大纲辅导
            </div>
          </div>
          <div class="demand-item"
               style="align-items: flex-start;">
            <div class="checkbox"
                 :class="{'check-check':isDemand_extra_info}"
                 @click="isDemand_extra_info=!isDemand_extra_info"></div>
            <div class="value"
                 style="flex-direction: column;align-items: flex-start;">
              其他要求
              <div class="textarea-wrap">
                <el-input type="textarea"
                          autosize
                          @input="remarkInput"
                          placeholder="请输入其他补充辅导要求，不超过50字。"
                          maxlength="50"
                          class="textarea"
                          v-model="dataForm.demand_extra_info">
                </el-input>
              </div>
            </div>
          </div>
        </div>
        <div class="chose"
             style="margin-top: 20px;">
          <span class="blue"></span> <span style="fontSize:28rpx;font-weight: 700;">排除老师</span>
        </div>
        <div class="text">
          添加已经联系无需推荐的老师姓名
        </div>
        <div class="addTeacher"
             @click="handleTeacher">
          <el-tag v-for="tag in excludeList"
                  :key="tag.value"
                  closable
                  type='success'
                  @close="closeTag(tag)">
            {{tag.title}}
          </el-tag> <span>+添加老师</span>
        </div>
        <div class="chose"
             style="margin-top: 20px;">
          <span class="blue"></span> <span style="fontSize:14px;font-weight: 700;">悬赏加急</span>
        </div>
        <div class="text">
          悬赏费用越高，则会更快被接单
        </div>
        <div class="recharge">
          <div v-for="(item,index) in rewardExpedited"
               :class="rechargeNum==index?'choses':''"
               @click="choseRecharge(item,index)"
               :key="index">
            {{item.label}}
            <!-- <image src="../../static/tag.png" mode="" class="discounts-img" v-if="item.flag"></image> -->
          </div>
        </div>
        <div class="chose"
             style="margin-top: 20px;">
          <span class="blue"></span> <span style="fontSize:28rpx;font-weight: 700;">截止时间</span>
        </div>
        <div class="text"
             style="margin-bottom: 10px;">
          截止时间前若还没有人接单，系统将自动取消当前订单。
        </div>
        <el-date-picker v-model="deadline_time"
                        format="yyyy-MM-dd HH:mm"
                        type="datetime"
                        placeholder="选择日期时间"
                        value-format="timestamp"
                        @change="time()">
        </el-date-picker>
      </el-form>
      <div class="footer">
        <div class="footerLeft">
          <span class="recommed">预估推荐费：</span>
          <div class="price"
               v-if="predict_amount_start&&predict_amount_end&&flag">
            <div v-if="minObject.id">
              <div class=""
                   v-if="(Number(predict_amount_start)-minObject.share_single_coupon_obj.single_circulation_amount).toFixed(2) != (Number(predict_amount_end) -minObject.share_single_coupon_obj.single_circulation_amount).toFixed(2)">
                <span>￥{{(Number(predict_amount_start)-minObject.share_single_coupon_obj.single_circulation_amount).toFixed(2)}}~￥{{(Number(predict_amount_end) -minObject.share_single_coupon_obj.single_circulation_amount).toFixed(2)}}</span>
              </div>
              <div class=""
                   v-else>
                <span>￥{{(Number(predict_amount_start)-minObject.share_single_coupon_obj.single_circulation_amount).toFixed(2)}}</span>
              </div>
            </div>
            <div class=""
                 v-else>
              <div v-if="predict_amount_start != predict_amount_end">
                <span>￥{{predict_amount_start}} ~ ￥{{predict_amount_end}}</span>
              </div>
              <div v-if="predict_amount_start == predict_amount_end">
                <span>￥{{predict_amount_start}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="footerRight">
          <el-button @click="handleToClose(false)">取消</el-button>
          <el-button type="primary"
                     @click="lookPrice()"
                     v-if="!flag">查看推荐费</el-button>
          <el-button type="primary"
                     @click="submit('formRules')"
                     v-if="flag&&title=='新建匹配需求'"> 立即发布</el-button>
          <el-button type="primary"
                     @click="submit('formRules')"
                     v-if="flag&&title=='修改订单'"> 提交</el-button>
        </div>
      </div>
      <!-- 添加联系老师 -->
      <el-dialog append-to-body
                 top="5vh"
                 title="添加老师"
                 :visible.sync="addTeacherVisible"
                 @close="addTeacherVisible=false"
                 :close-on-click-modal="false"
                 width="30%">
        <el-form :model="addForm"
                 label-position="right"
                 ref="formRules"
                 :rules="rules"
                 label-width="100px"
                 size="small">
          <!-- 必填项 -->
          <el-form-item label="考研年份：">
            <el-select v-model="addForm.apply_year"
                       placeholder="请选择考研年份"
                       style="width: 100%;"
                       clearable>
              <el-option label="2021级"
                         value="2021"></el-option>
              <el-option label="2022级"
                         value="2022"></el-option>
              <el-option label="2023级"
                         value="2023"></el-option>
              <el-option label="2024级"
                         value="2024"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="老师姓名：">
            <el-input type="text"
                      placeholder="请输入老师姓名"
                      v-model="addForm.teacherName" />
          </el-form-item>
        </el-form>
        <div class="footer"
             style="justify-content: flex-end;">
          <el-button @click="addTeacherVisible=false">取消</el-button>
          <el-button type="primary"
                     @click="addSubmit()">确定</el-button>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import {
  collegeList,
  subjectlist,
  majorlist,
  companyuserlist,
} from '@/request/api'
import { adminUrl, crmUrl } from '@/utils/helper'
export default {
  data() {
    return {
      title: '新建匹配需求',
      flag: false,
      dialogFormVisible: false,
      disabled: false,
      addTeacherVisible: false,
      deadline_time: null,
      rechargeNum: 0,
      fixationPrice: 0,
      dataForm: {
        apply_subjects: [],
        demand_max_hour_amount: 160,
        // in_urgency_amount: 10,
      },
      addForm: {},
      collegeList: [],
      directions: [],
      directionOptions: [],
      rewardExpedited: [
        {
          label: '不加急',
          value: 0,
        },
        {
          label: '+50元',
          value: 50,
        },
        {
          label: '+100元',
          value: 100,
          flag: true,
        },
        {
          label: '+150元',
          value: 150,
        },
        {
          label: '+200元',
          value: 200,
        },
        {
          label: '+300元',
          value: 300,
        },
      ],
      rules: {
        lesson_hours: [
          {
            required: true,
            message: '请选择辅导课时',
            trigger: 'blur',
          },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: '输入最多两位小数的数字',
          },
        ],
        cid: [
          {
            required: true,
            message: '请选择机构名称',
            trigger: 'change',
          },
        ],
        apply_year: [
          {
            required: true,
            message: '请选择考研年份',
            trigger: 'change',
          },
        ],
        apply_college: [
          {
            required: true,
            message: '请选择院校',
            trigger: 'change',
          },
        ],
        apply_major: [
          {
            required: true,
            message: '请选择专业',
            trigger: 'change',
          },
        ],
        apply_academy: [
          {
            required: true,
            message: '请选择学院',
            trigger: 'change',
          },
        ],
        apply_subjects: [
          {
            required: true,
            message: '请选择科目',
            trigger: 'change',
          },
        ],
      },
      minObject: {
        share_single_coupon_obj: {},
      },
      excludeList: [],
      majorOptions: [], // 专业数组
      academyOptions: [], // 学院数组
      subjectOptions: [], //科目数组
      isDemand_max_amount: true, //研究生时薪
      isDemand_direction: false, //考试方向
      isDemand_academy_major_status: false, //与学员同一个专业
      demand_trial_listen_status: false, //试听课
      demand_professional_experience_status: false, //辅导经验
      demand_same_reference_book_status: false, //参考书或者辅导大纲
      demand_before_first_rank: false, //初试总分排名
      demand_min_first_total_score: false, //初试总分成绩
      demand_min_first_professional_score: false, //专业课成绩
      isDemand_extra_info: false, //其他要求
      predict_amount_end: 0,
      predict_amount_start: 0,
      detail: {},
      type: 0,
    }
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    titles: {
      type: String,
      default: () => {
        return ''
      },
    },
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
    },
    titles(val) {
      this.title = val
    },
    fixationPrice(val) {
      this.computed()
    },
  },
  methods: {
    time() {},
    handleAccount() {
      let query = {
        isPaging: 0,
        cid: this.dataForm.cid,
      }
      companyuserlist(query).then((res) => {
        this.dataForm.uid = res.data.data.list[0].uid
      })
    },
    open() {
      let data = {
        isPaging: 0,
      }
      collegeList(data).then((res) => {
        this.collegeList = res.data.data.list
      })
      this.getShareCouponList()

      if (this.title == '修改订单') {
        this.handleEcho()
      } else {
        this.dataForm = {
          apply_subjects: [],
          demand_max_hour_amount: 160,
          // in_urgency_amount: 0,
        }
        this.isDemand_max_amount = true
      }
    },
    lookPrice() {
      this.flag = true
      this.computed()
    },
    handleToClose() {
      this.$refs['formRules'].resetFields()
      setTimeout(() => {
        this.$emit('close')
        this.cancle()
        this.flag = false
      }, 300)
    },
    closeTag(val) {
      this.excludeList = this.excludeList.filter((v) => {
        return v.value != val.value
      })
    },
    handleTeacher() {
      this.addTeacherVisible = true
    },
    choseRecharge(item, index) {
      this.rechargeNum = index
      this.fixationPrice = item.value
    },
    getObjectWithMinValue(objects, key) {
      objects.sort(function (a, b) {
        return (
          b.share_single_coupon_obj.single_circulation_amount -
          a.share_single_coupon_obj.single_circulation_amount
        )
      })
      return objects[0]
    },
    getShareCouponList() {
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/company/user/shareCoupon/list`,
        method: 'get', //可以省略 不写默认是get
        params: {
          pageIndex: 1,
          pageSize: 999,
          coupon_types: 1,
          status: 0,
        },
      }).then((res) => {
        if (res.data.data.list.length > 0) {
          this.minObject = this.getObjectWithMinValue(res.data.data.list)
        }
      })
    },
    computed() {
      if (
        !this.dataForm.apply_college ||
        !this.dataForm.apply_major ||
        !this.dataForm.apply_academy
      ) {
        return
      }
      if (!this.dataForm.apply_subjects) {
        return
      }
      if (!this.dataForm.lesson_hours) {
        return
      }
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrder/findFeeDetail`,
        method: 'post', //可以省略 不写默认是get
        data: {
          cid: this.dataForm.cid,
          uid: this.dataForm.uid,
          apply_year: this.dataForm.apply_year,
          apply_college_code: this.dataForm.apply_college
            ? this.dataForm.apply_college.value
            : '',
          apply_college_name: this.dataForm.apply_college
            ? this.dataForm.apply_college.label
            : '',
          apply_major_code: this.dataForm.apply_major
            ? this.dataForm.apply_major.value
            : '',
          apply_major_name: this.dataForm.apply_major
            ? this.dataForm.apply_major.label
            : '',
          apply_academy_code: this.dataForm.apply_academy
            ? this.dataForm.apply_academy.value
            : '',
          apply_academy_name: this.dataForm.apply_academy
            ? this.dataForm.apply_academy.label
            : '',
          lesson_hours: this.dataForm.lesson_hours,
          apply_subject_names:
            this.dataForm.apply_subjects && this.dataForm.apply_subjects.length
              ? this.dataForm.apply_subjects
                  .map((v) => {
                    return v.label
                  })
                  .join(',')
              : '',
          apply_subject_codes:
            this.dataForm.apply_subjects && this.dataForm.apply_subjects.length
              ? this.dataForm.apply_subjects
                  .map((v) => {
                    return v.value
                  })
                  .join(',')
              : '',
          demand_direction_codes: this.isDemand_direction
            ? this.dataForm.demand_directions.value
            : null,
          demand_direction_names: this.isDemand_direction
            ? this.dataForm.demand_directions.label
            : null,
          demand_max_hour_amount: this.isDemand_max_amount
            ? this.dataForm.demand_max_hour_amount
            : null,
          demand_academy_major_status: this.isDemand_academy_major_status
            ? 1
            : 0,
          demand_extra_info: this.isDemand_extra_info
            ? this.dataForm.demand_extra_info
            : null,
          in_urgency_amount: this.fixationPrice,
          demand_same_reference_book_status: this
            .demand_same_reference_book_status
            ? 1
            : 0,
          demand_trial_listen_status: this.demand_trial_listen_status ? 1 : 0,
          demand_professional_experience_status: this
            .demand_professional_experience_status
            ? 1
            : 0,
          demand_before_first_rank: this.demand_before_first_rank
            ? this.dataForm.demand_before_first_rank
            : null,
          demand_min_first_total_score: this.demand_min_first_total_score
            ? this.dataForm.demand_min_first_total_score
            : null,
          demand_min_first_professional_score: this
            .demand_min_first_professional_score
            ? this.dataForm.demand_min_first_professional_score
            : null,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.predict_amount_end = res.data.data.predict_amount_end
          this.predict_amount_start = res.data.data.predict_amount_start
          console.log(' this.predict_amount_start ', this.predict_amount_start)
          console.log(' this.predict_amount_end ', this.predict_amount_end)
        }
      })
    },
    cancle() {
      this.dataForm.demand_max_hour_amount = '160'
      this.dataForm.apply_subjects = []
      // this.dataForm = {
      //   demand_max_hour_amount: '160',
      //   apply_subjects: [],
      // }
      this.isDemand_direction = false
      this.isDemand_max_amount = false
      this.isDemand_academy_major_status = false
      this.isDemand_extra_info = false
      this.fixationPrice = null
      this.demand_same_reference_book_status = false
      this.demand_trial_listen_status = false
      this.demand_professional_experience_status = false
      this.demand_before_first_rank = false
      this.demand_min_first_total_score = false
      this.demand_min_first_professional_score = false
      this.excludeList = []
      this.subjectOptions = []
      this.deadline_time = null
      this.fixationPrice = 0
      this.rechargeNum = 0
      this.type = 1
    },
    addSubmit() {
      if (!this.addForm.apply_year) {
        this.$message.error('请选择考研年份')
        return
      }
      if (!this.addForm.teacherName) {
        this.$message.error('请输入老师姓名')
        return
      }
      this.excludeList.push({
        label: this.addForm.teacherName,
        value: this.addForm.apply_year,
        title: this.addForm.apply_year + '级' + '|' + this.addForm.teacherName,
      })
      this.addTeacherVisible = false
      this.addForm = {}
    },
    focus() {
      if (
        !this.dataForm.apply_college ||
        !this.dataForm.apply_major ||
        !this.dataForm.apply_academy
      ) {
        this.$message.error('请先选择院校、专业和学院')

        return
      }
    },
    isDemand_max_amountChange() {
      this.$message.error('此项为必选')
      this.isDemand_max_amount = true
      this.computed()
    },
    isDemand_academy_major_statusChange() {
      if (this.demand_same_reference_book_status) {
        this.isDemand_academy_major_status = true
        this.demand_same_reference_book_status = false
      } else {
        this.isDemand_academy_major_status = !this.isDemand_academy_major_status
      }
      this.computed()
    },
    demand_min_first_total_scoreChange() {
      this.demand_min_first_total_score = !this.demand_min_first_total_score
      this.computed()
    },
    demand_min_first_professional_scoreChange() {
      this.demand_min_first_professional_score =
        !this.demand_min_first_professional_score

      this.computed()
    },
    demand_trial_listen_statusChange() {
      this.demand_trial_listen_status = !this.demand_trial_listen_status
      this.computed()
    },
    demand_before_first_rankChange() {
      this.demand_before_first_rank = !this.demand_before_first_rank
      this.computed()
    },
    demand_professional_experience_statusChange() {
      this.demand_professional_experience_status =
        !this.demand_professional_experience_status
      this.computed()
    },
    handleRank() {
      if (this.dataForm.demand_before_first_rank.length > 0) {
        this.demand_before_first_rank = true
      } else if (this.dataForm.demand_before_first_rank.length == 0) {
        this.demand_before_first_rank = false
      }
    },
    handleStore() {
      if (this.dataForm.demand_min_first_total_score.length > 0) {
        this.demand_min_first_total_score = true
      } else if (this.dataForm.demand_min_first_total_score.length == 0) {
        this.demand_min_first_total_score = false
      }
    },
    handleProfessional() {
      if (this.dataForm.demand_min_first_professional_score.length > 0) {
        this.demand_min_first_professional_score = true
      } else if (
        this.dataForm.demand_min_first_professional_score.length == 0
      ) {
        this.demand_min_first_professional_score = false
      }
    },
    demand_same_reference_book_statusChange() {
      if (this.isDemand_academy_major_status) {
        this.demand_same_reference_book_status = true
        this.isDemand_academy_major_status = false
      } else {
        this.demand_same_reference_book_status =
          !this.demand_same_reference_book_status
      }
      this.computed()
    },
    remarkInput(e) {
      if (this.dataForm.demand_extra_info.length > 0) {
        this.isDemand_extra_info = true
      } else {
        this.isDemand_extra_info = false
      }
    },
    isDemand_directionChange() {
      this.isDemand_direction = !this.isDemand_direction
      this.computed()
    },
    // 选择院选和专业
    getCollege() {},
    collegeChange(e) {
      this.dataForm.apply_major = {}
      this.majorOptions = []
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.dataForm.apply_direction = {}
      this.directionsOptions = []
      if (e) {
        this.getMajor(e.value)
      }
      // this.computed()
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code * 1,
      }
      majorlist(query).then((res) => {
        this.majorOptions = res.data.data.list.map((v) => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`,
          }
        })
      })
    },
    majorChange(e) {
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.dataForm.apply_direction = {}
      this.directionsOptions = []
      if (e) {
        this.getMajorInfo(this.dataForm.apply_college.value, e.value)
      }
      // this.computed()
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get', //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let res = response.data.data
        this.directions = res.academys[0].directions
        this.academyOptions = res.academys
        if (res.academys.length == 1) {
          this.$set(this.dataForm, 'apply_academy', {
            label: res.academys[0].academy_name,
            value: res.academys[0].academy_code,
          })
          this.getSubjects()
        }
      })

      // majorinfo({
      // 	data
      // }).then(response => {
      // 	let res = response.data
      // 	this.directions = res.academys[0].directions

      // 	this.academyOptions = res.academys

      // 	if (res.academys.length == 1) {
      // 		this.$set(this.dataForm, 'apply_academy', {
      // 			label: res.academys[0].academy_name,
      // 			value: res.academys[0].academy_code,
      // 		})
      // 		this.getSubjects();
      // 	}
      // })
    },
    // getAcedemy(college_code, major_code) {
    //   let query = {
    //     isPaging: 0,
    //     college_code: college_code,
    //     major_code: major_code,
    //   }
    //   academyList(query).then((response) => {
    //     let res = response.data
    //     this.academyOptions = res.data.list || []
    //   })
    // },
    academyChange(e) {
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.dataForm.apply_direction = {}
      this.directionsOptions = []
      this.$forceUpdate()
      if (e) {
        this.directionsOptions = this.academyOptions.find((v) => {
          return this.dataForm.apply_academy.value == v.academy_code
        }).directions
        this.getSubjects()
      }
      // this.computed()
    },
    getSubjects() {
      let query = {
        isPaging: 0,
        college_code: this.dataForm.apply_college.value,
        academy_code: this.dataForm.apply_academy.value,
        major_code: this.dataForm.apply_major.value,
      }
      subjectlist(query).then((res) => {
        this.subjectOptions = res.data.data.list || []
        if (res.data.data.list.length == 1) {
          this.$set(this.dataForm, 'apply_subjects', {
            label: this.subjectOptions[0].subject_name,
            value: this.subjectOptions[0].subject_code,
          })
        }
      })
    },
    // 选择院选和专业

    submit(rules) {
      // **********
      // 选提交注意cid是调接口获取的
      //************
      this.$refs[rules].validate((valid) => {
        if (valid) {
          if (isNaN(this.dataForm.lesson_hours)) {
            this.$message.error('辅导小时请输入数字')
            return
          }

          if (
            this.isDemand_max_amount &&
            !this.dataForm.demand_max_hour_amount
          ) {
            this.$message.error('请输入研究生最大时薪')
            return
          }

          if (
            this.isDemand_max_amount &&
            isNaN(this.dataForm.demand_max_hour_amount)
          ) {
            this.$message.error('研究生最大时薪请输入数字')
            return
          }
          if (!/^(0?|-?[1-9]\d*)$/.test(this.dataForm.demand_max_hour_amount)) {
            this.$message.error('研究生时薪资请输入正整数')

            return
          }

          if (this.isDemand_extra_info && !this.dataForm.demand_extra_info) {
            this.$message.error('请输入其他要求')
            return
          }
          if (this.subjectFlag && !this.isDemand_direction) {
            this.$message.error('请勾选考试方向')

            return
          }
          if (this.isDemand_direction && !this.dataForm.demand_directions) {
            this.$message.error('请选择考试方向')

            return
          }
          if (this.demand_before_first_rank) {
            if (
              !/^(0?|-?[1-9]\d*)$/.test(this.dataForm.demand_before_first_rank)
            ) {
              this.$message.error('初试总分排名请输入正整数')

              return
            }
          }

          if (this.demand_min_first_total_score) {
            if (
              !/^(500|[1-4]{0,1}\d{0,1}\d)$/.test(
                this.dataForm.demand_min_first_total_score
              )
            ) {
              this.$message.error('初试总分请输入大于0小于500的正整数')

              return
            }
          }
          if (this.demand_min_first_professional_score) {
            if (
              !/^(300|[1-2]{0,1}\d{0,1}\d)$/.test(
                this.dataForm.demand_min_first_professional_score
              )
            ) {
              this.$message.error('专业课成绩请输入大于0小于300的正整数')

              return
            }
          }
          let excludeString = []
          if (this.excludeList.length > 0) {
            this.excludeList.forEach((item) => {
              excludeString.push(item.title)
            })
          }
          if (this.title == '新建匹配需求') {
            let query = {
              // id: this.dataForm.id,
              auto_cancel_time: this.deadline_time || null,
              cid: this.dataForm.cid,
              uid: this.dataForm.uid,
              apply_college_code: this.dataForm.apply_college
                ? this.dataForm.apply_college.value
                : '',
              apply_college_name: this.dataForm.apply_college
                ? this.dataForm.apply_college.label
                : '',
              apply_major_code: this.dataForm.apply_major
                ? this.dataForm.apply_major.value
                : '',
              apply_major_name: this.dataForm.apply_major
                ? this.dataForm.apply_major.label
                : '',
              apply_academy_code: this.dataForm.apply_academy
                ? this.dataForm.apply_academy.value
                : '',
              apply_academy_name: this.dataForm.apply_academy
                ? this.dataForm.apply_academy.label
                : '',
              apply_year: this.dataForm.apply_year,
              lesson_hours: this.dataForm.lesson_hours,
              apply_subject_names:
                this.dataForm.apply_subjects &&
                this.dataForm.apply_subjects.length
                  ? this.dataForm.apply_subjects
                      .map((v) => {
                        return v.label
                      })
                      .join(',')
                  : '',
              apply_subject_codes:
                this.dataForm.apply_subjects &&
                this.dataForm.apply_subjects.length
                  ? this.dataForm.apply_subjects
                      .map((v) => {
                        return v.value
                      })
                      .join(',')
                  : '',
              demand_direction_codes: this.isDemand_direction
                ? this.dataForm.demand_directions.value
                : null,
              demand_direction_names: this.isDemand_direction
                ? this.dataForm.demand_directions.label
                : null,
              demand_max_hour_amount: this.isDemand_max_amount
                ? this.dataForm.demand_max_hour_amount
                : null,
              demand_academy_major_status: this.isDemand_academy_major_status
                ? 1
                : 0,
              demand_extra_info: this.isDemand_extra_info
                ? this.dataForm.demand_extra_info
                : null,
              in_urgency_amount: this.fixationPrice,
              exclude_year_teachers: excludeString.toString(),
              company_user_pay_coupon_id: this.minObject.id
                ? this.minObject.id
                : null,
              demand_same_reference_book_status: this
                .demand_same_reference_book_status
                ? 1
                : 0,
              demand_trial_listen_status: this.demand_trial_listen_status
                ? 1
                : 0,
              demand_professional_experience_status: this
                .demand_professional_experience_status
                ? 1
                : 0,
              demand_before_first_rank: this.demand_before_first_rank
                ? this.dataForm.demand_before_first_rank
                : null,
              demand_min_first_total_score: this.demand_min_first_total_score
                ? this.dataForm.demand_min_first_total_score
                : null,
              demand_min_first_professional_score: this
                .demand_min_first_professional_score
                ? this.dataForm.demand_min_first_professional_score
                : null,
            }
            let str = crmUrl()
            this.$axios({
              url: `${str}/api/v1/sharePlatform/findOrder`,
              method: 'post', //可以省略 不写默认是get
              data: query,
            }).then((res) => {
              if (res.data.code == 0) {
                this.$message.success('订单创建成功')
                this.handleToClose()
                this.$emit('submit')
              }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .el-dialog__header {
  background-color: #ecf2fe !important;
}
::v-deep .el-input__inner {
  height: 30px !important;
}
::v-deep .el-textarea__inner {
  min-height: 100px !important;
}

.choses {
  color: #266fe8 !important;
  background: #ecf2fe;
  border: 1px solid #266fe8 !important;
}

.inputStyle {
  margin-left: 5px;
  text-align: center;
  // font-size: 15px;
  width: 75px;
  // height: 10px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}

.selectOrder {
  height: 20px;
  display: flex;
  align-items: center;
}

.textarea {
  // border: 1px solid #dcdfe6;
  background-color: #fff !important;
}

.chose {
  display: flex;
  align-items: center;

  .blue {
    margin-right: 6px;
    display: inline-block;
    width: 4px;
    height: 16px;
    border-radius: 2px;
    background: #409eff;
  }
}

.text {
  margin-top: 5px;
  font-size: 12px;
}

.addTeacher {
  margin-top: 10px;
  color: #409eff;
}

.demand-wrap {
  overflow: hidden;

  .demand-item {
    display: flex;
    align-items: center;
    margin-top: 16px;

    .checkbox {
      border-radius: 2px;
      border: 2px #d6dbe3 solid;
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }

    .check-check {
      background-image: url('https://upload.kaoyanbox.net/65a3249c75dee4629391278569bdd364.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border: none;
      width: 17px;
      height: 17px;
    }

    .check-checks {
      background-image: url('https://upload.kaoyanbox.net/bfa2fe1bab27c519815030619a3cf5b8.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border: none;
      width: 17px;
      height: 17px;
    }

    .value {
      flex: 1;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.9);

      .textarea-wrap {
        width: 100%;
        margin-top: 4px;

        // /deep/textarea {
        //   font-size: 24px;
        // }

        // /deep/.u-textarea {
        //   padding: 24px;
        //   background: #f6f7f8;

        //   .uni-textarea-textarea {
        //     font-size: 24px;
        //     color: rgba(0, 0, 0, 0.9);
        //   }
        // }

        // /deep/.uni-textarea-placeholder {
        //   font-size: 24px;
        // }
      }
    }
  }
}

.recharge {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > div:last-child:nth-child(3n-1) {
    margin-right: calc((100% - 313px) / 2 + 111px);
  }

  & > div {
    display: flex;
    margin-right: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 28px;
    background: #ffffff;
    border-radius: 4px;
    border: 2px solid transparent;
    border: 1px solid #c5c5c5;
    box-sizing: border-box;
    font-family: PingFangSC-Medium, PingFang SC;
    margin-top: 12px;
    position: relative;
    font-size: 14px;

    .pitch-img {
      width: 16px;
      height: 16px;
      position: absolute;
      right: -1px;
      bottom: -1px;
    }

    .discounts-img {
      width: 34px;
      height: 16px;
      position: absolute;
      top: -1px;
      right: -1px;
    }
  }

  .chose {
    color: #266fe8 !important;
    background: #ecf2fe;
    border: 1px solid #266fe8;
  }
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .footerLeft {
    display: flex;

    .recommed {
      font-weight: 700;
      font-size: 16px;
      color: black;
      // color: #E34E5A;
    }

    .price {
      font-size: 16px;
      font-weight: 700;
      color: #e34e5a;
    }
  }

  .footerRight {
    display: flex;
    justify-content: flex-end;
  }
}
</style>