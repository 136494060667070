<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资</el-breadcrumb-item>
        <el-breadcrumb-item>老师管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button type="primary"
                   size="small"
                   @click="openDataStatistics">数据统计</el-button>
        <el-button type="primary"
                   size="small"
                   @click="openCreateDialog">新建</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="搜索">
          <el-input style="width: 150px"
                    v-model.trim="query.keyword"
                    placeholder="请输入姓名/手机号"
                    @input="searchSetTimeout"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="年份"
                      prop="recruit_years">
          <el-select style="width: 100px"
                     v-model="query.recruit_years"
                     placeholder="请选择"
                     @change="fetchList(true)">
            <el-option label="2020年"
                       value="2020"></el-option>
            <el-option label="2021年"
                       value="2021"></el-option>
            <el-option label="2022年"
                       value="2022"></el-option>
            <el-option label="2023年"
                       value="2023"></el-option>
            <el-option label="2024年"
                       value="2024"></el-option>
            <el-option label="2025年"
                       value="2025"></el-option>
          </el-select>
        </el-form-item>
        <!-- @clear="collegeClear" -->
        <el-form-item label="院校">
          <el-select style="width: 150px"
                     @change="collegeChoose"
                     v-model.trim="query.college_code"
                     filterable
                     collapse-tags
                     placeholder="请选择"
                     clearable>
            <el-option v-for="(item, index) in $store.state.collegeList"
                       :key="index"
                       :label="item.name"
                       :value="item.code">
            </el-option>
          </el-select>
          <!-- <el-input
          style="width:150px"
            v-model.trim="query.college"
            placeholder="请输入院校名/代码"
            @input="fetchList(true)"
            clearable
          ></el-input> -->
        </el-form-item>
        <el-form-item label="专业">
          <!-- <el-select
            filterable
            placeholder="请选择"
            @change="fetchList(true)"
            v-model.trim="query.major_code"
            clearable
            style="width: 150px"
          >
            <el-option
              v-for="(item, index) in majorOptions"
              :key="index"
              :label="`(${item.code})${item.name}`"
              :value="item.code"
            >
            </el-option>
          </el-select> -->
          <el-input style="width: 150px"
                    v-model.trim="query.major"
                    placeholder="请输入专业名/代码"
                    @input="majorQuery"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="学院">
          <el-input style="width: 150px"
                    v-model.trim="query.academy"
                    placeholder="请输入学院名/代码"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="科目">
          <el-input style="width: 150px"
                    v-model.trim="query.test_subject_keyword"
                    placeholder="请输入初试科目"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="复试">
          <el-input style="width: 150px"
                    v-model.trim="query.second_test_subject_keyword"
                    placeholder="请输入复试科目"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="参考书">
          <el-input style="width: 150px"
                    v-model.trim="query.reference_book_keyword"
                    placeholder="请输入参考书"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="确认状态"
                      prop="confirm_statuses">
          <el-select style="width: 100px"
                     v-model="query.confirm_statuses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable>
            <el-option label="已确认"
                       :value="1"></el-option>
            <el-option label="未确认"
                       :value="0"></el-option>
            <el-option label="已驳回"
                       :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="签约状态"
                      prop="contract_statuses">
          <el-select style="width: 100px"
                     v-model="query.contract_statuses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable>
            <el-option label="已签约"
                       value="1"></el-option>
            <el-option label="未签约"
                       value="0"></el-option>
            <el-option label="已过期"
                       value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="同步状态">
          <el-select style="width: 100px"
                     v-model="query.pre_teacher_sync_status"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable>
            <el-option label="已同步"
                       value="1"></el-option>
            <el-option label="未同步"
                       value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="首次签约人"
                      prop="contract_statuses">
          <el-select filterable
                     placeholder="请选择"
                     @change="fetchList(true)"
                     v-model.trim="query.first_contract_manage_ids"
                     clearable
                     style="width: 150px">
            <el-option v-for="(item, index) in $store.state.staffOptions"
                       :key="index"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- format="yyyy-MM-dd HH:mm" -->
        <el-form-item label="首次签约时间">
          <el-date-picker clearable
                          @change="fetchList(true)"
                          v-model="time"
                          type="datetimerange"
                          value-format="timestamp"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form label-width="70px"
                 size="small"
                 style="margin-bottom: 0px; display: flex"
                 label-position="right">
          <el-form-item label-width="32px">
            <el-radio-group v-model="query.archive"
                            @change="fetchList(true)">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button label="1">已归档</el-radio-button>
              <el-radio-button label="0">未归档</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="result"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                :cell-style="{ fontSize: '13px' }"
                @selection-change="handleSelectionChange"
                border>
        <el-table-column type="selection"
                         width="50"
                         align="center">
        </el-table-column>
        <el-table-column label="沟通记录"
                         fixed="left"
                         align="center"
                         width="80">
          <template slot-scope="scope">
            <!-- <el-link
              type="primary"
              :underline="false"
              @click="handleToRecord(scope.row)"
            >查看</el-link> -->
            <div class="badge">
              <el-badge :value="
                  scope.row.record_alt_unreaded_count &&
                  scope.row.record_alt_unreaded_count > 0
                    ? '@'
                    : scope.row.record_msg_unreaded_count
                "
                        class="item"
                        :hidden="
                  !scope.row.record_alt_unreaded_count &&
                  !scope.row.record_msg_unreaded_count
                ">
                <el-link type="primary"
                         :underline="false"
                         @click="handleToRecord(scope.row)">查看</el-link>
              </el-badge>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="id"
                         label="ID"
                         align="center"
                         width="80">
        </el-table-column>
        <el-table-column prop="realname"
                         label="姓名"
                         align="center"
                         width="200"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="tag-wrap">
              <img src="@/assets/images/unconfirm_tag.png"
                   alt=""
                   srcset=""
                   class="unconfirm_tag"
                   v-if="
                  scope.row.unconfirm_fields
                    ? scope.row.unconfirm_fields.split(',').findIndex((v) => {
                        return v == 'realname';
                      }) != -1
                    : false
                " />
              {{ scope.row.realname
              }}{{
                scope.row.hide_name_status ? `（${scope.row.nickname}）` : ""
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="考研年份"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            <div class="tag-wrap">
              <img src="@/assets/images/unconfirm_tag.png"
                   alt=""
                   srcset=""
                   class="unconfirm_tag"
                   v-if="
                  scope.row.unconfirm_fields
                    ? scope.row.unconfirm_fields.split(',').findIndex((v) => {
                        return v == 'recruit_year';
                      }) != -1
                    : false
                " />
              {{ scope.row.recruit_year }}年
            </div>
          </template>
        </el-table-column>
        <el-table-column label="确认状态"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.confirm_status == 1"
                    type="success">已确认</el-tag>
            <el-tag size="small"
                    v-if="scope.row.confirm_status == 0">未确认</el-tag>
            <el-tag type="danger"
                    size="small"
                    v-if="scope.row.confirm_status == -1">未录取</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="签约状态"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.contract_status == 1"
                    type="success">已签约</el-tag>
            <el-tag size="small"
                    v-if="scope.row.contract_status == 0"
                    type="warning">未签约</el-tag>
            <el-tag size="small"
                    v-if="scope.row.contract_status == 2"
                    type="danger">已过期</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="同步状态"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.share_pre_teacher_sync_status == 1">已同步</el-tag>
            <el-tag size="small"
                    v-if="scope.row.share_pre_teacher_sync_status == 0"
                    type="warning">未同步</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="预留师资状态"
                         align="center"
                         width="120"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.share_pre_teacher_comp_teacher_status"
                    :type="getStatus(scope.row.share_pre_teacher_comp_teacher_status, 1)">{{
              getStatus(scope.row.share_pre_teacher_comp_teacher_status, 2)
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="录取信息"
                         align="left"
                         width="400"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="tag-wrap"
                 style="justify-content: start">
              <img src="@/assets/images/unconfirm_tag.png"
                   alt=""
                   srcset=""
                   class="unconfirm_tag"
                   v-if="
                  scope.row.unconfirm_fields
                    ? scope.row.unconfirm_fields.split(',').findIndex((v) => {
                        return (
                          v == 'recruit_college_name' ||
                          v == 'recruit_academy_name' ||
                          v == 'recruit_major_name' ||
                          v == 'recruit_direction_name'
                        );
                      }) != -1
                    : false
                " />
              <span>
                <span class="pointer"
                      @click="institutionOpen(scope.row)">{{ scope.row.recruit_college_name }} </span>| {{ scope.row.recruit_academy_name }} | ({{
                  scope.row.recruit_major_code
                }}){{ scope.row.recruit_major_name }}
                {{
                  scope.row.recruit_direction_code &&
                  scope.row.recruit_direction_name
                    ? ` |
                (${scope.row.recruit_direction_code})${scope.row.recruit_direction_name}`
                    : ""
                }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="初试科目"
                         align="left"
                         width="400"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="tag-wrap"
                 style="justify-content: start">
              <img src="@/assets/images/unconfirm_tag.png"
                   alt=""
                   srcset=""
                   class="unconfirm_tag"
                   v-if="
                  scope.row.unconfirm_fields
                    ? scope.row.unconfirm_fields.split(',').findIndex((v) => {
                        return (
                          v == 'test_subject_codes' || v == 'test_subject_names'
                        );
                      }) != -1
                    : false
                " />
              <span>{{ formateSubjects(scope.row) }}</span>
            </div>
          </template>
        </el-table-column>

        x
        <el-table-column label="初试总分"
                         align="center "
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="tag-wrap">
              <img src="@/assets/images/unconfirm_tag.png"
                   alt=""
                   srcset=""
                   class="unconfirm_tag"
                   v-if="
                  scope.row.unconfirm_fields
                    ? scope.row.unconfirm_fields.split(',').findIndex((v) => {
                        return v == 'initial_test_score';
                      }) != -1
                    : false
                " />
              {{ scope.row.initial_test_score }}分
            </div>
          </template>
        </el-table-column>
        <el-table-column label="复试科目"
                         align="left"
                         width="350"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="tag-wrap"
                 style="justify-content: start">
              <img src="@/assets/images/unconfirm_tag.png"
                   alt=""
                   srcset=""
                   class="unconfirm_tag"
                   v-if="
                  scope.row.unconfirm_fields
                    ? scope.row.unconfirm_fields.split(',').findIndex((v) => {
                        return (
                          v == 'second_test_subject_codes' ||
                          v == 'second_test_subject_names'
                        );
                      }) != -1
                    : false
                " />
              <span>{{ scope.row.second_test_subject_names || "无" }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="录取类型"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="tag-wrap">
              <img src="@/assets/images/unconfirm_tag.png"
                   alt=""
                   srcset=""
                   class="unconfirm_tag"
                   v-if="
                  scope.row.unconfirm_fields
                    ? scope.row.unconfirm_fields.split(',').findIndex((v) => {
                        return v == 'recruit_type';
                      }) != -1
                    : false
                " />
              <span v-if="scope.row.recruit_type == 1">一志愿</span>
              <span v-if="scope.row.recruit_type == 2">调剂</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="辅导书籍"
                         align="center"
                         width="300"
                         prop="ref_recruit_reference_bookses"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateBooks(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="录取参考书"
                         align="center"
                         width="300"
                         prop="ref_recruit_reference_bookses"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.ref_recruit_reference_bookses
                ? scope.row.ref_recruit_reference_bookses
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="签约课时费"
                         align="center "
                         width="110"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.salary_split_type==2"> {{scope.row.salary_profit_fix_amount ? scope.row.salary_profit_fix_amount :0}} +
              {{scope.row.bonus_profit_fix_amount ? scope.row.bonus_profit_fix_amount :0}}元/小时</span>
            <span v-else>{{scope.row.fee_amount}}元/小时</span>
          </template>
          <!-- <template slot-scope="scope"
                     v-if="scope.row.salary_split_type==1">
            {{scope.row.fee_amount}}元/小时
          </template> -->
        </el-table-column>
        <el-table-column label="结算周期"
                         align="center "
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.settle_cycle_type==1 ? "月结": "周结"}}
          </template>
        </el-table-column>
        <el-table-column label="平均课时费"
                         align="center "
                         width="110"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.teacher_average_lesson_hour_amount
                ? scope.row.teacher_average_lesson_hour_amount
                : 0
            }}元/小时
          </template>
        </el-table-column>
        <el-table-column label="综合评分"
                         width="80"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{
              scope.row.teacher_average_student_apprise_score
                ? scope.row.teacher_average_student_apprise_score.toFixed(1) +
                  "分"
                : "暂无"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="contract_remark"
                         label="签约备注"
                         width="200"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="微信添加"
                         width="200"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateWechatManager(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="首次签约人"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateFirstManager(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="创建人"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ formateCreator(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="首次签约时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.first_contract_time
                ? $moment(scope.row.first_contract_time).format(
                    "YYYY-MM-DD HH:mm"
                  )
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="签约有效期"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.contract_end_time
                ? $moment(scope.row.contract_end_time).format(
                    "YYYY-MM-DD HH:mm"
                  )
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         fixed="right"
                         align="center"
                         width="180">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToSeniorDetail(scope.row)">详情</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToEdit(scope.row.id)">确认</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="handleToSign(scope.row.id)">签约</el-link>
            <el-link type="primary"
                     :underline="false"
                     :disabled="!!scope.row.share_pre_teacher_sync_status"
                     @click="handleSynchronization(scope.row)">同步</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button size="mini"
                   type="primary"
                   @click="chooseAllEdit(1, '您确定要归档吗')"
                   v-show="query.archive == 0 && query.archive != ''">归档</el-button>
        <el-button size="mini"
                   type="primary"
                   @click="chooseAllEdit(0, '您确定要取消归档吗')"
                   v-show="query.archive == 1">取消归档</el-button>
        <div class="block">
          <div style="margin-right: 16px">
            共{{ otherCount }}条<span v-if="Number(otherCount) > 100">,涉及隐私仅显示前100条</span>
          </div>
          <el-pagination background
                         layout="prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <Record :teacher_id="teacher_id"
            :isVisible="recordDialog"
            @close="recordDialog = false"
            :flag="true"
            :type="'teacher_read'"
            @submit="fetchList"></Record>
    <ConfirmSenior :isVisible="editDialog"
                   :id="editId"
                   @close="editDialog = false"
                   @submit="fetchList"></ConfirmSenior>
    <Sign :isVisible="signDialog"
          :id="signId"
          @close="signDialog = false"
          @submit="fetchList"></Sign>
    <TeacherCreate :isVisible="createDialog"
                   @close="createDialog = false"
                   @submit="fetchList"></TeacherCreate>
    <SeniorDetail :isVisible="SeniorDetailDialog"
                  @close="SeniorDetailDialog = false"
                  :id="detailId" />
    <InstitutionDetails ref="institutionDetails"></InstitutionDetails>
  </div>
</template>
    
<script>
import TableTittle from '@/components/TableTittle/TableTittle.vue'
import Record from '@/components/DialogComponents/TeacherResources/Record.vue'
import ConfirmSenior from '@/components/DialogComponents/TeacherResources/ConfirmSenior.vue'
import Sign from '@/components/DialogComponents/TeacherResources/Sign.vue'
import SeniorDetail from '@/components/DialogComponents/TeacherResources/SeniorDetail.vue'
import TeacherCreate from '@/components/DialogComponents/TeacherResources/TeacherCreate.vue'
import {
  teacherlist,
  teacherEditlist,
  majorlist,
  taRecordCreate,
} from '@/request/api'
import InstitutionDetails from '@/components/DialogComponents/InstitutionDetails/index.vue'
import { adminUrl, crmUrl } from '@/utils/helper.js'

let Timer = null // 定时器
let TimerTime = 500 // 定时器 等待时长

export default {
  components: {
    TableTittle,
    Record,
    ConfirmSenior,
    Sign,
    TeacherCreate,
    SeniorDetail,
    InstitutionDetails,
  },
  data() {
    return {
      majorOptions: [],
      TableName: '老师列表',
      result: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        archive: '0',
        first_contract_time: '',
        pre_teacher_sync_status: '',
      },
      pageTotal: 0,
      chooseList: [],
      editDialog: false,
      editId: null,
      recordDialog: false,
      signId: null,
      signDialog: false,
      teacher_id: null,
      otherCount: 0,
      loading: false,
      createDialog: false,
      SeniorDetailDialog: false,
      detailId: null,
      time: [],
    }
  },
  created() {
    this.parseQuery()
    this.fetchList()
    this.fetchallmajorlist()
    this.$store.dispatch('getStaffOptions')
    this.$store.dispatch('getAllColleges')
  },
  computed: {},
  methods: {
    getStatus(status, type) {
      switch (status) {
        case 1:
          if (type == 1) return ''
          return '已提交'
        case 2:
          if (type == 1) return 'success'
          return '正常'
        case 3:
          if (type == 1) return 'warning'
          return '待审核'
        case 4:
          if (type == 1) return 'danger'
          return '已下线'
        case 5:
          if (type == 1) return 'info'
          return '已删除'
      }
    },
    handleSynchronization(row) {
      var that = this
      this.$confirm('此操作将同步师资, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let data = {
            pre_apply_college_code: row.recruit_college_code,
            pre_apply_college_name: row.recruit_college_name,
            pre_apply_direction_code: row.recruit_direction_code,
            pre_apply_direction_name: row.recruit_direction_name,
            pre_apply_major_code: row.recruit_major_code,
            pre_apply_major_name: row.recruit_major_name,
            remark: row.contract_remark,
            teacher_apply_year: row.recruit_year,
            teacher_first_exam_score: row.initial_test_score,
            teacher_name: row.realname,
            teacher_phone: row.mobile,
            teacher_wechat: row.wechat,
            pre_first_subject_codes: row.test_subject_codes
              ? row.test_subject_codes
              : '',
            pre_first_subject_names: row.test_subject_names
              ? row.test_subject_names
              : '',
          }
          that.bulk(data, row)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    edit(row) {
      let data = {
        record: '已成功将' + row.realname + '老师同步至预留师资',
        teacher_id: row.id,
        type: 20,
      }
      taRecordCreate(data).then((res) => {
        if (res.code == 0) {
          this.$message.success('操作成功')
          this.fetchList()
        }
      })
    },
    //  teacherEdit(data).then((response) => {
    //         let res = response.data
    //         if (res.code == 0) {
    //           this.$message.success('操作成功')
    //           taRecordCreate({
    //             record: `已确认${this.dataForm.realname}老师的报考信息`,
    //             teacher_id:this.$route.params.id,
    //             type:18,
    //           }).then(res=>{
    //             // console.log("ces",res)
    //           })
    //           this.fetchDetail()
    //         }
    //       }),
    bulk(data, row) {
      console.log('rows1111', row)
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/preTeacher/bulk`,
        method: 'post', //可以省略 不写默认是get
        data: {
          arr_pre_teacher: [data],
        },
        headers: {
          // 设置请求头
          Authorization: localStorage.getItem('admin_token'),
        },
      }).then((response) => {
        console.log('response:', response)
        let res = response.data
        if (res.code == 0) {
          this.edit(row)
          this.fetchList()
          this.$message.success('同步成功!')
        }
      })
    },
    //
    majorQuery() {
      this.$forceUpdate()
      this.fetchList(true)
    },
    formateBooks(row) {
      let arr = row.reference_book_extra_infos
        ? row.reference_book_extra_infos.split(',')
        : []
      let code_arr = row.reference_book_names
        ? row.reference_book_names.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `${code_arr[i]},${v}`
            })
            .join('，')
        : '无'
    },
    openDataStatistics() {
      this.$router.push({
        path: '/data/statistics',
      })
    },
    institutionOpen(row) {
      this.$refs.institutionDetails.open(row.recruit_college_code)
    },

    collegeClear() {
      // this.$set(this.query, 'major_code', '')
      // this.majorOptions = []
      // this.fetchList(true)
    },
    collegeChoose(e) {
      this.fetchList(true)
      // this.collegeClear()
      // if(!e){
      //   return
      // }
      // let obj = {};
      // obj = this.$store.state.collegeList.find((item) => {
      //   return item.code === e; //筛选出匹配数据
      // });
      // let query = {
      //   isPaging: 0,
      //   college_code: obj.code,
      // };
      // majorlist(query).then((response) => {
      //   let res = response.data
      //   this.majorOptions = res.data.list.map(v => {
      //     return {
      //       ...v,
      //       allnames: `（${v.code}）${v.name}`
      //     }
      //   })
      // });
      // this.form.apply_college_code = obj.code;
      // this.form.apply_college_name = obj.name;
      // this.getMajorOptions();
    },

    // 专业列表请求
    fetchallmajorlist() {
      let str = adminUrl()

      this.$axios({
        url: `${str}/api/v1/back/major/list`,
        method: 'get', //可以省略 不写默认是get
        params: { isPaging: 0 },
      }).then((response) => {
        this.majorOptions = []
        // this.recursion(response.data.data.list)
        response.data.data.list.forEach((item) => {
          if (item.children) {
            this.recursion(item.children)
          }
        })
        // console.log('list', this.majorOptions, this.majorOptions.length)
      })
    },

    recursion(node) {
      node.forEach((item) => {
        if (item.children) {
          this.recursion(item.children)
        } else {
          this.majorOptions.push(item)
        }
      })
    },

    searchSetTimeout() {
      clearTimeout(Timer)
      Timer = setTimeout(() => {
        this.fetchList(true)
      }, TimerTime)
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },

    fetchList(isReset = false) {
      this.loading = true
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }

      // start/end_first_contract_time
      const params = Object.assign({}, this.query)
      if (this.time && this.time.length > 0) {
        params.start_first_contract_time = this.time[0]
        params.end_first_contract_time = this.time[1]
      }
      params.is_show_pre_teacher_sync_status = 1
      teacherlist(params).then((response) => {
        let res = response.data
        this.result = res.data.list || []
        /*  this.pageTotal = res.data.count; */
        if (res.data.count > 100) {
          this.otherCount = res.data.count
          this.pageTotal = 100
        } else {
          this.otherCount = res.data.count
          this.pageTotal = res.data.count || 0
        }
        this.loading = false
      })
      this.syncQuery()
    },

    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    handleToDetail(row) {
      this.$router.push('/tr/order/handle/99')
    },
    formateSubjects(row) {
      let arr = row.test_subject_names ? row.test_subject_names.split(',') : []
      let code_arr = row.test_subject_codes
        ? row.test_subject_codes.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `(${code_arr[i]})${v}`
            })
            .join('+')
        : '无'
    },
    formateSecondSubjects(row) {
      let arr = row.second_test_subject_codes
        ? row.second_test_subject_codes.split(',')
        : []
      let code_arr = row.second_test_subject_names
        ? row.second_test_subject_names.split(',')
        : []
      return arr.length
        ? arr
            .map((v, i) => {
              return `(${v})${code_arr[i]}`
            })
            .join('+')
        : '无'
    },
    formateFirstManager(row) {
      if (row.first_contract_manage_id && this.$store.state.staffOptions) {
        let id = row.first_contract_manage_id
        // console.log("Ccc",this.$store.state.staffOptions)
        return this.$store.state.staffOptions.find((v) => {
          return v.id == id
        }).nickname
      } else {
        return '无'
      }
    },
    formateCreator(row) {
      if (row.manage_creator && this.$store.state.staffOptions) {
        let id = row.manage_creator
        return this.$store.state.staffOptions.find((v) => {
          return v.id == id
        }).nickname
      } else {
        return '无'
      }
    },
    formateWechatManager(row) {
      if (row.wechat_add_manage_ids && this.$store.state.staffOptions) {
        let id = row.wechat_add_manage_ids
        let idList = id.split(',')
        let nickname = ''
        idList.forEach((item) => {
          this.$store.state.staffOptions.forEach((v) => {
            if (v.id == item) {
              nickname = nickname + ' | ' + v.nickname
            }
          })
        })
        return nickname.substring(2, nickname.length)
      } else {
        return '无'
      }
    },
    handleToEdit(id) {
      window.open(`/tr/order/confirm/${id}`)
    },
    handleToSign(id) {
      this.signId = id
      this.signDialog = true
    },
    handleToRecord(row) {
      this.teacher_id = row.id
      this.recordDialog = true
    },
    openCreateDialog() {
      this.createDialog = true
    },
    handleToSeniorDetail(row) {
      this.detailId = row.id
      this.SeniorDetailDialog = true
    },
    chooseAllEdit(archive_status, text) {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要修改的数据')
        return false
      }
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let query = this.chooseList.map((v) => {
            return {
              id: v.id,
              archive: archive_status,
            }
          })
          teacherEditlist(query).then((res) => {
            this.fetchList()
          })
        })
        .catch(() => {
          this.$notify.error({
            title: '提示',
            message: '已取消操作',
          })
        })
    },
  },
}
</script>
    
<style lang="less" scoped>
.content {
  .badge {
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tag-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .unconfirm_tag {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }

  .block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
}
</style>
    