<template>
  <div>
    <el-dialog top="5vh"
               title="编辑订单信息"
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               :close-on-click-modal="false"
               width="620px">
      <el-form :model="dataForm"
               label-position="right"
               ref="formRules"
               :rules="rules"
               label-width="100px"
               size="small">
        <el-form-item label="考研年份："
                      prop="apply_year">
          <el-select v-model="dataForm.apply_year"
                     placeholder="请选择考研年份"
                     style="width: 100%;"
                     clearable>
            <el-option label="2024"
                       value="2024"></el-option>
            <el-option label="2025"
                       value="2025"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考院校："
                      prop="apply_college">
          <el-select v-model="dataForm.apply_college"
                     style="width: 100%;"
                     placeholder="请选择院校"
                     filterable
                     clearable
                     @change="collegeChange">
            <el-option v-for="(item,index) in $store.state.collegeList"
                       :key="index"
                       :label="item.name"
                       :value="{ value: item.code, label: item.name}">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考专业："
                      prop="apply_major">
          <el-select v-model="dataForm.apply_major"
                     placeholder="请选择专业"
                     style="width: 100%;"
                     @change="majorChange"
                     clearable
                     filterable>
            <el-option v-for="(item,index) in majorOptions"
                       :key="index"
                       :label="item.allnames"
                       :value="{ value: item.code, label: item.name}">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考学院："
                      prop="apply_major">
          <el-select v-model="dataForm.apply_academy"
                     placeholder="请选择学院"
                     clearable
                     @change="academyChange"
                     style="width: 100%;">
            <el-option v-for="(item,index) in academyOptions"
                       :key="index"
                       :label="item.academy_name"
                       :value="{ value: item.academy_code, label: item.academy_name,directions:item.directions}">
              <span>{{ `（${item.academy_code}）${item.academy_name}` }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="辅导科目："
                      prop="apply_subjects">
          <el-select style="width:100%;"
                     v-model="dataForm.apply_subjects"
                     placeholder="请选择科目"
                     clearable
                     multiple
                     @change="$forceUpdate()">
            <el-option v-for="(item,index) in subjectOptions"
                       :key="index"
                       :label="item.subject_name"
                       :value="{ value: item.subject_code, label: item.subject_name}">
              <span>{{ `（${item.subject_code}）${item.subject_name}` }}</span>

            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="辅导小时："
                      prop="lesson_hours">
          <el-input placeholder="请输入辅导小时"
                    v-model="dataForm.lesson_hours"
                    class="input-with-select"
                    oninput="value=value.replace(/[^0-9.]/g,'')">
            <span slot="append">小时</span>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="handleToClose(false)">取消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import { academylist, majorlist, gradeOne, subjectlist } from '@/request/api'
import { adminUrl, crmUrl } from '@/utils/helper'

export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: {},
      rules: {
        name: [{ required: true, message: '请输入班级名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择班级类型', trigger: 'blur' }],
        lesson_hours: [
          { required: true, message: '请选择辅导课时', trigger: 'blur' },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: '输入最多两位小数的数字',
          },
        ],
        learn_manager: [
          { required: true, message: '请选择学管师', trigger: 'blur' },
        ],
        apply_year: [
          { required: true, message: '请选择考研年份', trigger: 'blur' },
        ],
        apply_college: [
          { required: true, message: '请选择院校', trigger: 'blur' },
        ],
        apply_major: [
          { required: true, message: '请选择专业', trigger: 'blur' },
        ],
        apply_academy: [
          { required: true, message: '请选择学院', trigger: 'blur' },
        ],
        apply_subjects: [
          { required: true, message: '请选择科目', trigger: 'blur' },
        ],
      },
      majorOptions: [], // 专业数组
      academyOptions: [], // 学院数组
      subjectOptions: [], //科目数组
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    fetchDetail() {
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrder`,
        method: 'get', //可以省略 不写默认是get
        params: {
          id: this.id,
        },
      }).then((response) => {
        let res = response.data
        this.dataForm = res.data
        if (
          this.dataForm.apply_college_name &&
          this.dataForm.apply_college_code
        ) {
          this.$set(this.dataForm, 'apply_college', {
            label: this.dataForm.apply_college_name,
            value: this.dataForm.apply_college_code,
          })
          this.getMajor(this.dataForm.apply_college_code)
        }
        if (this.dataForm.apply_major_name && this.dataForm.apply_major_code) {
          this.$set(this.dataForm, 'apply_major', {
            label: this.dataForm.apply_major_name,
            value: this.dataForm.apply_major_code,
          })
          this.getMajorInfo(
            this.dataForm.apply_college_code,
            this.dataForm.apply_major_code
          )
        }
        if (
          this.dataForm.apply_academy_name &&
          this.dataForm.apply_academy_code
        ) {
          this.$set(this.dataForm, 'apply_academy', {
            label: this.dataForm.apply_academy_name,
            value: this.dataForm.apply_academy_code,
          })
          this.getSubjects()
        }
        if (
          this.dataForm.apply_subject_codes &&
          this.dataForm.apply_subject_names
        ) {
          this.$set(
            this.dataForm,
            'apply_subjects',
            this.dataForm.apply_subject_names.split(',').map((v, i) => {
              return {
                value: this.dataForm.apply_subject_codes.split(',')[i],
                label: v,
              }
            })
          )
        }
        this.$forceUpdate()
      })
    },
    handleToClose() {
      this.$emit('close')
    },
    collegeChange(e) {
      this.dataForm.apply_major = {}
      this.majorOptions = []
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.dataForm.apply_direction = {}
      this.directionsOptions = []
      if (e) {
        this.getMajor(e.value)
      }
    },
    getMajor(college_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
      }
      majorlist(query).then((response) => {
        let res = response.data
        this.majorOptions = res.data.list.map((v) => {
          return {
            ...v,
            allnames: `（${v.code}）${v.name}`,
          }
        })
      })
    },
    majorChange(e) {
      this.dataForm.apply_academy = {}
      this.academyOptions = []
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.dataForm.apply_direction = {}
      this.directionsOptions = []
      if (e) {
        this.getMajorInfo(this.dataForm.apply_college.value, e.value)
      }
    },
    getMajorInfo(college_code, major_code) {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/major/info`,
        method: 'get', //可以省略 不写默认是get
        params: {
          college_code: college_code,
          major_code: major_code,
        },
      }).then((response) => {
        let res = response.data
        this.academyOptions = res.data.academys
        this.directionsOptions = this.academyOptions.find((v) => {
          return this.dataForm.apply_academy.value == v.academy_code
        }).directions
      })
    },
    getAcedemy(college_code, major_code) {
      let query = {
        isPaging: 0,
        college_code: college_code,
        major_code: major_code,
      }
      academylist(query).then((response) => {
        let res = response.data
        this.academyOptions = res.data.list || []
      })
    },
    academyChange(e) {
      this.dataForm.apply_subjects = []
      this.subjectOptions = []
      this.dataForm.apply_direction = {}
      this.directionsOptions = []
      this.$forceUpdate()
      if (e) {
        this.directionsOptions = this.academyOptions.find((v) => {
          return this.dataForm.apply_academy.value == v.academy_code
        }).directions
        this.getSubjects()
      }
    },
    getSubjects() {
      let query = {
        isPaging: 0,
        college_code: this.dataForm.apply_college.value,
        academy_code: this.dataForm.apply_academy.value,
        major_code: this.dataForm.apply_major.value,
      }
      subjectlist(query).then((res) => {
        let obj = {
          id: 999,
          subject_code: '999',
          subject_name: '复试课程',
        }
        this.subjectOptions = res.data.data.list || []
        this.subjectOptions.push(obj)
      })
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.dataForm.id,
            apply_college_code: this.dataForm.apply_college
              ? this.dataForm.apply_college.value
              : '',
            apply_college_name: this.dataForm.apply_college
              ? this.dataForm.apply_college.label
              : '',
            apply_major_code: this.dataForm.apply_major
              ? this.dataForm.apply_major.value
              : '',
            apply_major_name: this.dataForm.apply_major
              ? this.dataForm.apply_major.label
              : '',
            apply_academy_code: this.dataForm.apply_academy
              ? this.dataForm.apply_academy.value
              : '',
            apply_academy_name: this.dataForm.apply_academy
              ? this.dataForm.apply_academy.label
              : '',
            apply_year: this.dataForm.apply_year,
            lesson_hours: this.dataForm.lesson_hours,
            apply_subject_names:
              this.dataForm.apply_subjects &&
              this.dataForm.apply_subjects.length
                ? this.dataForm.apply_subjects
                    .map((v) => {
                      return v.label
                    })
                    .join(',')
                : '',
            apply_subject_codes:
              this.dataForm.apply_subjects &&
              this.dataForm.apply_subjects.length
                ? this.dataForm.apply_subjects
                    .map((v) => {
                      return v.value
                    })
                    .join(',')
                : '',
          }
          let str = crmUrl()
          this.$axios({
            url: `${str}/api/v1/sharePlatform/findOrder`,
            method: 'put', //可以省略 不写默认是get
            data: query,
          }).then((response) => {
            if (response.data.code == 0) {
              this.handleToClose()
              this.$emit('submit')
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
  
  <style lang="less" scoped>
</style>