<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资共享</el-breadcrumb-item>
        <el-breadcrumb-item>我的订单</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button size="mini"
                   type="primary"
                   @click="newOrder">新建订单</el-button>
        <el-button icon="el-icon-download"
                   @click="handleExport"
                   size="small">导出</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="订单编号">
          <el-input v-model.trim="query.order_no"
                    placeholder="请输入订单编号"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="发单人">
          <el-input v-model.trim="query.sender_keyword"
                    placeholder="请输入发单人名字/手机号/机构名"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="接单人">
          <el-input v-model.trim="query.receiver_keyword"
                    placeholder="请输入接单人名字/手机号/机构名"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select v-model="query.statues"
                     placeholder="请选择"
                     @change="fetchList"
                     multiple
                     clearable>
            <el-option v-for="item in filters.statues"
                       :key="item.id"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预留重启">

          <el-select v-model="query.receive_order_types"
                     placeholder="请选择状态"
                     @change="fetchList(true)"
                     clearable>
            <el-option v-for="(item, index) in filters.receiveType"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>

          <!-- <el-select v-model="query.receive_order_types"
                     placeholder="请选择"
                     @change="fetchList"
                     clearable>
            <el-option v-for="(item, index) in filters.receiveType"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select> -->
        </el-form-item>
        <!-- <el-form-item label="关联匹配订单状态 ">
          <el-select
            v-model="query.ref_allocation_order_statuses"
            placeholder="请选择"
            @change="fetchList"
            multiple
            clearable
          >
            <el-option
              v-for="item in filters.relateStatues"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="报考信息">
          <el-input v-model.trim="query.apply_cams_keyword"
                    placeholder="请输入院校/学院/专业/科目关键字"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-select v-model="create_time"
                     placeholder="请选择创建时间"
                     @change="createChange"
                     clearable>
            <el-option v-for="(item, index) in timeArr"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-date-picker
            @change="fetchList(true)"
            class="datetime"
            v-model="create_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            clearable
          >
          </el-date-picker> -->
        </el-form-item>
        <el-form-item label="完成时间">
          <el-select v-model="finish_time"
                     placeholder="请选择完成时间"
                     @change="finishChange"
                     clearable>
            <el-option v-for="(item, index) in timeArr"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-date-picker
            @change="fetchList(true)"
            class="datetime"
            v-model="finish_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            clearable
          >
          </el-date-picker> -->
        </el-form-item>
        <el-form-item label="取消时间">
          <el-select v-model="update_time"
                     placeholder="请选择取消时间"
                     @change="updateChange"
                     clearable>
            <el-option v-for="(item, index) in timeArr"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
          <!-- <el-date-picker
            @change="fetchList(true)"
            class="datetime"
            v-model="update_time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
            :default-time="['00:00:00', '23:59:59']"
            clearable
          >
          </el-date-picker> -->
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form size="small"
                 :inline="true">
          <el-form-item label-width="32px">
            <el-radio-group v-model="order_status"
                            @change="fetchList(true)">
              <el-radio-button :label="'1,2,3,4,5,6'">全部</el-radio-button>
              <el-radio-button :label="5">已完成</el-radio-button>
              <el-radio-button :label="'1,2,3,4'">进行中</el-radio-button>
              <el-radio-button :label="6">已取消</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="result"
                :header-cell-style="{
          backgroundColor: '#F2F6FC',
          color: '#909399',
          fontSize: '14px',
        }"
                :cell-style="{ fontSize: '13px' }"
                @selection-change="handleSelectionChange"
                border>
        <el-table-column type="selection"
                         width="50"
                         align="center">
        </el-table-column>
        <!--        <el-table-column
          prop="id"
          label="ID"
          align="center"
          fixed="left"
          width="80"
        >
        </el-table-column> -->
        <el-table-column fixed="left"
                         label="操作记录"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToRecord(scope.row)">查看</el-link>
          </template>
        </el-table-column>
        <el-table-column prop="order_no"
                         label="订单编号"
                         align="center"
                         width="150">
        </el-table-column>
        <el-table-column prop="spend_duration"
                         label="时效"
                         align="center"
                         width="120">
          <template slot-scope="scope">
            <div class="spend-wrap">
              <img src="@/assets/images/ji_tag.png"
                   alt=""
                   srcset=""
                   v-if="scope.row.urgency_status == 1" />
              <div v-html="formateTime(scope.row)"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单状态"
                         align="center"
                         width="110"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.status == 1">待接单</el-tag>
            <el-tag size="small"
                    v-if="scope.row.status == 2">
              <span v-if=" scope.row.receive_order_type ==1 "> 预留-接单中</span>

              <span v-else>接单中</span>
            </el-tag>
            <el-tag size="small"
                    type="warning"
                    v-if="scope.row.status == 3">
              <span v-if=" scope.row.receive_order_type ==1 "> 预留-待支付</span>

              <span v-else>待支付</span>
            </el-tag>
            <!-- <el-tag size="small"
                    type="warning"
                    v-if="scope.row.status == 3 ">待支付</el-tag> -->
            <el-tag size="small"
                    type="warning"
                    v-if="scope.row.status == 4">
              <span v-if=" scope.row.receive_order_type ==1 ">
                预留-待确认
              </span>
              <span v-else-if=" scope.row.order_reuse_status  ==1  ">
                重启-待确认
              </span>

              <span v-else>待确认</span></el-tag>
            <el-tag size="small"
                    type="success"
                    v-if="scope.row.status == 5">
              <span v-if=" scope.row.receive_order_type ==1 "> 预留-已完成</span>
              <span v-else-if=" scope.row.order_reuse_status  ==1  ">
                重启-已完成
              </span>
              <span v-else>已完成</span>
            </el-tag>
            <el-tag size="small"
                    type="info"
                    v-if="scope.row.status == 6"> <span v-if=" scope.row.receive_order_type ==1 "> 预留-已取消</span>
              <span v-else-if=" scope.row.order_reuse_status  ==1  ">
                重启-已取消
              </span>
              <span v-else>已取消</span></el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="关联匹配订单状态"
          align="center"
          width="150"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag size="small" v-if="scope.row.ref_allocation_order_status == 1">待分配</el-tag>
            <el-tag size="small" type="warning" v-if="scope.row.ref_allocation_order_status == 2"
              >匹配中</el-tag
            >
            <el-tag size="small" type="warning" v-if="scope.row.ref_allocation_order_status == 3"
              >待确认</el-tag
            >
            <el-tag size="small" type="success" v-if="scope.row.ref_allocation_order_status == 4"
              >已完成</el-tag
            >
            <el-tag size="small" type="info" v-if="scope.row.ref_allocation_order_status == 5"
              >返单中</el-tag
            >
             <el-tag size="small" type="info" v-if="scope.row.ref_allocation_order_status == 6"
              >已取消</el-tag
            >
          </template>
        </el-table-column> -->
        <el-table-column label="发单人"
                         align="left"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.sender_company_user_obj !=null &&scope.row.sender_company_user_obj.cid==1000"
                  class="pointer"
                  @click="handleReceiveSingleIssue(scope.row.sender_send_count_statistic_obj,scope.row.sender_company_user_obj )">
              <span v-if="scope.row.ref_sender_manage_user_obj !=null"> 平台-{{scope.row.ref_sender_manage_user_obj.nickname}}</span>
              <span v-else>平台-</span>
            </span>
            <span class="pointer"
                  v-else
                  @click="handleReceiveSingleIssue(scope.row.sender_send_count_statistic_obj,scope.row.sender_company_user_obj  )">{{
              scope.row.sender_company_user_obj
                ? `${scope.row.sender_company_user_obj.company_ref_name}/${scope.row.sender_company_user_obj.alias}/${scope.row.sender_company_user_obj.phone}`
                : ""
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单信息"
                         align="left"
                         min-width="500"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- ({{ scope.row.apply_subject_codes }}){{ scope.row.apply_subject_names }} -->
            <span>{{ scope.row.apply_year }}考研 |
              <span class="pointer"
                    @click="institutionOpen(scope.row)">{{
                scope.row.apply_college_name
              }}</span>
              | {{ scope.row.apply_academy_name }} | ({{
                scope.row.apply_major_code
              }}){{ scope.row.apply_major_name }} |
              {{
                getSubjectCodeAndName(
                  scope.row.apply_subject_codes,
                  scope.row.apply_subject_names
                )
              }}
              | {{ scope.row.lesson_hours }}小时</span>
          </template>
        </el-table-column>
        <el-table-column label="辅导要求"
                         align="center"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover placement="top"
                        width="260"
                        trigger="hover"
                        v-if="formateAsks(scope.row) > 0">
              <div class="popver-content">
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="
                    scope.row.demand_direction_codes &&
                    scope.row.demand_direction_names
                  ">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  考试方向仅限是{{ formateDirections(scope.row) }}
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.demand_max_hour_amount">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  研究生时薪≤{{ scope.row.demand_max_hour_amount }}元/小时；
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.demand_academy_major_status">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  研究生与学员同一个学院专业
                </div>
                <!-- <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.demand_extra_info">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  {{ scope.row.demand_extra_info }}
                </div> -->
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.extend_info_demand_trial_listen_status">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  正式课程前，需要安排一节试听课
                </div>

                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.extend_info_demand_professional_experience_status">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  需要有对应的专业课辅导经验
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.extend_info_demand_before_first_rank">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  初试总分排名在前{{ scope.row.extend_info_demand_before_first_rank}}名
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.extend_info_demand_min_first_total_score">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  初试总成绩不低于{{ scope.row.extend_info_demand_min_first_total_score}}分
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.extend_info_demand_min_first_professional_score">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  专业课成绩不低于{{ scope.row.extend_info_demand_min_first_professional_score}}分
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.demand_same_reference_book_status == 1">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  允许其他院校研究生根据参考书或者大纲辅导
                </div>
                <div style="
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 8px;
                  "
                     v-if="scope.row.demand_extra_info !=null">
                  <div class="point"
                       style="margin-right: 8px">·</div>
                  其他要求： {{scope.row.demand_extra_info}}
                </div>
              </div>
              <span slot="reference">有{{ formateAsks(scope.row) }}项辅导要求</span>
            </el-popover>
            <span v-else> 暂无任何辅导要求 </span>
          </template>
        </el-table-column>
        <el-table-column label="订单金额"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover placement="top"
                        width="200"
                        trigger="hover">
              <div>
                <div class="count-item other">
                  <div>订单费用</div>
                  <div>￥{{ scope.row.total_in_amount }}</div>
                </div>
                <div class="line"></div>
                <div class="count-item">
                  <div>基础费用</div>
                  <div>
                    ￥{{
                      scope.row.in_base_amount ? scope.row.in_base_amount : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>平台费用</div>
                  <div>
                    ￥{{
                      scope.row.in_service_amount
                        ? scope.row.in_service_amount
                        : 0
                    }}
                  </div>
                </div>

                <div class="count-item">
                  <div>超时附加</div>
                  <div>
                    ￥{{
                      scope.row.in_timeout_amount
                        ? scope.row.in_timeout_amount
                        : 0
                    }}
                  </div>
                </div>

                <div class="count-item">
                  <div>院校附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_college_category_amount
                        ? scope.row.in_append_college_category_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>地区附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_college_province_amount
                        ? scope.row.in_append_college_province_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>招生附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_college_enrol_amount
                        ? scope.row.in_append_college_enrol_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>要求附加</div>
                  <div>
                    ￥{{
                      Number(scope.row.in_append_demand_academy_major_amount) +
                      Number(scope.row.in_append_demand_direction_amount) +
                      Number(scope.row.in_append_demand_hour_amount_amount)+
                       Number(scope.row.in_append_trial_listen_amount)+
                          Number(scope.row.in_append_professional_experience_amount)+
                            Number(scope.row.in_append_first_rank_amount)+
                             Number(scope.row. in_append_first_total_score_amount)+
                              Number(scope.row.in_append_first_professional_score_amount)
                        ? (
                            Number(
                              scope.row.in_append_demand_academy_major_amount
                            ) +
                            Number(
                              scope.row.in_append_demand_direction_amount
                            ) +
                            Number(
                              scope.row.in_append_demand_hour_amount_amount
                            )+
                              Number(scope.row.in_append_trial_listen_amount)+
                                Number(scope.row.in_append_professional_experience_amount)+
                                 Number(scope.row.in_append_first_rank_amount)+
                                     Number(scope.row. in_append_first_total_score_amount)+
                                       Number(scope.row.in_append_first_professional_score_amount)
                          ).toFixed(2)
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>加急费用</div>
                  <div>
                    ￥{{
                      scope.row.in_urgency_amount
                        ? scope.row.in_urgency_amount
                        : 0
                    }}
                  </div>
                </div>

                <div class="count-item">
                  <div>复试附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_second_exam_subject_amount
                        ? scope.row.in_append_second_exam_subject_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>专业附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_major_discipline_amount
                        ? scope.row.in_append_major_discipline_amount
                        : 0
                    }}
                  </div>
                </div>
                <div class="count-item">
                  <div>考核附加</div>
                  <div>
                    ￥{{
                      scope.row.in_append_examine_cancel_amount
                        ? scope.row.in_append_examine_cancel_amount
                        : 0
                    }}
                  </div>
                </div>

                <!--   <div class="count-item">
                  <div>推荐分成</div>
                  <div>￥{{ scope.row.extra_out_referrer_bonus_amount?scope.row.extra_out_referrer_bonus_amount:0 }}</div>
                </div> -->
              </div>
              <span slot="reference"
                    style="color: #ff0034">￥{{ scope.row.total_in_amount }}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="已付费用"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{
              scope.row.total_deposit_amount
                ? `￥${(scope.row.total_deposit_amount - Number(scope.row.company_user_pay_coupon_amount) - Number(scope.row.extend_info_balance_pay_sub_amount)).toFixed(2)}`
                : "-"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="优惠补贴"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover placement="top"
                        width="160"
                        trigger="hover">
              <div>
                <div class="count-item other">
                  <div>优惠补贴</div>
                  <div>
                    ￥{{
                      (Number(scope.row.company_user_pay_coupon_amount) +
                      Number(scope.row.company_user_supply_coupon_amount) +
                      Number(scope.row.extend_info_balance_pay_sub_amount)).toFixed(2)
                    }}
                  </div>
                </div>
                <div class="line"></div>
                <div class="count-item">
                  <div>优惠券抵扣</div>
                  <div>
                    ￥{{Number(scope.row.company_user_pay_coupon_amount)}}
                  </div>
                </div>
                <div class="count-item">
                  <div>补贴券补贴</div>
                  <div>
                    ￥{{Number(scope.row.company_user_supply_coupon_amount)}}
                  </div>
                </div>
                <div class="count-item">
                  <div>余额支付减免</div>
                  <div>
                    ￥{{Number(scope.row.extend_info_balance_pay_sub_amount)}}
                  </div>
                </div>
              </div>
              <div slot="reference">
                <span v-if="
                    scope.row.company_user_pay_coupon_amount ||
                    scope.row.company_user_supply_coupon_amount ||
                    scope.row.extend_info_balance_pay_sub_amount
                  ">￥{{
                    (Number(scope.row.company_user_pay_coupon_amount) +
                    Number(scope.row.company_user_supply_coupon_amount) +
                    Number(scope.row.extend_info_balance_pay_sub_amount)).toFixed(2)
                  }}</span>
                <span v-else>-</span>
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="接单人收益"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.status == 5">￥{{
                scope.row.total_out_receiver_amount
                  ? scope.row.total_out_receiver_amount
                  : 0
              }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="订单查看人数"
                         align="center"
                         width="120"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="pointer"
                  @click="handleToDetails(scope.row )">
              {{scope.row.count_company_user_read}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="平台收益"
                         align="center"
                         width="100"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.status == 5">￥{{
                scope.row.total_out_platform_amount
                  ? (scope.row.total_out_platform_amount - Number(scope.row.company_user_pay_coupon_amount) - Number(scope.row.company_user_supply_coupon_amount) - Number(scope.row.extend_info_balance_pay_sub_amount)).toFixed(2)
                  : 0
              }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column label="接单人"
                         align="left"
                         width="150"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.receiver_company_user_obj !=null &&scope.row.receiver_company_user_obj.cid==1000"
                  class="pointer"
                  @click="handleReceiveSingleIssue(scope.row.receiver_receive_count_statistic_obj, scope.row.receiver_company_user_obj)">
              <span v-if="scope.row.ref_receiver_manage_user_obj !=null">平台-{{scope.row.ref_receiver_manage_user_obj.nickname}}</span>
              <span v-else>平台-</span>
            </span>
            <span class="pointer"
                  v-if="scope.row.receiver_company_user_obj==null || scope.row.receiver_company_user_obj.cid !=1000"
                  @click="handleReceiveSingleIssue(scope.row.receiver_receive_count_statistic_obj, scope.row.receiver_company_user_obj)">{{
              scope.row.receiver_company_user_obj
                ? `${scope.row.receiver_company_user_obj.company_ref_name}/${scope.row.receiver_company_user_obj.alias}/${scope.row.receiver_company_user_obj.phone}`
                : ""
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="发单备注"
                         align="left"
                         width="150"
                         prop="sender_company_user_obj.remark"
                         show-overflow-tooltip>
        </el-table-column>

        <el-table-column label="截止时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.extend_info_auto_cancel_time
                ? $moment(scope.row.extend_info_auto_cancel_time).format("YYYY-MM-DD HH:mm")
                : "无"
            }}
          </template>
        </el-table-column>

        <el-table-column label="创建时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                : "无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="更新时间"
                         align="center"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column fixed="right"
                         label="操作"
                         align="center"
                         width="200">
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToEdit(scope.row)">编辑</el-link>
            <el-link v-if="scope.row.status != 6 && scope.row.status != 5"
                     type="danger"
                     :underline="false"
                     @click="handleDel(scope.row)">取消</el-link>
            <el-link v-if="scope.row.status == 5"
                     type="danger"
                     :underline="false"
                     @click="handleCancelFinish(scope.row)">取消确认</el-link>
            <el-link v-if="scope.row.status == 4"
                     type="warning"
                     :underline="false"
                     @click="handledelayed(scope.row)">延时</el-link>
            <el-link type="primary"
                     :underline="false"
                     @click="cancelOrder(scope.row)"
                     v-if="scope.row.status == 3">催支付</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div class="operation">
        <div>
          <el-button size="mini"
                     type="danger"
                     @click="handleToDeleteAll()">删除</el-button>
        </div>
        <div class="block"
             style="margin-bottom: 0px">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <EditOrder :isVisible="editDialog"
               :id="editId"
               @submit="fetchList()"
               @close="editDialog = false"></EditOrder>
    <OrderRecord :recordItem="recordItem"
                 :isVisible="recordDialog"
                 :id="recordId"
                 @close="recordDialog = false"
                 :handle_receiver_id="handle_receiver_id"></OrderRecord>
    <InstitutionDetails ref="institutionDetails"></InstitutionDetails>

    <ReceiveSingleIssue ref="receiveSingleIssue"></ReceiveSingleIssue>
    <AddOrder :isVisible="addDialog"
              @close="editCloseDialog"
              :titles="title"
              @submit="fetchList()"></AddOrder>
    <detailRecord ref="admixtureDetail"></detailRecord>

    <!-- 延时弹窗 -->
    <el-dialog title="延时时间"
               :visible.sync="delayedVisible"
               :close-on-click-modal="false"
               width="404px"
               @open="open"
               @close="emitClose">

      <div class="timef">
        <el-select v-model="dayTime"
                   filterable
                   size="small"
                   placeholder="请选择">
          <el-option v-for="item in optionsDays"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
        <span>天</span>
        <el-select v-model="hourTime"
                   filterable
                   size="small"
                   placeholder="请选择">
          <el-option v-for="item in optionsHour"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
        <span>小时</span>
        <el-select v-model="mintueTime"
                   filterable
                   size="small"
                   placeholder="请选择">
          <el-option v-for="item in optionsMinuted"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
          </el-option>
        </el-select>
        <span>分钟</span>
      </div>

      <div class="footer">
        <el-button @click="cancle()">取消</el-button>
        <el-button type="primary"
                   @click="confirm">确定</el-button>

      </div>
    </el-dialog>

  </div>
</template>
          
<script>
import TableTittle from '@/components/TableTittle/TableTittle.vue'

import EditOrder from '@/components/DialogComponents/ShareOrder/EditOrder.vue'
import OrderRecord from '@/components/DialogComponents/ShareOrder/OrderRecord.vue'
import detailRecord from '@/components/DialogComponents/ShareOrder/detailOrder.vue'
import AddOrder from '@/components/DialogComponents/ShareOrder/newOrder.vue'
import InstitutionDetails from '@/components/DialogComponents/InstitutionDetails/index.vue'
import ReceiveSingleIssue from '@/components/ReceiveSingleIssue/index.vue'
import { crmUrl } from '@/utils/helper.js'

import {
  findOrderExport,
  CancelFinish,
  addConfirmTimeBackHandle,
} from '@/request/api'
export default {
  components: {
    TableTittle,
    AddOrder,
    EditOrder,
    OrderRecord,
    InstitutionDetails,
    ReceiveSingleIssue,
    detailRecord,
  },
  data() {
    return {
      delayedVisible: false,
      addDialog: false,
      title: '',
      optionsDays: Array.from({ length: 366 }, (v, k) => ({
        value: k,
        label: k,
      })),
      dayTime: '3',
      optionsHour: Array.from({ length: 25 }, (v, k) => ({
        value: k,
        label: k,
      })),
      hourTime: '0',
      optionsMinuted: Array.from({ length: 61 }, (v, k) => ({
        value: k,
        label: k,
      })),
      mintueTime: '0',
      delayedTime: '',

      timeArr: [
        {
          label: '全部',
          value: [],
        },
        {
          label: '一天内',
          value: [
            Number(this.$moment().format('x')) - 86400000,
            Number(this.$moment().format('x')),
          ],
        },
        {
          label: '七天内',
          value: [
            Number(this.$moment().format('x')) - 604800000,
            Number(this.$moment().format('x')),
          ],
        },
        {
          label: '十五天内',
          value: [
            Number(this.$moment().format('x')) - 1296000000,
            Number(this.$moment().format('x')),
          ],
        },
        {
          label: '一个月内',
          value: [
            Number(this.$moment().format('x')) - 2592000000,
            Number(this.$moment().format('x')),
          ],
        },
      ],
      finish_time: [],
      update_time: [],
      create_time: [],
      recordItem: {},
      TableName: '订单列表',
      result: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        receive_order_types: '',
        is_statistic_send_receive_count: 1,
      },
      pageTotal: 0,
      chooseList: [],
      editId: null,
      editDialog: false,
      recordId: null,
      handle_receiver_id: null,
      recordDialog: false,
      delayedRow: {},
      timestamp: 0,
      filters: {
        statues: [
          {
            label: '待接单',
            value: 1,
          },
          {
            label: '进行中',
            value: 2,
          },
          {
            label: '待支付',
            value: 3,
          },
          {
            label: '待确认',
            value: 4,
          },
          {
            label: '已完成',
            value: 5,
          },
          {
            label: '已取消',
            value: 6,
          },
        ],
        receiveType: [
          { label: '全部', value: '' },
          {
            label: '普通接单',
            value: '0',
          },
          {
            label: '预留接单',
            value: '1',
          },
          {
            label: '重启接单',
            value: '2',
          },
        ],
        //    relateStatues:[
        //  {
        //    label: "待分配",
        //    value: 1,
        //  },
        //  {
        //    label: "匹配中",
        //    value: 2,
        //  },
        //   {
        //    label: "待确认",
        //    value: 3,
        //  },
        //   {
        //    label: "已完成",
        //    value: 4,
        //  },
        //   {
        //    label: "返单中",
        //    value: 5,
        //  },
        //   {
        //    label: "已取消",
        //    value: 6,
        //  },
        // ],
      },
      order_status: '1,2,3,4',
    }
  },
  created() {
    console.log('时间', this.$moment().format('x'))
    this.parseQuery()
    this.fetchList()
    this.$store.dispatch('getAllColleges')
    this.$store.dispatch('getCompanyList', { data_range_tags: '2,3,4' })
  },
  computed: {},
  methods: {
    newOrder() {
      this.title = '新建匹配需求'
      this.addDialog = true
    },
    editCloseDialog() {
      this.addDialog = false
    },
    open() {
      this.dayTime = 3
      this.hourTime = 0
      this.mintueTime = 0
    },
    cancelOrder(row) {
      this.$confirm('是否要催支付', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let str = crmUrl()
        this.$axios({
          url: `${str}/api/v1/sharePlatform/findOrderHandle/order/rushPay`,
          method: 'post', //可以省略 不写默认是get
          data: {
            find_order_id: row.id,
          },
        }).then((response) => {
          let res = response.data
          if (res.code == 0) {
            this.$message.success('催支付成功')
          }
        })
        // rushPay({
        //   find_order_id: row.id
        // }).then(res=>{

        // })
        // let str = crmUrl()
        // this.$axios({
        //   url: `${str}/api/v1/sharePlatform/findOrderHandle/receiveOrder/cancel`,
        //   method: 'post',//可以省略 不写默认是get
        //   data: {
        //     find_order_id: row.id
        //   },
        // }).then(response => {
        //   let res = response.data
        //   if (res.code == 0) {
        //     this.$message.success('接单成功')
        //     this.fetchList()
        //   }
        // })
      })
    },
    confirm() {
      this.timestamp =
        this.dayTime * 24 * 60 * 60 * 1000 +
        this.hourTime * 60 * 60 * 1000 +
        this.mintueTime * 60 * 1000
      let data = {
        find_order_id: this.delayedRow.id,
        add_time_time: this.timestamp,
      }
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrderHandle/addConfirmTimeBackHandle`,
        method: 'post', //可以省略 不写默认是get
        data: data,
        headers: {
          // 设置请求头
          Authorization: localStorage.getItem('token'),
        },
      }).then((res) => {
        this.$message({
          message: '延时成功',
          type: 'success',
        })
        this.delayedVisible = false
        this.dayTime = ''
        this.hourTime = ''
        this.mintueTime = ''
        this.timestamp = ''
      })
    },
    cancle() {
      this.delayedVisible = false
    },
    emitClose() {
      this.delayedVisible = false
    },
    handledelayed(row) {
      console.log('row', row)
      this.delayedRow = row
      this.delayedVisible = true
    },
    handleCancelFinish(row) {
      this.$confirm('是否取消确认', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let data = {
          find_order_id: row.id,
        }
        let str = crmUrl()
        this.$axios({
          url: `${str}/api/v1/sharePlatform/tool/orderCancelFinish`,
          method: 'post', //可以省略 不写默认是get
          data: data,
          headers: {
            // 设置请求头
            Authorization: localStorage.getItem('token'),
          },
        }).then((response) => {
          if (response.code == 0) {
            this.fetchList()
            this.$message({
              message: '取消确认成功',
              type: 'success',
            })
          } else if (response.code == 404) {
            this.$message({
              message: response.error,
              type: 'error',
            })
          }
        })
      })
    },

    handleReceiveSingleIssue(row, item) {
      this.$refs.receiveSingleIssue.open(row, item)
    },
    institutionOpen(row) {
      this.$refs.institutionDetails.open(row.apply_college_code)
    },
    handleExport() {
      this.$confirm('是否导出数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = Object.assign({}, this.query)
        if (this.query.statues && this.query.statues.length) {
          params.statues = params.statues.join(',')
        } else {
          params.statues = this.order_status
        }
        if (this.finish_time && this.finish_time.length > 0) {
          params.start_finish_time = this.finish_time[0]
          params.end_finish_time = this.finish_time[1]
        }

        if (this.update_time && this.update_time.length > 0) {
          params.start_update_time = this.update_time[0]
          params.end_update_time = this.update_time[1]
        }
        if (this.create_time && this.create_time.length > 0) {
          params.start_create_time = this.create_time[0]
          params.end_create_time = this.create_time[1]
        }

        let str = crmUrl()
        this.$axios({
          url: `${str}/api/v1/sharePlatform/findOrder/export`,
          method: 'get', //可以省略 不写默认是get
          params: params,
        }).then((response) => {
          if (response.data.code == 0) {
            this.$message.success('导出成功')
            //创建a标签用于跳转至下载链接
            var str = response.data.data.url.replace(
              /http:/,
              window.location.protocol
            )
            this.$store.commit('downLoad', str)
          }
        })
      })
    },
    updateChange() {
      this.order_status = 6
      this.fetchList(true)
    },
    finishChange() {
      this.order_status = '5'
      this.fetchList(true)
    },
    createChange() {
      this.order_status = '1,2,3,4,5,6'
      this.fetchList(true)
    },
    getSubjectCodeAndName(code, name) {
      let codeList = code.split(',')
      let nameList = name.split(',')
      let value = ''
      nameList.forEach((item, index) => {
        value += `(${codeList[index]})${item}` + ','
      })
      return value.substring(0, value.length - 1)
    },
    // 取消
    handleDel(item) {
      //
      console.log('row', item.sender_company_user_teacher_name)
      this.$confirm('确定要取消订单吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let str = crmUrl()
        this.$axios({
          url: `${str}/api/v1/sharePlatform/findOrderHandle/order/cancel`,
          method: 'post', //可以省略 不写默认是get
          data: {
            find_order_id: item.id,
            cid: item.sender_company_user_obj.cid,
            uid: item.sender_company_user_obj.uid,
          },
        }).then((response) => {
          this.$axios({
            url: `${str}/api/v1/sharePlatform/findOrder/record`,
            method: 'post', //可以省略 不写默认是get
            data: {
              help_find_order_id: item.id,
              sender_company_user_id: item.sender_company_user_id,
              type: 15,
              receiver_company_user_id: item.receiver_company_user_ids_real,
              record:
                '客服协助发单人' +
                item.sender_company_area +
                item.sender_company_user_teacher_name +
                '取消了订单！',
            },
          }).then((res) => {
            this.fetchList()
            this.$message({
              message: '取消成功',
              type: 'success',
            })
          })
        })
      })
    },

    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)
      if (
        this.query.ref_allocation_order_statuses &&
        this.query.ref_allocation_order_statuses.length
      ) {
        params.ref_allocation_order_statuses =
          params.ref_allocation_order_statuses.join(',')
      }

      if (this.query.receive_order_types) {
        if (this.query.receive_order_types == '2') {
          params.order_reuse_status = 1
          params.receive_order_types = null
        } else {
          params.receive_order_types = this.query.receive_order_types
        }
      }
      if (this.query.statues && this.query.statues.length) {
        params.statues = params.statues.join(',')
      } else {
        params.statues = this.order_status
      }
      if (this.finish_time && this.finish_time.length > 0) {
        params.start_finish_time = this.finish_time[0]
        params.end_finish_time = this.finish_time[1]
      }

      if (this.update_time && this.update_time.length > 0) {
        params.start_update_time = this.update_time[0]
        params.end_update_time = this.update_time[1]
      }
      if (this.create_time && this.create_time.length > 0) {
        params.start_create_time = this.create_time[0]
        params.end_create_time = this.create_time[1]
      }
      let str = crmUrl()
      this.$axios({
        url: `${str}/api/v1/sharePlatform/findOrder/list`,
        method: 'get', //可以省略 不写默认是get
        params: params,
      }).then((response) => {
        let res = response.data
        this.result = res.data.list || []
        this.pageTotal = res.data.count
      })
      this.syncQuery()
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
      if (this.query.statues && this.query.statues.length) {
        this.query.statues = this.query.statues.split(',').map((v) => {
          return Number(v)
        })
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      if (params.statues) {
        params.statues = params.statues.filter((v) => v).join(',')
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    formateTime(row) {
      if (row.status == 5) {
        let time = new Date().getTime() - Number(row.finish_time)
        if (time < 60000) {
          return '刚刚完成'
        } else if (time < 3600000) {
          return `${Math.floor(
            Math.floor(Math.round(time / 1000) / 60)
          )}分钟前完成`
        } else if (time < 86400000) {
          return `${Math.floor(
            Math.floor(Math.round(time / 1000) / 60) / 60
          )}小时前完成`
        } else if (time < 2592000000) {
          return `${Math.floor(
            Math.floor(Math.floor(Math.round(time / 1000) / 60) / 60) / 24
          )}天前完成`
        } else if (time > 2592000000) {
          return '30天前完成'
        }
      } else if (row.status == 6) {
        let time = new Date().getTime() - Number(row.update_time)
        if (time < 60000) {
          return '刚刚取消'
        } else if (time < 3600000) {
          return `${Math.floor(
            Math.floor(Math.round(time / 1000) / 60)
          )}分钟前取消`
        } else if (time < 86400000) {
          return `${Math.floor(
            Math.floor(Math.round(time / 1000) / 60) / 60
          )}小时前取消`
        } else if (time < 2592000000) {
          return `${Math.floor(
            Math.floor(Math.floor(Math.round(time / 1000) / 60) / 60) / 24
          )}天前取消`
        } else if (time > 2592000000) {
          return '30天前取消'
        }
      }

      if (row.create_time == row.refresh_time) {
        let time = new Date().getTime() - Number(row.create_time)
        if (time < 60000) {
          return '刚刚发布'
        } else if (time < 3600000) {
          return `${Math.floor(
            Math.floor(Math.round(time / 1000) / 60)
          )}分钟前发布`
        } else if (time < 86400000) {
          return `${Math.floor(
            Math.floor(Math.round(time / 1000) / 60) / 60
          )}小时前发布`
        } else if (time < 2592000000) {
          return `${Math.floor(
            Math.floor(Math.floor(Math.round(time / 1000) / 60) / 60) / 24
          )}天前发布`
        } else if (time > 2592000000) {
          return '30天前发布'
        }
        // else {
        // 	return `${Math.floor(Math.floor(Math.floor(Math.round(time/1000)/60)/60)/24)}天前发布`
        // }
      } else {
        let time = new Date().getTime() - Number(row.refresh_time)
        if (time < 60000) {
          return '刚刚更新'
        } else if (time < 3600000) {
          return `${Math.floor(
            Math.floor(Math.round(time / 1000) / 60)
          )}分钟前更新`
        } else if (time < 86400000) {
          return `${Math.floor(
            Math.floor(Math.round(time / 1000) / 60) / 60
          )}小时前更新`
        } else if (time < 2592000000) {
          return `${Math.floor(
            Math.floor(Math.floor(Math.round(time / 1000) / 60) / 60) / 24
          )}天前更新`
        } else if (time > 2592000000) {
          return '30天前更新'
        }

        // else {
        // 	return `${Math.floor(Math.floor(Math.floor(Math.round(time/1000)/60)/60)/24)}天前更新`
        // }
      }
    },

    formateDuration(row) {
      let time = new Date().getTime() - row.create_time
      return this.formatDuring(time)
    },
    formatDuring(millisecond) {
      var days = parseInt(millisecond / (1000 * 60 * 60 * 24))
      var hours = parseInt(
        (millisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      var minutes = parseInt((millisecond % (1000 * 60 * 60)) / (1000 * 60))
      var seconds = (millisecond % (1000 * 60)) / 1000
      if (days < 7) {
        return `<span>${days + '天' + hours + '小时'}</span>`
      } else if (days < 14) {
        return `<span>${days + '天' + hours + '小时'}</span>`
      } else {
        return `<span>${days + '天' + hours + '小时'}</span>`
      }
    },
    formateAsks(row) {
      let num = 0
      for (let k in row) {
        if (k == 'demand_direction_codes' && row[k]) {
          num += 1
        }
        if (k == 'demand_max_hour_amount' && row[k]) {
          num += 1
        }
        if (k == 'demand_academy_major_status' && row[k]) {
          num += 1
        }
        if (k == 'demand_extra_info' && row[k]) {
          num += 1
        }
        if (k == 'extend_info_demand_trial_listen_status' && row[k]) {
          num += 1
        }
        if (
          k == 'extend_info_demand_professional_experience_status' &&
          row[k]
        ) {
          num += 1
        }
        if (k == 'extend_info_demand_before_first_rank' && row[k]) {
          num += 1
        }
        if (k == 'extend_info_demand_min_first_total_score' && row[k]) {
          num += 1
        }
        if (k == 'extend_info_demand_min_first_professional_score' && row[k]) {
          num += 1
        }
        if (k == 'demand_same_reference_book_status' && row[k]) {
          num += 1
        }
      }
      return num
    },
    formateDirections(row) {
      let names = row.demand_direction_names.split(',')
      let codes = row.demand_direction_codes.split(',')

      return names
        .map((v, i) => {
          return `(${codes[i]})${v}`
        })
        .join(',')
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    handleToDeleteAll() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要删除的数据')
        return
      }
      this.$confirm('确定要删除这些信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let ids = this.chooseList.map((v) => {
          return v.id
        })
        let str = crmUrl()
        this.$axios({
          url: `${str}/api/v1/sharePlatform/findOrder/list`,
          method: 'delete', //可以省略 不写默认是get
          data: { ids },
        }).then((response) => {
          this.fetchList()
        })
      })
    },
    handleToEdit(row) {
      this.editId = row.id
      this.editDialog = true
    },
    handleToDetails(row) {
      this.$refs.admixtureDetail.open(row.id)
    },
    handleToRecord(row) {
      this.recordItem = row
      this.recordDialog = true
      this.recordId = row.id
      this.handle_receiver_id = row.receiver_company_user_ids_real
        ? row.receiver_company_user_ids_real
        : null
    },
  },
}
</script>
          
<style lang="less" scoped>
::v-deep .timef .el-input--small .el-input__inner {
  width: 90px !important;
}
.content {
  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
  }

  .el-link {
    font-size: 14px;
    margin: 0px 5px;
  }

  .img-list {
    margin-top: 12px;
    display: flex;
    align-items: center;

    img {
      width: 140px;
      height: 140px;
      margin-right: 12px;
      border-radius: 4px;
    }
  }

  .popver-content {
    .item {
      display: flex;
      align-items: flex-start;
    }
  }
}

.count-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
}

.other {
  font-weight: 600;
  font-size: 16px;
}

.line {
  opacity: 1;
  border: 1px solid #656565;
  margin: 12px 0px;
}
.footer {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>
          