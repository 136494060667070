<template>
  <div>
    <el-dialog top="5vh"
               title="确认列表"
               :visible.sync="dialogFormVisible"
               @close="handleToClose"
               :close-on-click-modal="false"
               width="626px">
      <div class="head">
        <div class="button-set">
          <el-badge :value="record_msg_unreaded_count"
                    :hidden="record_msg_unreaded_count == 0"
                    :max="99"
                    style="margin-right: 8px">
            <el-button type="primary"
                       size="small"
                       @click="handleToOrderRecord">沟通记录</el-button>
          </el-badge>
          <el-badge :hidden="allocation_order_teacher_unconfirm_count == 0"
                    :value="allocation_order_teacher_unconfirm_count"
                    :max="99"
                    style="margin-right: 8px">
            <el-button type="primary"
                       size="small"
                       @click="openEditMatching">修改匹配</el-button>
          </el-badge>

          <el-button type="danger"
                     size="small"
                     v-if="order && order.status != 6 && !type"
                     @click="cancelMatching">取消匹配</el-button>
        </div>
        <el-tabs v-model="activeName"
                 type="card">
          <el-tab-pane label="确认列表"
                       name="first"> </el-tab-pane>
          <!-- <el-tab-pane label="确认列表" name="second"></el-tab-pane> -->
        </el-tabs>

        <div v-loading="loading">
          <el-empty :image-size="200"
                    v-if="!result || result.length <= 0"></el-empty>
          <div class="card"
               v-for="(item, index) in result"
               :key="index">
            <div class="card-title">
              {{
                item.hide_name_status == 1
                  ? `${item.realname}(${item.nickname})`
                  : item.realname
              }}
            </div>
            <div class="descriptions">
              <div class="chunk"
                   style="margin-right: 77px">
                <div class="chunk-row">
                  <div>ID</div>
                  <div>{{ item.id }}</div>
                </div>
                <div class="chunk-row">
                  <div>状态</div>
                  <div>
                    <el-tag size="small"
                            v-if="formateStatus(item, 0)"
                            type="warning">待确认</el-tag>
                    <el-tag size="small"
                            v-if="formateStatus(item, 1)"
                            type="success">已确认</el-tag>
                    <el-tag type="danger"
                            size="small"
                            v-if="formateStatus(item, 2)">已驳回</el-tag>
                  </div>
                </div>
                <div class="chunk-row">
                  <div>录取学院</div>
                  <div>
                    {{ item.recruit_college_name }}
                  </div>
                </div>
                <!-- <div class="chunk-row">
                  <div>结算周期</div>
                  <div>
                    {{
                      teachers.find((v) => {
                        return v.teacher_id == item.id;
                      }).sign_teacher_settle_cycle_type ==1? '月结' :'周结'
                    }}
                  </div>
                </div> -->
                <div class="chunk-row">
                  <div>课时费</div>
                  <div v-if=" teachers.find((v) => {
                        return v.teacher_id == item.id;
                      }).sign_teacher_salary_split_type==1">
                    {{
                      teachers.find((v) => {
                        return v.teacher_id == item.id;
                      }).sign_teacher_lesson_hour_amount || 0
                    }}元/小时
                    <!-- {{
                      item.sign_order_lesson_hour_amount
                        ? item.sign_order_lesson_hour_amount
                        : 0
                    }}元/小时 -->
                  </div>
                  <div v-if=" teachers.find((v) => {
                        return v.teacher_id == item.id;
                      }).sign_teacher_salary_split_type==2">
                    {{
                      teachers.find((v) => {
                        return v.teacher_id == item.id;
                      }).sign_teacher_salary_profit_fix_amount || 0
                    }} + {{
                      teachers.find((v) => {
                        return v.teacher_id == item.id;
                      }).sign_teacher_bonus_profit_fix_amount || 0
                    }}元/小时
                    <!-- {{
                      item.sign_order_lesson_hour_amount
                        ? item.sign_order_lesson_hour_amount
                        : 0
                    }}元/小时 -->
                  </div>
                </div>
                <div class="chunk-row">
                  <div>手机号</div>
                  <div>{{ item.mobile }}</div>
                </div>
                <div class="chunk-row">
                  <div>匹配师</div>
                  <div>
                    {{ formateAllocation_manage_id(item) }}
                  </div>
                </div>
                <div class="chunk-row">
                  <div>签约课时费</div>
                  <div>{{ item.fee_amount ? item.fee_amount : 0 }}元/小时</div>
                </div>
              </div>

              <div class="chunk">
                <div class="chunk-row">
                  <div>年级</div>
                  <div>{{ item.recruit_year }}年</div>
                </div>
                <div class="chunk-row">
                  <div>录取院校</div>
                  <div>
                    {{ item.recruit_academy_name }}
                  </div>
                </div>
                <div class="chunk-row">
                  <div>录取专业</div>
                  <div>
                    <!-- ({{item.recruit_major_code}}) -->
                    {{ item.recruit_major_name }}
                  </div>
                </div>
                <div class="chunk-row">
                  <div>本单课时</div>
                  <div>
                    {{
                      item.sign_order_lesson_hour
                        ? item.sign_order_lesson_hour
                        : 0
                    }}小时
                  </div>
                </div>
                <div class="chunk-row">
                  <div>微信号</div>
                  <div>{{ item.wechat }}</div>
                </div>
                <div class="chunk-row">
                  <div>签约有效期</div>
                  <div>
                    {{
                      item.contract_end_time
                        ? $moment(item.contract_end_time).format(
                            "YYYY-MM-DD HH:mm"
                          )
                        : ""
                    }}
                  </div>
                </div>
                <div class="chunk-row">
                  <div>平均课时费</div>
                  <div>
                    {{
                      item.teacher_average_lesson_hour_amount
                        ? item.teacher_average_lesson_hour_amount.toFixed(2)
                        : 0
                    }}元/小时
                  </div>
                </div>
              </div>
            </div>

            <div class="button-set-bottom">
              <el-button size="small"
                         @click="handleToDetail(item)">查看详情</el-button>
              <el-button type="primary"
                         size="small"
                         @click="handleToConfirm(item)">确认</el-button>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="between">
        <TableTittle :title="TableName"></TableTittle>
        <div>
          <el-button
            type="danger"
            size="small"
            style="margin-bottom: 12px"
            @click="cancelMatching"
            v-if="order && order.status != 6 && !type"
            >取消匹配</el-button
          >
          <el-button
            type="danger"
            size="small"
            style="margin-bottom: 12px"
            disabled
            v-else-if="!type"
            >匹配已取消</el-button
          >
          <el-button
            type="primary"
            size="small"
            v-if="!type"
            style="margin-bottom: 12px"
            @click="openEditMatching"
            >修改匹配</el-button
          >
        </div>
      </div>
      <el-table
        :data="result"
        row-key="id"
        :header-cell-style="{
          backgroundColor: '#F2F6FC',
        }"
        border
        v-loading="loading"
        @expand-change="expandChange"
        :expand-row-keys="expands"
      >
        <el-table-column type="expand" prop="沟通记录">
          <template slot-scope="scope">
            <div class="expand-wrap">
              <el-table
                :data="scope.row.records ? scope.row.records : []"
                :border="false"
                :header-cell-style="{
                  backgroundColor: '#f9f9f9',
                }"
              >
                <el-table-column label="日期" align="center" width="150">
                  <template slot-scope="scope">
                    {{
                      scope.row.create_time
                        ? $moment(scope.row.create_time).format(
                            "YYYY-MM-DD HH:mm"
                          )
                        : "无"
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="记录人" align="center" width="100">
                  <template slot-scope="scope">
                    {{
                      scope.row.manage_creator
                        ? scope.row.manage_creator.nickname
                        : "无"
                    }}
                  </template>
                </el-table-column>
                <el-table-column label="类别" align="center" width="100">
                  <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.type == 1">分配</el-tag>
                    <el-tag size="mini" v-if="scope.row.type == 2">备注</el-tag>
                    <el-tag size="mini" v-if="scope.row.type == 3">修改</el-tag>
                    <el-tag size="mini" v-if="scope.row.type == 4">跟进</el-tag>
                    <el-tag size="mini" v-if="scope.row.type == 5">签单</el-tag>
                    <el-tag size="mini" v-if="scope.row.type == 6">申请</el-tag>
                    <el-tag size="mini" v-if="scope.row.type == 7">沟通</el-tag>
                    <el-tag size="mini" v-if="scope.row.type == 8">记录</el-tag>
                    <el-tag size="mini" v-if="scope.row.type == 9">提交</el-tag>
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 10"
                      type="warning"
                      >修改</el-tag
                    >
                    <el-tag size="mini" v-if="scope.row.type == 11"
                      >加入</el-tag
                    >
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 12"
                      type="success"
                      >确认</el-tag
                    >
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 13"
                      type="danger"
                      >驳回</el-tag
                    >
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 14"
                      type="danger"
                      >取消</el-tag
                    >
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 15"
                      type="warning"
                      >稍签</el-tag
                    >
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 16"
                      type="warning"
                      >完结</el-tag
                    >
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 17"
                      type="warning"
                      >推荐</el-tag
                    >
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 18"
                      type="success"
                      >确认</el-tag
                    >
                    <el-tag
                      size="mini"
                      v-if="scope.row.type == 19"
                      type="success"
                      >签约</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="沟通记录"
                  align="left"
                  show-overflow-tooltip
                  prop="record"
                  min-width="500"
                >
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.record }}
                      <span style="color: #409eff">{{
                        formateAltManager(scope.row.alt_manage_ids)
                      }}</span>
                    </div>
                    <div class="img-list" v-if="scope.row.record_img_urls">
                      <img
                        v-for="(item, index) in JSON.parse(
                          scope.row.record_img_urls
                        )"
                        :key="index"
                        :src="item.url"
                        alt=""
                        srcset=""
                        @click="
                          openViewer(JSON.parse(scope.row.record_img_urls))
                        "
                      />
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" align="center" width="80">
        </el-table-column>
        <el-table-column
          prop="realname"
          label="姓名"
          align="center"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.realname }}
            {{ scope.row.hide_name_status ? `（${scope.row.nickname}）` : "" }}
          </template>
        </el-table-column>
        <el-table-column label="年级" align="center" width="100">
          <template slot-scope="scope">
            {{ scope.row.recruit_year }}年
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          align="center"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag
              size="small"
              v-if="formateStatus(scope.row, 0)"
              type="warning"
              >待确认</el-tag
            >
            <el-tag
              size="small"
              v-if="formateStatus(scope.row, 1)"
              type="success"
              >已确认</el-tag
            >
            <el-tag
              type="danger"
              size="small"
              v-if="formateStatus(scope.row, 2)"
              >已驳回</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          label="本单课时费"
          align="center "
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{
              teachers.find((v) => {
                return v.teacher_id == scope.row.id;
              }).sign_teacher_lesson_hour_amount || 0
            }}元/小时
          </template>
        </el-table-column>
        <el-table-column
          label="本单课时"
          align="center "
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{
              teachers.find((v) => {
                return v.teacher_id == scope.row.id;
              }).sign_teacher_lesson_hour || 0
            }}小时
          </template>
        </el-table-column>
        <el-table-column
          label="录取信息"
          align="center "
          width="500"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span
              >{{ scope.row.recruit_college_name }} |
              {{ scope.row.recruit_academy_name }} | ({{
                scope.row.recruit_major_code
              }}){{ scope.row.recruit_major_name }}
              {{
                scope.row.recruit_direction_code &&
                scope.row.recruit_direction_name
                  ? ` | (${scope.row.recruit_direction_code})${scope.row.recruit_direction_name}`
                  : ""
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="手机号"
          align="center"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="wechat"
          label="微信号"
          align="center"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="签约课时费"
          align="center "
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.fee_amount ? scope.row.fee_amount : 0 }}元/小时
          </template>
        </el-table-column>
        <el-table-column
          label="平均课时费"
          align="center "
          width="110"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{
              scope.row.teacher_average_lesson_hour_amount
                ? scope.row.teacher_average_lesson_hour_amount
                : 0
            }}元/小时
          </template>
        </el-table-column>
        <el-table-column label="匹配师" align="center" width="150">
          <template slot-scope="scope">
            {{ formateAllocation_manage_id(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column label="签约有效期" align="center" width="150">
          <template slot-scope="scope">
            {{
              scope.row.contract_end_time
                ? $moment(scope.row.contract_end_time).format(
                    "YYYY-MM-DD HH:mm"
                  )
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-link
              type="primary"
              :underline="false"
              @click="handleToDetail(scope.row)"
              >查看详情</el-link
            >
            <el-link
              v-if="!type"
              type="primary"
              :underline="false"
              @click="handleToConfirm(scope.row)"
              >确认</el-link
            >
          </template>
        </el-table-column>
      </el-table> -->
    </el-dialog>

    <Record :teacher_allocation_order_id="order_id"
            :isVisible="recordDialog"
            @close="recordDialog = false"
            @submit="fetchDetail()"
            :type="'order_read'"></Record>

    <SeniorDetail :isVisible="SeniorDetailDialog"
                  @close="SeniorDetailDialog = false"
                  :id="detailId" />
    <ConfirmGradeTeacher :isVisible="confirmDialog"
                         @close="confirmDialog = false"
                         :id="confirmId"
                         @submit="fetchDetail" />
    <EditMatching :isVisible="editDialog"
                  @close="editDialog = false"
                  :id="editId"
                  :teachers="teachers"
                  @submit="fetchDetail" />
    <MatchingCancle :isVisible="cancelDialog"
                    @close="cancelDialog = false"
                    :id="order_id"
                    @submit="fetchDetail" />
    <el-image-viewer v-if="showViewer"
                     :url-list="imgs"
                     :zIndex="999999999"
                     :on-close="closeViewer" />
  </div>
</template>
  <script>
import TableTittle from '@/components/TableTittle/TableTittle.vue'
import SeniorDetail from '@/components/DialogComponents/TeacherResources/SeniorDetail.vue'
import ConfirmGradeTeacher from '@/components/DialogComponents/TeacherResources/ConfirmGradeTeacher.vue'
import EditMatching from '@/components/DialogComponents/TeacherResources/EditMatching.vue'
import MatchingCancle from '@/components/DialogComponents/TeacherResources/MatchingCancle.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import Record from '@/components/DialogComponents/TeacherResources/Record.vue'
import {
  teacherlist,
  gradelist,
  cancelOrdereTa,
  taOne,
  taRecordList,
  teacherorderlist,
  teacherdispatchorderlist,
} from '@/request/api'
export default {
  components: {
    TableTittle,
    SeniorDetail,
    ConfirmGradeTeacher,
    EditMatching,
    MatchingCancle,
    ElImageViewer,
    Record,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    name: {
      default: null,
    },
    type: {
      type: Boolean,
      default: false,
    },
    grade_id: {
      type: Number,
      default: null,
    },
    order_id: {
      type: Number,
      default: null,
    },
    teachers: {
      type: Array,
      default: () => [],
    },

    /*   ids: {
        type: String,
        default: '',
      },
      teachers: {
        type: Array,
        default: () => [],
      }, */
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      activeName: 'first',
      result: [],
      record_msg_unreaded_count: 0,
      allocation_order_teacher_unconfirm_count: 0,
      TableName: '老师列表',
      SeniorDetailDialog: false,
      detailId: null,
      confirmId: null,
      confirmDialog: false,
      editId: null,
      editDialog: false,
      loading: false,
      order: null,
      cancelDialog: false,
      expands: [],
      showViewer: false,
      imgs: [],
      orderId: null,
      recordGradeId: '',
      recordDialog: false,
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        this.expands = []

        this.fetchDetail()
      }
    },
  },
  methods: {
    handleToOrderRecord() {
      // this.orderId = this.grade_id;
      // this.recordGradeId = this.order_id;
      this.recordDialog = true
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    },
    openViewer(url) {
      this.imgs = url.map((v) => {
        return v.url
      })
      this.showViewer = true
    },
    expandChange(row) {
      let params = {
        teacher_allocation_order_id: this.$route.params.id,
        teacher_id: row.id,
        isPaging: 0,
      }
      taRecordList(params).then((response) => {
        let res = response.data
        this.$set(row, 'records', res.data.list)
      })
    },
    fetchOrderDetail() {
      taOne({
        id: this.order_id,
      }).then((response) => {
        let res = response.data
        this.order = res.data || []
      })
    },
    fetchDetail() {
      this.fetchOrderDetail()
      let ids = this.teachers
        .map((v) => {
          return v.teacher_id
        })
        .join(',')
      if (ids) {
        this.fetchList(ids)
      } else {
        this.result = []
      }
    },
    fetchList(ids) {
      this.loading = true
      const params = {
        isPaging: 0,
        ids: ids,
      }
      teacherlist(params).then((response) => {
        let res = response.data
        this.result = res.data.list || []
        this.loading = false
        this.getTeacherorderlist()
      })
    },

    getTeacherorderlist() {
      if (this.name == '匹配') {
        teacherorderlist({
          ids: this.grade_id,
        }).then((res) => {
          if (res.data.code == 0) {
            this.record_msg_unreaded_count =
              res.data.data.list[0].record_msg_unreaded_count
            this.allocation_order_teacher_unconfirm_count =
              res.data.data.list[0].allocation_order_teacher_unconfirm_count
          }
          // console.log("res:", res);
        })
      } else {
        teacherdispatchorderlist({
          ids: this.grade_id,
        }).then((res) => {
          this.record_msg_unreaded_count =
            res.data.data.list[0].record_msg_unreaded_count
          this.allocation_order_teacher_unconfirm_count =
            res.data.data.list[0].allocation_order_teacher_unconfirm_count
        })
      }

      // teacherdispatchorderlist
    },

    handleToClose() {
      this.$emit('close')
      this.$emit('submit')
    },
    formateStatus(row, status) {
      if (this.teachers.length) {
        let number = this.teachers.find((v) => {
          return v.teacher_id == row.id
        }).confirm_status
        if (number == status) {
          return true
        } else {
          return false
        }
      }
    },
    formateAllocation_manage_id(row) {
      var ids =
        this.teachers && this.teachers.length
          ? this.teachers.find((v) => {
              return v.teacher_id == row.id
            }).allocation_manage_id
          : ''
      if (ids) {
        let arr = ids
          .toString()
          .split(',')
          .map((v) => {
            return this.$store.state.staffOptions.find((t) => {
              return t.id == v
            }).nickname
          })
        return arr.join(',')
      } else {
        return '无'
      }
    },
    Origin() {},
    handleToDetail(row) {
      this.detailId = row.id
      this.SeniorDetailDialog = true
    },
    handleToConfirm(row) {
      let id = this.teachers.find((v) => {
        return v.teacher_id == row.id
      }).id
      this.confirmId = id
      this.confirmDialog = true
    },
    openEditMatching() {
      this.editId = this.order_id
      this.editDialog = true
    },
    formateAltManager(ids) {
      if (ids) {
        let str = ids.split(',').map((v) => {
          return `@${
            this.$store.state.staffOptions.find((t) => {
              return t.id == v
            }).nickname
          }`
        })

        return str.join(',')
      } else {
        return ''
      }
    },
    cancelMatching() {
      this.cancelDialog = true
      /*      this.$confirm("确定要取消该匹配吗", "提示", {
             confirmButtonText: "确定",
             cancelButtonText: "取消",
             type: "warning",
           }).then(() => {
             cancelOrdereTa({
               order_id: this.order_id,
             }).then((response) => {
               let res = response.data
               if (res.code == 0) {
                 this.$message.success('匹配订单已取消')
                 this.$emit('submit')
                 this.handleToClose()
               }
             });
           }) */
    },
  },
}
</script>
        <style lang="less" scoped>
.content {
  .between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.el-table__expanded-cell {
  padding: 0px !important;
}
.img-list {
  margin-top: 12px;
  display: flex;
  align-items: center;
  img {
    width: 140px;
    height: 140px;
    margin-right: 12px;
    border-radius: 4px;
  }
}

/deep/.el-badge__content.is-fixed {
  right: 14px;
  z-index: 999999 !important;
}

.button-set > .el-badge:nth-of-type(2) /deep/.el-badge__content.is-fixed {
  right: 414px;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  background: #ecf2fe;
  border-top: 2px solid #ecf2fe;
}
/deep/.el-tabs__item.is-active {
  background: white !important;
  border-top: 2px solid #1890ff !important;
}

div {
  box-sizing: border-box;
}
.head {
  position: relative;
  .button-set {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
  }

  .card {
    width: 100%;
    // height: 317px;
    margin-top: 7px;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08),
      0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 6px 30px 5px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 16px 18px;
    margin-bottom: 20px;
    .card-title {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
    }
    .descriptions {
      display: flex;
      justify-content: space-between;
      .chunk {
        flex: 1;
        .chunk-row {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          line-height: 22px;
          display: flex;
          margin-top: 8px;
          justify-content: space-between;
          & > div:nth-of-type(1) {
            flex-shrink: 0;
            color: #252525;
            width: 90px;
          }
          & > div:nth-of-type(2) {
            color: #6b6b6b;
          }
        }
      }
    }

    .button-set-bottom {
      margin-top: 18px;
      display: flex;
      justify-content: flex-end;
      // flex-direction: flex-start;
    }
  }
}
</style>