<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item>结课奖励</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query">

        <el-form-item label="状态"
                      prop="order_no">
          <el-select v-model="query.status"
                     placeholder="请选择"
                     clearable
                     @change="getList(query, true)">
            <el-option v-for="item in filter.courseOrderTypes"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>

        </el-form-item>
        <!-- <el-form-item label="审核状态"
                      prop="order_no">
          <el-select v-model="query.finish_course_bonus_check_statuses"
                     placeholder="请选择"
                     clearable
                     @change="getList(query, true)">
            <el-option v-for="item in filter.status"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>

        </el-form-item> -->

        <el-form-item label="创建时间">
          <el-date-picker class="datetime"
                          v-model="createDatetime"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          clearable
                          :default-time="['00:00:00', '23:59:59']"
                          @change="timechange">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="table-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form size="small"
                 :inline="true">
          <el-form-item label-width="32px">
            <el-radio-group v-model="query.finish_course_bonus_check_statuses"
                            @change="getList(query,true)">
              <el-radio-button :label="''">全部</el-radio-button>
              <el-radio-button :label="'1'">待审核</el-radio-button>
              <el-radio-button :label="'3'">已拒绝</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ textAlign: 'center', fontSize: '13px' }"
                border>
        <el-table-column prop="id"
                         label="ID"
                         width="80"
                         align="center">
        </el-table-column>
        <el-table-column prop="ref_senior_order_course_order_type"
                         label="课酬单类型"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.ref_senior_order_course_order_type == 1 ? "标准课酬单" : "派遣课酬单" }}
          </template>
        </el-table-column>
        <el-table-column prop="order_name"
                         label="账单名称"
                         width="220"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="align-left">
              {{ scope.row.goods_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status"
                         label="状态"
                         width="80"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.status == 0"
                    type="warning">待结算</el-tag>
            <el-tag size="small"
                    v-else-if="scope.row.status == 1"
                    type="success">已入账</el-tag>

          </template>
        </el-table-column>
        <el-table-column prop="status"
                         label="审核状态"
                         width="80"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.finish_course_bonus_check_status == 1"
                    type="warning">待审核</el-tag>
            <el-tag size="small"
                    v-else-if="scope.row.finish_course_bonus_check_status == 2"
                    type="success">已通过</el-tag>
            <el-tag size="small"
                    v-else-if="scope.row.finish_course_bonus_check_status == 3"
                    type="danger">已拒绝</el-tag>

          </template>
        </el-table-column>

        <el-table-column label="课时"
                         width="80"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.ref_senior_order_total_lesson_hour }}小时
          </template>
        </el-table-column>
        <el-table-column label="结课奖励金额"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.ref_senior_order_bonus_amount ||0}}元
          </template>
        </el-table-column>
        <el-table-column prop="profit_user_realname"
                         label="姓名"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="profit_user_mobile"
                         label="手机号"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="finish_course_bonus_refuse_reason"
                         label="拒绝理由"
                         width="300"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.finish_course_bonus_refuse_reason ? scope.row.finish_course_bonus_refuse_reason : "/" }}
          </template>
        </el-table-column>
        <!-- <el-table-column prop="log_student_realnames"
                         label="关联学员"
                         width="200"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="log_company_info_names"
                         label="关联机构"
                         width="150"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="log_learn_manager_nickames"
                         label="学管师"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
        </el-table-column> -->
        <el-table-column prop="remark"
                         label="备注"
                         width="300"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.remark ? scope.row.remark : "无" }}
          </template>
        </el-table-column>

        <!-- <el-table-column prop="creator_realname"
                         label="创建人"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.creator_nickname ? scope.row.creator_nickname : "" }}
          </template>
        </el-table-column>
        <el-table-column prop="auditor_realname"
                         label="审核人"
                         width="100"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.auditor_nickname ? scope.row.auditor_nickname : "" }}
          </template>
        </el-table-column> -->
        <el-table-column prop="create_time"
                         label="创建日期"
                         width="180"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{
              $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="update_time"
                         label="审核日期"
                         width="180"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{
              scope.row.audit_time
                ? $moment(scope.row.audit_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="update_time"
                         label="操作"
                         width="250"
                         fixed="right"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <!-- <el-link type="danger"
                     :underline="false"
                     @click="handlePass(scope.row)">通过</el-link>
            <el-link type="success"
                     :underline="false"
                     @click="handleRefuse(scope.row)">驳回</el-link> -->
            <el-link type="primary"
                     :underline="false"
                     @click="handleDetail(scope.row)">账单详情</el-link>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>

    <addHourBill :isVisible="EditSeniorBillDialog"
                 @close="EditSeniorBillDialog = false"
                 :content="EditSeniorBillContent"
                 :type="type"
                 @submit="getList(query)" />
  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'

import addHourBill from '@/components/DialogComponents/SeniorBill/addHourBill'

import { adminUrl } from '@/utils/helper.js'
import { profitLogs, transferAccountByHandle } from '../../../request/api'
export default {
  components: {
    TableTittle,
    addHourBill,
  },
  data() {
    return {
      TableName: '结课奖励',
      tableData: [],
      pageTotal: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        transfer_account_types: 2,
        finish_course_bonus_check_statuses: '1',
        // status: '0',
      },
      AddSeniorBillDialog: false,
      EditSeniorBillDialog: false,
      EditSeniorBillContent: {},
      filter: {
        status: [
          { label: '全部', value: '' },
          { label: '待审核', value: '1' },
          { label: '已通过', value: '2' },
          { label: '已拒绝', value: '3' },
        ],
        courseOrderTypes: [
          { label: '已入账', value: '1' },
          { label: '待结算', value: '0' },
        ],
      },
      createDatetime: '',
      pageIndex_total: 1,
      type: false,
    }
  },
  mounted() {
    this.getList(this.query)
  },
  watch: {
    // 时间数据监听
    createDatetime(value) {
      if (value) {
        this.query.startTime = this.$moment(value[0]).valueOf()
        this.query.endTime = this.$moment(value[1]).valueOf()
      } else {
        this.query.startTime = ''
        this.query.endTime = ''
      }
    },
  },
  methods: {
    handleDetail(row) {
      if (row.course_order_type == 2) {
        this.type = true
      } else {
        this.type = false
      }
      this.EditSeniorBillContent = Object.assign({}, row)
      console.log('EditSeniorBillContent', this.EditSeniorBillContent)
      this.EditSeniorBillDialog = true
    },
    timechange(e) {
      if (e == null) {
        this.query.startTime = ''
        this.query.endTime = ''
      }
      this.getList(this.query, true)
    },
    // 表格选中取值
    handleSelectionChange(val) {
      this.deleteList = val
    },
    // 学长课酬订单（查询列表）
    getList(query, isReset = false) {
      if (isReset) {
        this.query.pageIndex = 1
      }
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/member/profit/logs`,
        method: 'get', //可以省略 不写默认是get
        params: query,
      }).then((res) => {
        this.tableData = res.data.data.list || []
        console.log('this.tableData', this.tableData)
        this.pageTotal = res.data.data.count
        this.pageIndex_total = Math.ceil(this.pageTotal / this.query.pageSize)
        if (
          this.query.pageIndex > this.pageIndex_total &&
          this.pageIndex_total != 0
        ) {
          this.$set(this.query, 'pageIndex', this.pageIndex_total)
          this.getList(this.query)
        }
      })
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.getList(this.query)
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.getList(this.query)
    },
    openCreateDialog() {
      this.AddSeniorBillDialog = true
    },
    openEditDialog(row) {
      if (row.course_order_type == 2) {
        this.type = true
      } else {
        this.type = false
      }
      this.EditSeniorBillContent = Object.assign({}, row)
      this.EditSeniorBillDialog = true
    },
  },
}
</script>

<style lang="less" scoped>
.breadcrumb {
  display: flex;
  align-items: center;
  height: 30px;
  justify-content: space-between;
}
.block {
  float: right;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}
.demo-form-inline {
  border-bottom: 2px dashed #d2d5df;
}
.popoverStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 92px;
}
.link-template {
  .el-link {
    margin: 0px 5px;
  }
}
.table-header {
  display: flex;
  justify-content: space-between;
}
.align-left {
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>