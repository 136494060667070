<template>
  <div class="container withdraw">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>财务</el-breadcrumb-item>
        <el-breadcrumb-item>提现审核</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
      </div>
    </div>
    <div class="filters card">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query">
        <el-form-item label="提现用户"
                      prop="user">
          <el-input v-model="query.user"
                    placeholder="支持用户昵称/手机号"
                    @input="fetchWithdrawList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="排序"
                      prop="type">
          <el-select style="width: 150px"
                     v-model="query.type"
                     size="mini"
                     @change="fetchWithdrawList(true)"
                     placeholder="请选择">
            <el-option v-for="item in typeList"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请提现时间">
          <el-date-picker class="datetime"
                          v-model="createDatetime"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="fetchWithdrawList  (true)"
                          clearable
                          :default-time="['00:00:00', '23:59:59']"></el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <div class="results card">
      <div class="table-header">
        <h3 class="title">申请列表</h3>
        <el-form label-width="70px"
                 size="small">
          <el-form-item style="">
            <el-radio-group v-model="query.status"
                            @change="fetchWithdrawList(true)">
              <el-badge :value="uncheck"
                        class="item"
                        :hidden="uncheck==0">
                <el-radio-button v-for="(item, index) in filter.status"
                                 :key="index"
                                 :label="item.value">
                  {{ item.label }}
                </el-radio-button>
              </el-badge>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-table :data="results"
                  border
                  size="mini"
                  style="width: 100%">
          <el-table-column prop="id"
                           label="ID"
                           width="80"
                           align="center"></el-table-column>
          <el-table-column label="申请人"
                           min-width="120"
                           align="center"
                           show-overflow-tooltip="">
            <template slot-scope="scope">
              <div v-if="scope.row.user">
                <p>{{ scope.row.user.realname }}</p>
                <p>{{ scope.row.user.mobile }}</p>
              </div>
              <span v-else-if="scope.row.withdraw_user_type==2">
                <p>{{ scope.row.company_user.company_ref_name }}</p>
                <p>{{ scope.row.company_user.alias }}</p>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="申请提现时间"
                           min-width="160"
                           align="center">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.create_time).format('YYYY-MM-DD HH:mm') }}</span>
            </template>
          </el-table-column>

          <el-table-column label="提现金额(元)"
                           min-width="120"
                           align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.amount }}元</span>
            </template>
          </el-table-column>
          <el-table-column label="手续费(元)"
                           min-width="120"
                           align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.service_charge }}元</span>
            </template>
          </el-table-column>
          <el-table-column label="到账金额(元)"
                           min-width="120"
                           align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.final_amount }}元</span>
            </template>
          </el-table-column>
          <el-table-column label="方式"
                           width="120"
                           align="center">
            <template slot-scope="scope">
              <span>{{ handleRender(scope.row.fee_account_type, 'type') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="排队号"
                           width="120"
                           align="center">
            <template slot-scope="scope">
              <span>{{scope.row.queue_sort}}号</span>
            </template>
          </el-table-column>
          <el-table-column label="提现账号姓名"
                           min-width="120"
                           align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.fee_account_name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="提现账号"
                           min-width="180"
                           align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.fee_account_card }}</span>
            </template>
          </el-table-column>
          <el-table-column label="提现状态"
                           width="120"
                           align="center">
            <template slot-scope="scope">
              <el-tag size="mini"
                      v-if="scope.row.status==0">待审核</el-tag>
              <el-tag size="mini"
                      v-if="scope.row.status==1"
                      type="success">提现成功</el-tag>
              <el-tag size="mini"
                      v-if="scope.row.status==2"
                      type="danger">已撤销</el-tag>
              <el-tag size="mini"
                      v-if="scope.row.status==3"
                      type="danger">已拒绝</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="完成时间"
                           min-width="160"
                           align="center">
            <template slot-scope="scope">
              <span>{{ $moment(scope.row.update_time).format('YYYY-MM-DD HH:mm')}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           width="120"
                           align="center"
                           fixed="right"
                           v-if="isfixed">
            <template slot-scope="scope">
              <el-button size="mini"
                         type="text"
                         @click="handleWithdrawInfo(scope.row)">提现详情</el-button>
            </template>
          </el-table-column>
          <el-table-column label=""
                           width="40"
                           align="center"
                           fixed="right"
                           v-else>
            <template slot-scope="scope">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  ...
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a"
                                    style="margin-bottom:6px;"><span @click="handleWithdrawInfo(scope.row)"
                          style="font-size:16px;color: #409EFF;">
                      提现详情
                    </span></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-pagination background
                       layout="total, prev, pager, next, sizes, jumper"
                       :current-page="Number(query.pageIndex)"
                       :page-sizes="[10, 20, 50, 100, 500]"
                       :page-size="Number(query.pageSize)"
                       :total="Number(pageTotal)"
                       @size-change="handleSizeChange"
                       @current-change="handlePageChange"></el-pagination>
      </div>
    </div>
    <WithdrawInfoDialog :visible="dialogVisible"
                        :content="withdrawInfo"
                        :cid="cid"
                        @submit="fetchWithdrawList"
                        :withdraw_user_type="withdraw_user_type"
                        @close="dialogVisible = false" />

  </div>
</template>

<script>
import { adminUrl } from '@/utils/helper.js'
import WithdrawInfoDialog from '@/components/DialogComponents/WithDrawInfo/WithDrawInfo.vue'

export default {
  components: {
    WithdrawInfoDialog,
  },
  data() {
    return {
      uncheck: 0,
      dialogVisible: false,
      withdrawInfo: {},
      query: {
        type: '0',
        status: '0',
        user: '',
        startTime: '',
        endTime: '',
        pageIndex: 1,
        pageSize: localStorage.getItem('pageSize')
          ? Number(localStorage.getItem('pageSize'))
          : 10,
        isPaging: 1,
      },
      createDatetime: '',
      pageTotal: 10,
      typeList: [
        {
          label: '按时间排序',
          value: '0',
        },
        {
          label: '按排队号排序',
          value: '1',
        },
      ],
      filter: {
        status: [
          { label: '全部', value: '' },
          { label: '机构', value: '2' },
          { label: '学长', value: '1' },
          { label: '待审核', value: '0' },
          /*    { label: "提现中", value: "0" },
             { label: "提现成功", value: "1" },
             { label: "已撤销", value: "2" },
             { label: "被驳回", value: "3" }, */
        ],
      },
      results: [],
      isfixed: document.body.clientWidth < 820 ? false : true,
      pageIndex_total: 1,
      withdraw_user_type: 1,
      cid: null,
    }
  },
  watch: {
    // 时间数据监听
    createDatetime(value) {
      if (value) {
        this.query.startTime = this.$moment(value[0]).valueOf()
        this.query.endTime = this.$moment(value[1]).valueOf()
      } else {
        this.query.startTime = ''
        this.query.endTime = ''
        this.fetchWithdrawList()
      }
    },
  },
  created() {
    this.fetchWithdrawList()
    this.fetchWithdrawCount()
  },
  methods: {
    fetchWithdrawList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      if (this.query.type == '1') {
        this.query.is_sort_by_queue_sort = 1
      } else {
        this.query.is_sort_by_queue_sort = ''
      }

      let str = adminUrl()

      let data = JSON.parse(JSON.stringify(this.query))
      if (data.status == 1 || data.status == 2) {
        data.withdraw_user_types = data.status
        data.status = ''
      }
      this.$axios({
        url: `${str}/api/v1/back/member/withdraw/order/list`,
        method: 'get', //可以省略 不写默认是get
        params: data,
      }).then((res) => {
        this.results = res.data.data.list || []
        this.pageTotal = res.data.data.count
        this.pageIndex_total = Math.ceil(this.pageTotal / this.query.pageSize)
        if (
          this.query.pageIndex > this.pageIndex_total &&
          this.pageIndex_total != 0
        ) {
          this.$set(this.query, 'pageIndex', this.pageIndex_total)
          this.fetchWithdrawList(this.query)
        }
        this.fetchWithdrawCount()
      })
    },
    fetchWithdrawCount() {
      let str = adminUrl()
      let data = JSON.parse(JSON.stringify(this.query))
      data.status = 0
      data.withdraw_user_types = ''
      this.$axios({
        url: `${str}/api/v1/back/member/withdraw/order/list`,
        method: 'get', //可以省略 不写默认是get
        params: data,
      }).then((res) => {
        // console.log('cc',res)
        this.uncheck = res.data.data.count
        // this.filter.status[3].uncheck =  this.filter.status[2].uncheck +  this.filter.status[1].uncheck
      })

      // data.withdraw_user_types = 2
      // this.$axios({
      //   url: `${str}/api/v1/back/member/withdraw/order/list`,
      //   method: 'get',//可以省略 不写默认是get
      //   params: data,
      // }).then(res => {
      //   this.filter.status[1].uncheck = res.data.data.count
      //   this.filter.status[3].uncheck =  this.filter.status[2].uncheck +  this.filter.status[1].uncheck
      // })
    },
    // 提现详情
    handleWithdrawInfo(row) {
      if (row.withdraw_user_type == 2) {
        this.cid = row.company_user.cid
        console.log(this.cid)
      }
      this.withdraw_user_type = row.withdraw_user_type
      this.withdrawInfo = row
      this.dialogVisible = true
    },
    handleRender(value, type) {
      let status = {
        0: '待审核',
        1: '提现成功',
        2: '已撤销',
        3: '被驳回',
      }
      let types = {
        1: '支付宝',
        2: '微信',
      }
      if (type == 'status') {
        return status[value] || ''
      } else if (type == 'type') {
        return types[value] || ''
      }
      return ''
    },
    // 分页条数变化
    handleSizeChange(val) {
      this.$set(this.query, 'pageSize', val)
      localStorage.setItem('pageSize', val)
      this.fetchWithdrawList()
    },
    // 分页导航
    handlePageChange(val) {
      this.$set(this.query, 'pageIndex', val)
      this.fetchWithdrawList()
    },
    // 触发搜索按钮
    handleSearch() {
      this.$set(this.query, 'pageIndex', 1)
      this.fetchWithdrawList()
    },
    // 重置搜索条件
    handleFormReset(formName) {
      this.$refs[formName].resetFields()
      // 手动处理
      this.createDatetime = null
      setTimeout(() => {
        this.fetchWithdrawList()
      }, 20)
    },
  },
}
</script>

<style scoped lang="less">
// /deep/.el-badge__content.is-fixed{
//   z-index: 99 !important;
// }
.withdraw {
  .card {
    position: relative;
    color: #303133;
    background-color: #fff;
    border: 1px solid #ebeef5;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    padding: 20px;
  }
  .table-header {
    display: flex;

    justify-content: space-between;
    width: 100%;
  }
  .header {
    .breadcrumb {
      margin-bottom: 30px;
    }
    .title {
      font-size: 32px;
    }
    .content {
      display: flex;
      align-content: center;
    }

    .left,
    .right {
      flex: 1;
    }
    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: center;

      .button {
        font-size: 14px;
        padding: 12px 20px;
      }
    }
  }

  .filters {
    margin-top: 10px;

    .search-wrap {
      margin-bottom: -18px;

      .datetime {
        width: 380px;
      }

      /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        color: #409eff;
        background: #ecf5ff;
        border-color: #b3d8ff;
        box-shadow: none !important;
      }
    }
    .divider {
      border-bottom: 1px dashed #dcdfe6;
      margin: 15px 0;
    }
    .search-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .action-wrap {
      display: flex;
      justify-content: flex-end;
    }

    .filter-item {
      display: flex;

      .label {
        line-height: 32px;
        margin-right: 10px;
      }
      .values {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
      }
      .value-item {
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
        padding: 0 10px;
        margin-right: 5px;
        cursor: pointer;

        &.active {
          color: #fff;
          background: #409eff;
        }
      }
    }
  }
  .results {
    margin-top: 10px;

    .values {
      display: flex;
      flex-wrap: wrap;

      &:not(:nth-child(3n)) {
        margin-top: 10px;
      }
    }
    .experience-item {
      margin-right: 15px;
      margin-bottom: 15px;
    }
    .footer {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
}
</style>
