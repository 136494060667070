<template>
  <div class="content">
    <el-dialog top="5vh"
               :visible.sync="dialogFormVisible"
               :close-on-click-modal="false"
               width="1380px"
               title="账单详情"
               @open="open"
               @close="emitClose">
      <div class="header">
        <TableTitle :title="`${content.goods_name}`" />
        <div class="info-wrap">
          <el-descriptions :column="2"
                           class="fixed-width-descriptions"
                           :contentStyle="contentStyle">
            <el-descriptions-item label="姓名">{{
              content.profit_user_realname
            }}</el-descriptions-item>
            <el-descriptions-item label="账单名称">{{
              content.goods_name
            }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{
              content.profit_user_mobile
            }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{
              $moment(content.create_time).format("YYYY-MM-DD HH:mm")
            }}</el-descriptions-item>
          </el-descriptions>
          <div class="info">
            <div class="item">
              <div class="label">状态</div>
              <div class="value">
                <div class="status"
                     style="color: #e6a23c"
                     v-if="content.status == 0">
                  待结算
                </div>
                <div class="status"
                     style="color: #67c23a"
                     v-if="content.status == 1">
                  已入账
                </div>

                <!-- <div class="status"
                     style="color: #67c23a"
                     v-if="content.status == 3">
                  已提现
                </div> -->
              </div>
            </div>
            <div class="item">
              <div class="label">*本次账单结课奖励</div>
              <div class="value">
                <div class="count">￥{{ content.ref_senior_order_bonus_amount }}</div>
              </div>
            </div>
            <!-- <div class="item">
              <div class="label">*本次账单结课奖励</div>
              <div class="value">
                <div class="count">￥{{ content.bonus_amount }}</div>
              </div>
            </div> -->
          </div>
          <div class="info"
               style="margin-left: 80px">
            <el-button @click="handle('1')"
                       type="primary">审核通过</el-button>
            <el-button @click="handle('2')"
                       type="danger">审核拒绝</el-button>
          </div>
        </div>
      </div>
      <div class="wrap"></div>
      <div class="table"
           style="padding: 16px">
        <TableTitle :title="'结课奖励课时核对'" />
        <el-table v-if="!type"
                  :data="tableData"
                  :header-cell-style="{
            textAlign: 'center',
            color: '#909399',
            fontFamily: 'PingFang SC',
            fontSize: '14px',
            fontWeight: '400',
            backgroundColor: '#F2F6FC',
          }"
                  :cell-style="{ fontSize: '13px' }"
                  border
                  height="300px">
          <el-table-column prop="id"
                           label="ID"
                           align="center"
                           width="80"
                           show-overflow-tooltip>
          </el-table-column>
          <!--  <el-table-column
            label="上课时间"
            width="150"
            align="center"
          >
            <template slot-scope="scope">
              {{scope.row.plan_start_time?$moment(scope.row.plan_start_time).format("YYYY-MM-DD HH:mm"):""}}
            </template>
          </el-table-column> -->
          <el-table-column width="160"
                           align="center"
                           show-overflow-tooltip>
            <template slot="header">
              <el-popover ref="popover">
                <span class="el-dropdown-link"
                      slot="reference">
                  {{ unitConversion == 1 ? "计划上课时间" : "实际上课时间"
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <div class="popover-item"
                     @click="handleCommand(1)">
                  计划上课时间
                </div>
                <div class="popover-item"
                     @click="handleCommand(2)">
                  实际上课时间
                </div>
              </el-popover>
            </template>
            <template slot-scope="scope">
              <div v-if="unitConversion == 1">
                {{
                  scope.row.plan_start_time
                    ? $moment(scope.row.plan_start_time).format(
                        "YYYY-MM-DD HH:mm"
                      )
                    : ""
                }}
              </div>
              <div v-if="unitConversion == 2">
                <span v-if="scope.row.final_real_lesson_start_time">{{
                  $moment(scope.row.final_real_lesson_start_time).format(
                    "YYYY-MM-DD HH:mm"
                  )
                }}</span>
                <el-tag type="info"
                        size="mini"
                        v-else>暂未上课</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="plan_course_theme"
                           label="课时主题"
                           width="200"
                           align="left"
                           show-overflow-tooltip>
          </el-table-column>
          <!-- <el-table-column prop="plan_course_content"
                           label="课程内容"
                           width="300"
                           align="left"
                           show-overflow-tooltip>
          </el-table-column> -->
          <el-table-column width="120"
                           show-overflow-tooltip
                           align="center"
                           label="课时时长">
            <template slot-scope="scope">
              <div>{{ scope.row.final_real_lesson_hour }}小时</div>
            </template>
          </el-table-column>
          <el-table-column label="基础课时费"
                           width="150"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.final_real_salary_split_type==2">{{ scope.row.final_real_salary_profit_fix_amount || 0 }}元/小时</span>
              <span v-else>{{ scope.row.final_real_lesson_hour_amount || 0 }}元/小时</span>
            </template>
            <!-- <template slot-scope="scope"> -->
            <!-- {{ scope.row.final_real_salary_profit_fix_amount || 0 }}元/小时 -->

            <!-- </template> -->
          </el-table-column>
          <el-table-column label="结课奖励"
                           width="150"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.final_real_bonus_profit_fix_amount ?scope.row.final_real_bonus_profit_fix_amount:0}}元/小时</span>
              <!-- <span v-if="scope.row.final_real_salary_split_type==2">{{ scope.row.final_real_salary_profit_fix_amount || 0 }}元/小时</span>
              <span v-else>{{ scope.row.final_real_lesson_hour_amount || 0 }}元/小时</span> -->
            </template>
            <!-- <template slot-scope="scope"> -->
            <!-- {{ scope.row.final_real_salary_profit_fix_amount || 0 }}元/小时 -->
            <!-- </template> -->
          </el-table-column>
          <el-table-column label="课时状态"
                           width="80"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.lesson_status == 0"
                      size="mini"
                      type="warning">待确认</el-tag>
              <el-tag v-if="scope.row.lesson_status == 1"
                      size="mini">待回访</el-tag>
              <el-tag v-if="scope.row.lesson_status == 2"
                      size="mini"
                      type="danger">已拒绝</el-tag>
              <el-tag v-if="scope.row.lesson_status == 3"
                      size="mini"
                      type="success">已完成</el-tag>
              <el-tag v-if="scope.row.lesson_status == 5"
                      size="mini"
                      type="success">已完结</el-tag>
              <el-tag v-if="scope.row.lesson_status == 6"
                      size="mini">已暂停</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="学员评分"
                           width="100"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{
                scope.row.student_apprise_teacher_average_score
                  ? `${scope.row.student_apprise_teacher_average_score}分`
                  : "暂无"
              }}
            </template>
          </el-table-column>
          <el-table-column label="相关学员"
                           width="220"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{ formateMember(scope.row.student_realnames) }}
            </template>
          </el-table-column>
          <el-table-column prop="grade_name"
                           label="所在班级"
                           align="center"
                           width="260"
                           show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="课程阶段"
                           width="100"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{ formateCourse_stage(scope.row.plan_course_stage) }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="plan_course_target"
                           label="课程目标"
                           width="200"
                           align="center"
                           show-overflow-tooltip>
          </el-table-column> -->
          <el-table-column label="课酬状态"
                           width="80"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.senior_course_order_status">
                <el-tag size="small"
                        v-if="scope.row.senior_course_order_status == 0"
                        type="warning">待审核</el-tag>
                <el-tag size="small"
                        v-else-if="scope.row.senior_course_order_status == 1"
                        type="success">已通过</el-tag>
                <el-tag size="small"
                        v-else-if="scope.row.senior_course_order_status == 2"
                        type="danger">已拒绝</el-tag>
                <el-tag size="small"
                        v-else-if="scope.row.senior_course_order_status == 3"
                        type="success">已提现</el-tag>
              </div>
              <div v-else>暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="授课老师"
                           width="120"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.teacher_realname }}
            </template>
          </el-table-column>
          <el-table-column label="学管师"
                           align="center"
                           width="130">
            <template slot-scope="scope">
              {{
                scope.row.learn_manager ? scope.row.learn_manager.nickname : ""
              }}
            </template>
          </el-table-column>
          <el-table-column label="创建日期"
                           align="center"
                           width="150">
            <template slot-scope="scope">
              {{
                scope.row.create_time
                  ? $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm")
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column align="center"
                           label="修改日期"
                           width="150">
            <template slot-scope="scope">
              {{
                scope.row.update_time
                  ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                  : ""
              }}
            </template>
          </el-table-column>
        </el-table>

        <el-table v-else
                  :data="tableData"
                  :header-cell-style="{
            textAlign: 'center',
            color: '#909399',
            fontFamily: 'PingFang SC',
            fontSize: '14px',
            fontWeight: '400',
            backgroundColor: '#F2F6FC',
          }"
                  :cell-style="{ fontSize: '13px' }"
                  border
                  height="300px">
          <el-table-column type="selection"
                           width="50"
                           fixed="left"
                           align="center">
          </el-table-column>
          <el-table-column prop="id"
                           label="ID"
                           align="center"
                           width="80"
                           show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="dispatch_log_manage_creator.nickname"
                           label="登记人"
                           align="center"
                           width="80"
                           show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="create_time"
                           label="登记日期"
                           width="120">
            <template slot-scope="scope">
              {{
                scope.row.create_time
                  ? $moment(scope.row.create_time).format("YYYY-MM-DD")
                  : "-"
              }}
            </template>
          </el-table-column>
          <el-table-column prop="dispatch_log_course_theme"
                           label="课时主题"
                           width="200"
                           align="left"
                           show-overflow-tooltip>
          </el-table-column>
          <el-table-column width="120"
                           show-overflow-tooltip
                           align="center"
                           label="课时时长">
            <template slot-scope="scope">
              <div>{{ scope.row.current_lesson_hour }}小时</div>
            </template>
          </el-table-column>
          <el-table-column label="课时状态"
                           width="120"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.lesson_status == 0"
                      size="mini"
                      type="warning">待确认</el-tag>
              <el-tag v-if="scope.row.lesson_status == 1"
                      size="mini">待回访</el-tag>
              <el-tag v-if="scope.row.lesson_status == 2"
                      size="mini"
                      type="danger">已拒绝</el-tag>
              <el-tag v-if="scope.row.lesson_status == 3"
                      size="mini"
                      type="success">已完成</el-tag>
              <el-tag v-if="scope.row.lesson_status == 5"
                      size="mini"
                      type="success">已完结</el-tag>
              <el-tag v-if="scope.row.lesson_status == 6"
                      size="mini">已暂停</el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="company_name"
                           label="机构简称"
                           align="center"
                           width="120"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.company_name || "-" }}
            </template>
          </el-table-column>
          <el-table-column label="学生姓名"
                           width="220"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{scope.row.dispatch_log_student_name}}
              <!-- {{ formateMember(scope.row.student_realnames) }} -->
            </template>
          </el-table-column>
          <el-table-column label="授课老师"
                           width="130"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.teacher_realname }}
            </template>
          </el-table-column>

          <!-- <el-table-column label="课时费"
                           width="150"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.final_real_lesson_hour_amount || 0 }}元/小时
            </template>
          </el-table-column> -->
          <el-table-column label="基础课时费"
                           width="150"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.final_real_salary_split_type==2">{{ scope.row.final_real_salary_profit_fix_amount || 0 }}元/小时</span>
              <span v-else>{{ scope.row.final_real_lesson_hour_amount || 0 }}元/小时</span>
            </template>
          </el-table-column>
          <el-table-column label="结课奖励"
                           width="150"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{scope.row.final_real_bonus_profit_fix_amount ?scope.row.final_real_bonus_profit_fix_amount:0}}元/小时</span>
              <!-- <span v-if="scope.row.final_real_salary_split_type==2">{{ scope.row.final_real_salary_profit_fix_amount || 0 }}元/小时</span>
              <span v-else>{{ scope.row.final_real_lesson_hour_amount || 0 }}元/小时</span> -->
            </template>
            <!-- <template slot-scope="scope"> -->
            <!-- {{ scope.row.final_real_salary_profit_fix_amount || 0 }}元/小时 -->

            <!-- </template> -->
          </el-table-column>

          <!-- <el-table-column
            label="附加费"
            width="150"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.final_real_company_service_amount || 0 }}元
            </template>
          </el-table-column> -->
          <el-table-column label="课时备注"
                           width="150"
                           align="center"
                           prop="remark"
                           show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="课酬状态"
                           width="80"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.senior_course_order_status">
                <el-tag size="small"
                        v-if="scope.row.senior_course_order_status == 0"
                        type="warning">待审核</el-tag>
                <el-tag size="small"
                        v-else-if="scope.row.senior_course_order_status == 1"
                        type="success">已通过</el-tag>
                <el-tag size="small"
                        v-else-if="scope.row.senior_course_order_status == 2"
                        type="danger">已拒绝</el-tag>
                <el-tag size="small"
                        v-else-if="scope.row.senior_course_order_status == 3"
                        type="success">已提现</el-tag>
              </div>
              <div v-else>暂无</div>
            </template>
          </el-table-column>
          <el-table-column prop="senior_course_order_name"
                           label="课酬单名称"
                           align="center"
                           width="200"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{
                scope.row.senior_course_order_name
                  ? scope.row.senior_course_order_name
                  : "暂无"
              }}
            </template>
          </el-table-column>

          <el-table-column label="账单状态"
                           width="120"
                           align="center"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag size="small"
                      v-if="scope.row.company_service_order_status == 0"
                      type="warning">待确定</el-tag>
              <el-tag size="small"
                      v-else-if="scope.row.company_service_order_status == 1">待支付</el-tag>
              <el-tag size="small"
                      v-else-if="scope.row.company_service_order_status == 2"
                      type="success">已支付</el-tag>
              <el-tag size="small"
                      v-else-if="scope.row.company_service_order_status == 3"
                      type="success">已完成</el-tag>
              <el-tag size="small"
                      v-else-if="scope.row.company_service_order_status == 4"
                      type="info">已取消</el-tag>
              <div v-else>暂无</div>
            </template>
          </el-table-column>
          <el-table-column prop="senior_course_order_name"
                           label="机构账单名称"
                           align="center"
                           width="200"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              {{
              scope.row.company_service_order_name
                ? scope.row.company_service_order_name
                : "暂无"
            }}
            </template>
          </el-table-column>

          <!--  -->
          <el-table-column label="匹配师"
                           align="center"
                           width="130">
            <template slot-scope="scope">
              {{
                scope.row.allocation_manage_nickname
                  ? scope.row.allocation_manage_nickname
                  : ""
              }}
            </template>
          </el-table-column>
          <el-table-column align="center"
                           label="修改日期"
                           width="150">
            <template slot-scope="scope">
              {{
                scope.row.update_time
                  ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                  : ""
              }}
            </template>
          </el-table-column>
        </el-table>
        <div class="block"
             style="margin-top: 16px">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
      <!--     <el-descriptions
        :column="1"
        :labelStyle="labelStyle"
      >
        <el-descriptions-item label="账单名称">{{content.order_name}}</el-descriptions-item>
        <el-descriptions-item label="金额">{{content.total_amount}}元</el-descriptions-item>
        <el-descriptions-item label="姓名">{{content.senior_realname}}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{content.senior_mobile}}</el-descriptions-item>
        <el-descriptions-item label="审核状态">
          <div class="button-wrap">
            <el-tag
              size="small"
              v-if="content.status==0"
              type="warning"
            >待审核</el-tag>
            <el-tag
              size="small"
              v-else-if="content.status==1"
              type="success"
            >已通过</el-tag>
            <el-tag
              size="small"
              v-else-if="content.status==2"
              type="danger"
            >已拒绝</el-tag>
            <el-tag
              size="small"
              v-else-if="content.status==3"
              type="success"
            >已提现</el-tag>
          </div>
        </el-descriptions-item>
      </el-descriptions> -->
      <div slot="footer"
           class="dialog-footer"></div>
    </el-dialog>
    <el-dialog top="5vh"
               title="请输入验证码"
               :visible.sync="dialogVisible"
               width="420px">
      <el-form :model="form"
               style="display: flex">
        <el-form-item :label="phone">
          <el-input v-model="form.password"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item style="align-self: flex-end; margin-left: 16px">
          <el-button type="primary"
                     size="small"
                     :disabled="codedisabled"
                     @click="sendsms">{{ yzcodetext }}</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="accept">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog top="5vh"
               title="拒绝理由"
               :visible="refuseVisible"
               width="500px"
               @close="refuseVisible = false">
      <el-form :model="refuseForm"
               ref="dialogForm">
        <el-form-item prop="remark">
          <el-input type="textarea"
                    v-model="refuseForm.refuse_reason"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="refuseVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="reject">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { lessonLoglist } from '../../../request/api'
import { adminUrl } from '@/utils/helper.js'
import TableTitle from '../../../components/TableTittle/TableTittle.vue'
export default {
  components: {
    TableTitle,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      form: {},
      refuseForm: {},
      dialogVisible: false, //验证码弹窗
      codedisabled: false,
      refuseVisible: false,
      yzcodetext: '获取验证码',
      codeTime: 0,
      phone: '',
      labelStyle: {
        width: '62px',
        textAlign: 'right',
        fontSize: '13px',
      },
      tableData: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      },
      pageTotal: 0,
      contentStyle: {
        color: '#606266',
      },
      unitConversion: 1,
    }
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        if (this.type) {
          this.query.force_delete_types = 2
        } else {
          if (this.query.force_delete_types) {
            delete this.query.force_delete_types
          }
        }
        this.fetchList()
      }
    },
  },
  mounted() {
    // 获取全部院校列表
  },

  methods: {
    open() {},
    fetchList() {
      const params = Object.assign({}, this.query, {
        senior_course_order_id: this.content.ref_id,
      })
      lessonLoglist(params).then((response) => {
        let res = response.data
        this.tableData = res.data.list || []
        this.pageTotal = res.data.count
      })
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    emitClose() {
      this.$emit('close')
    },
    submit(rules) {
      this.$refs[rules].validate((valid) => {
        if (valid) {
        } else {
          return false
        }
      })
    },
    handle(type) {
      let str = adminUrl()
      switch (type) {
        case '1':
          this.accept()

          break
        case '2':
          this.refuseVisible = true
          break
        default:
          break
      }
    },
    timer() {
      if (this.codeTime > 0) {
        this.codeTime--
        this.yzcodetext = this.codeTime + 's后重新获取'
        setTimeout(this.timer, 1000)
      } else {
        this.codeTime = 0
        this.yzcodetext = '获取验证码'
        this.codedisabled = false
      }
    },
    sendsms() {
      this.codeTime = 60
      this.codedisabled = true
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/member/withdraw/send/smscode`,
        method: 'post', //可以省略 不写默认是get
      }).then((res) => {
        if (res.data.code == 0) {
          this.timer()
        }
      })
    },
    accept() {
      let str = adminUrl()
      this.$axios({
        url: `${str}/api/v1/back/member/profit/transferAccountByHandle`,
        method: 'post', //可以省略 不写默认是get
        data: {
          user_profit_log_id: this.content.id,
          password: this.form.password,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: 'success',
            message: '操作成功',
          })
          this.$emit('submit')
          this.emitClose()
          this.dialogVisible = false
        }
      })
    },
    reject() {
      if (!this.refuseForm.refuse_reason) {
        this.$message.error('请填写拒绝理由')
        return
      }
      let str = adminUrl()

      this.$axios({
        url: `${str}/api/v1/back/member/profit/finishCourseBonusCheckRefuse`,
        method: 'post', //可以省略 不写默认是get
        data: {
          user_profit_log_id: this.content.id,
          finish_course_bonus_refuse_reason: this.refuseForm.refuse_reason,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message({
            type: 'success',
            message: '操作成功',
          })
          this.$emit('submit')
          this.emitClose()
          this.refuseVisible = false
        }
      })
      // this.$axios({
      //   url: `${str}/api/v1/back/senior/course/order/checkRefuse`,
      //   method: 'post', //可以省略 不写默认是get
      //   data: {
      //     order_id: this.content.id,
      //     refuse_reason: this.refuseForm.refuse_reason,
      //   },
      // }).then((res) => {
      //   if (res.data.code == 0) {
      //     this.$message.success('操作成功')
      //     this.$emit('submit')
      //     this.emitClose()
      //     this.refuseVisible = false
      //   }
      // })
    },
    formateMember(val) {
      if (val) {
        let val_arr = val.split(',')
        if (val_arr.length > 3) {
          return val.split(',').slice(0, 3).join('，') + '等'
        } else {
          return val.split(',').slice(0, 3).join('，')
        }
      } else {
        return '无'
      }
    },
    formateCourse_stage(val) {
      let stages = {
        0: '基础',
        1: '强化',
        2: '冲刺',
      }
      return stages[val]
    },
    handleCommand(e) {
      this.unitConversion = e
      this.$refs['popover'].doClose()
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .fixed-width-descriptions .el-descriptions__body {
  width: 520px; /* 你想要设置的固定宽度 */
}
.input-with-select {
  margin-bottom: 16px;
}
.button-wrap {
  display: flex;
}
.header {
  padding: 16px;
  .info-wrap {
    display: flex;
    padding-left: 6px;
    padding-right: 16px;

    .info {
      display: flex;
      align-items: flex-start;
      .item {
        min-width: 130px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .label {
          font-size: 13px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #606266;
          margin-bottom: 4px;
        }
        .value {
          .status {
            font-size: 20px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #e6a543;
          }
          .count {
            font-size: 22px;
            font-family: DIN Condensed-Bold, DIN Condensed;
            font-weight: bold;
            color: #303133;
          }
        }
      }
    }
  }
}
.wrap {
  height: 16px;
  background: #f2f6fc;
  opacity: 1;
}
// /deep/.el-dialog__body {
//   padding: 0px;
//   padding-bottom: 16px;
// }
/deep/.el-dialog__header {
  background-color: #ebeef5;
}
/deep/.el-dialog__title {
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #303133;
}
</style>