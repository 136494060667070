<template>
  <div class="content">
    <el-dialog top="5vh"
               title="学员信息"
               :visible.sync="dialogFormVisible"
               @close="emitClose"
               width="570px">
      <el-form :model="dataForm"
               label-position="right"
               ref="formRules"
               :rules="rules"
               label-width="110px"
               size="small">
        <el-form-item label="姓名/昵称："
                      prop="realname">
          <el-input v-model="dataForm.realname"
                    autocomplete="off"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="联系方式：">
          <el-input placeholder=""
                    v-model.trim="dataForm.phone"
                    class="input-with-select">
            <template slot="prepend">手机号</template>
          </el-input>
          <el-input placeholder=""
                    v-model="dataForm.wechat"
                    class="input-with-select">
            <template slot="prepend">微信号</template>
          </el-input>
          <el-input placeholder=""
                    v-model="dataForm.qq"
                    class="input-with-select">
            <template slot="prepend">&nbsp;QQ号</template>
          </el-input>
        </el-form-item>
      </el-form>

      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   class="type"
                   :data-clipboard-text="clipboardText"
                   @click="copy">复制学员信息</el-button>
        <el-button type="primary"
                   @click="handleToChangeStatus(dataForm)"
                   v-if="dataForm.docking_status == 0">已对接</el-button>
        <el-button @click="emitClose">取 消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { editstudent } from '@/request/api'
import Clipboard from 'clipboard'
export default {
  components: {},
  props: {
    content: {
      type: Object,
      default: () => {
        return null
      },
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      clipboardText: '',
      dataForm: {},
      rules: {
        realname: [
          { required: true, message: '请输入学员姓名', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        this.dataForm = this.content
        console.log(this.dataForm)
      }
    },
  },
  created() {},
  computed: {},
  methods: {
    copy() {
      let subject = []
      this.dataForm.subjects.map((item) => {
        subject.push('（' + item.subject_code + '）' + item.subject_name)
      })
      let hour = (this.dataForm.service_hours / 0.75).toFixed(2)
      this.clipboardText = `先跟你核对一下信息哟~
我们会根据你核对的信息安排后续课程！
---
姓名：${this.dataForm.realname}
手机号：${this.dataForm.phone}
考研年份：${this.dataForm.apply_year}
报考学校：${this.dataForm.apply_college_name}
报考学院：${this.dataForm.apply_academy_name}
报考专业:（${this.dataForm.apply_major_code}）${
        this.dataForm.apply_major_name
      }专业
报考科目:${subject.join('+')}
---
总课时：${hour}课时，1课时45分钟，一共累计上满 ${
        this.dataForm.service_hours
      }小时 
授课形式：网授课 `
      var clipboard = new Clipboard('.type')
      clipboard.on('success', (e) => {
        this.$message({
          message: '复制成功！',
          type: 'success',
        })
        this.handleToClose()
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message({
          message: '该浏览器不支持自动复制',
          type: 'error',
        })
        // 释放内存
        clipboard.destroy()
      })
    },
    handleToChangeStatus(row) {
      this.$confirm('确定已对接该学员吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let query = {
          id: row.id,
          docking_status: 1,
        }
        editstudent(query).then((res) => {
          this.emitClose()
          this.$emit('submit')
        })
      })
    },
    emitClose() {
      this.$emit('close')
    },
    submit(rules) {
      if (!/^1[123456789]\d{9}$/.test(this.dataForm.phone)) {
        this.$message({
          message: '请填写正确的手机号',
          type: 'error',
          duration: 1000,
        })
        return false
      }
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let query = {
            id: this.dataForm.id,
            realname: this.dataForm.realname,
            phone: this.dataForm.phone,
            qq: this.dataForm.qq,
            wechat: this.dataForm.wechat,
          }
          editstudent(query).then((res) => {
            if (res.data.code == 0) {
              this.emitClose()
              this.$emit('submit')
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  .input-with-select {
    margin-bottom: 16px;
  }
}
</style>
