<template>
  <div>
    <el-dialog append-to-body
               top="5vh"
               title="修改课时费"
               :visible.sync="dialogFormVisible"
               @close="emitClose"
               :close-on-click-modal="false"
               width="700px">
      <el-form :model="dataForm"
               label-position="right"
               ref="formRules"
               :rules="rules"
               label-width="100px"
               size="small">
        <el-form-item label="结算周期："
                      prop="settle_cycle_type">
          <el-radio-group v-model="dataForm.settle_cycle_type">
            <el-radio :label="2">周结</el-radio>
            <el-radio :label="1">月结</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="当前课时费：">
          <span class="item-value">
            {{now_amount}}元/小时
          </span>
        </el-form-item>
        <el-form-item label="课时费："
                      prop="lesson_hour_amount">
          <el-input placeholder="请输入课时费"
                    v-model="dataForm.lesson_hour_amount"
                    class="input-with-select"
                    oninput="value=value.replace(/[^0-9.]/g,'')">
            <span slot="append">元/小时</span>
          </el-input>
          <div class="gray">
            其中包含：
            <div style="display:flex;justify-content: space-between;width:80%">
              <div style="position: relative;width:45%">
                <el-input v-model="dataForm.salary_profit_fix_amount"
                          style="marginRight:10px"
                          placeholder=""
                          oninput="value=value.replace(/[^0-9.]/g,'')"
                          @blur="basInput">
                  <template slot="prepend">基础课时费</template>
                </el-input>
                <span style=" position: absolute;top:1px;right:2px;fontSize:12px;">元/小时</span>
              </div>
              <div style="position: relative;width:45%">
                <el-input v-model="dataForm.bonus_profit_fix_amount"
                          style="marginRight:10px"
                          placeholder=""
                          @blur="signInput"
                          oninput="value=value.replace(/[^0-9.]/g,'')">
                  <template slot="prepend">结课奖励</template>
                </el-input>
                <span style=" position: absolute;top:1px;right:2px;fontSize:12px;">元/小时</span>
              </div>
            </div>

          </div>

        </el-form-item>
      </el-form>
      <div class="footer">
        <el-button @click="emitClose()">取消</el-button>
        <el-button type="primary"
                   @click="submit('formRules')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  teacherEdit,
  teacherOne,
  commonHandleLogCreate,
  gradeOne,
} from '@/request/api'
export default {
  components: {},
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: () => {
        return null
      },
    },
    gradTd: {
      type: Number,
      default: () => {
        return null
      },
    },
    class_id: {
      default: () => {
        return null
      },
    },
  },
  data() {
    return {
      dialogFormVisible: this.isVisible,
      dataForm: { lesson_hour_amount: '' },
      rules: {
        lesson_hour_amount: [
          { required: true, message: '请输入课时费', trigger: 'blur' },
          {
            pattern: /^(\d+|\d+\.\d{1,2})$/,
            message: '输入最多两位小数的数字',
          },
        ],
      },
      now_amount: 0,
    }
  },
  mounted() {},
  watch: {
    isVisible(val) {
      this.dialogFormVisible = val
      if (val) {
        this.fetchDetail()
      }
    },
  },
  methods: {
    basInput(e) {
      console.log('e', this.dataForm.salary_profit_fix_amount)
      this.dataForm.bonus_profit_fix_amount =
        Math.round(
          (this.dataForm.lesson_hour_amount * 1 -
            this.dataForm.salary_profit_fix_amount * 1) *
            100
        ) / 100
    },
    signInput(e) {
      this.dataForm.salary_profit_fix_amount =
        Math.round(
          (this.dataForm.lesson_hour_amount * 1 -
            this.dataForm.bonus_profit_fix_amount * 1) *
            100
        ) / 100
    },
    emitClose() {
      this.dataForm.lesson_hour_amount = null
      this.$emit('close')
    },
    fetchDetail() {
      gradeOne({ id: this.gradTd }).then((response) => {
        console.log('response', response)

        response.data.data.teachers.map((item) => {
          if (item.id == this.id) {
            this.dataForm = item
          }
        })
        this.dataForm.salary_profit_fix_amount =
          this.dataForm.teacher_salary_profit_fix_amount
        this.dataForm.bonus_profit_fix_amount =
          this.dataForm.teacher_bonus_profit_fix_amount
        this.dataForm.settle_cycle_type =
          this.dataForm.teacher_settle_cycle_type
        this.now_amount = this.dataForm.teacher_lesson_hour_amount
        // this.dataForm.lesson_hour_amount =
        //   this.dataForm.teacher_lesson_hour_amount
        this.$set(
          this.dataForm,
          'lesson_hour_amount',
          this.dataForm.teacher_lesson_hour_amount
        )
      })
      setTimeout(() => {
        teacherOne({ id: this.id }).then((response) => {
          // let res = response.data
          // this.dataForm = res.data
          // console.log('this.dataForm', this.dataForm)
          if (response.data.data.grades) {
            this.$set(this.dataForm, 'grades', response.data.data.grades)
            // this.now_amount =
            //   res.data.grades.find((v) => {
            //     return v.id == this.class_id
            //   }).teacher_lesson_hour_amount || 0
          }
        })
      }, 100)
    },
    submit(rules) {
      console.log(' this.dataForm.grades', this.dataForm.grades)
      console.log('this.class_id', this.class_id)
      this.$refs[rules].validate((valid) => {
        if (valid) {
          let arr = []
          // arr = this.dataForm.grades.filter((v) => {
          //   return v.id == this.class_id
          // })
          // console.log('arr333', arr)
          arr.push({
            id: this.class_id,
            teacher_lesson_hour_amount: this.dataForm.lesson_hour_amount,
            teacher_grade_status: this.dataForm.grades.find((t) => {
              return t.id == this.class_id
            }).teacher_grade_status,
          })
          console.log('arr', arr)

          let query = {
            id: this.dataForm.id,
            arr_grade: arr.map((v) => {
              return {
                grade_id: v.id,
                teacher_lesson_hour_amount: v.teacher_lesson_hour_amount,
                teacher_grade_status: v.teacher_grade_status,
                teacher_settle_cycle_type: this.dataForm.settle_cycle_type,
                teacher_salary_split_type: 2,
                teacher_salary_profit_fix_amount:
                  this.dataForm.salary_profit_fix_amount,
                teacher_bonus_profit_fix_amount:
                  this.dataForm.bonus_profit_fix_amount,
              }
            }),
          }
          teacherEdit(query).then((response) => {
            let res = response.data
            if (res.code == 0) {
              this.createLog()
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    createLog() {
      if (this.now_amount == this.dataForm.lesson_hour_amount) {
        this.$emit('submit')
        this.emitClose()
        return
      }
      let query = {
        ref_id: this.class_id,
        handle_type: 0,
        handle_child_type: 6,
        handle_content: `将${this.dataForm.realname}老师的课时费由“${this.now_amount}元/小时”变更为“${this.dataForm.lesson_hour_amount}元/小时”`,
      }
      commonHandleLogCreate(query).then((res) => {
        if (res.data.code == 0) {
          this.$emit('submit')
          this.emitClose()
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
.gray {
  padding-left: 10px;
  padding-top: 5px;
  margin-top: 5px;
  height: 100px;
  background: #f7f9fc;
}
</style>