<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>师资</el-breadcrumb-item>
        <el-breadcrumb-item>课时记录</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <el-button type="primary" size="mini" @click="addClass"
        >新建课时</el-button
      > -->
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="学生信息">
          <el-input v-model.trim="query.student_keyword"
                    placeholder="姓名/院校/学院/专业/备注"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="老师信息">
          <el-input v-model.trim="query.teacher_keyword"
                    placeholder="姓名/手机号"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="机构简称"
                      prop="cid">
          <el-select v-model="query.company_ids"
                     placeholder="请选择"
                     filterable
                     @change="fetchList(true)"
                     clearable>
            <el-option v-for="item in $store.state.CompanyList"
                       :key="item.id"
                       :label="item.name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="课时主题">
          <el-input v-model.trim="query.plan_course_theme"
                    placeholder="请输入计划课程主题"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="课时状态">
          <el-select v-model="lesson_statuses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable>
            <el-option v-for="(item, index) in filters.lesson_status"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="课酬状态">
          <el-select v-model="query.senior_course_order_statuses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="(item, index) in filters.senior_course_order_status"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="机构课酬状态">
          <el-select v-model="query.company_lesson_statuses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="(item, index) in filters.companyLessonStatus"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="机构账单状态">
          <el-select v-model="query.company_service_order_statuses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="(item, index) in filters.serviceList"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="登记时间">
          <el-date-picker class="datetime"
                          v-model="plan_start_time"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="fetchList(true)"
                          clearable
                          :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="匹配师："
                      prop="allocation_manage_ids">
          <el-select v-model="query.allocation_manage_ids"
                     placeholder="请选择匹配师"
                     clearable
                     style="width: 100%"
                     multiple
                     filterable
                     @change="fetchList(true)">
            <el-option v-for="(item, index) in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 5 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="index"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form label-width="70px"
                 size="small"
                 style="margin-bottom:0px;display:flex;"
                 label-position="right">
          <el-form-item label-width="32px">
            <el-radio-group v-model="lesson"
                            @change="fetchList(true)">
              <el-radio-button label="">全部</el-radio-button>
              <el-radio-button :label="1">待结算（上周）</el-radio-button>
            </el-radio-group>
          </el-form-item>

        </el-form>
      </div>
      <el-table :data="tableData"
                :header-cell-style="{
          textAlign: 'center',
          color: '#909399',
          fontFamily: 'PingFang SC',
          fontSize: '14px',
          fontWeight: '400',
          backgroundColor: '#F2F6FC',
        }"
                :cell-style="{ fontSize: '13px' }"
                border
                @selection-change="handleSelectionChangeTab0">
        <el-table-column type="selection"
                         width="50"
                         fixed="left"
                         align="center">
        </el-table-column>
        <el-table-column prop="id"
                         label="ID"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="dispatch_log_manage_creator.nickname"
                         label="登记人"
                         align="center"
                         width="80"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="create_time"
                         label="登记日期"
                         width="120">
          <template slot-scope="scope">
            {{
              scope.row.create_time
                ? $moment(scope.row.create_time).format("YYYY-MM-DD")
                : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="dispatch_log_course_theme"
                         label="课时主题"
                         width="200"
                         align="left"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column width="120"
                         show-overflow-tooltip
                         align="center"
                         label="课时时长">
          <template slot-scope="scope">
            <div>{{ scope.row.current_lesson_hour }}小时</div>
          </template>
        </el-table-column>
        <el-table-column label="课时状态"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.lesson_status == 0"
                    size="mini"
                    type="warning">待确认</el-tag>
            <el-tag v-if="scope.row.lesson_status == 1"
                    size="mini">待回访</el-tag>
            <el-tag v-if="scope.row.lesson_status == 2"
                    size="mini"
                    type="danger">已拒绝</el-tag>
            <el-tag v-if="scope.row.lesson_status == 3"
                    size="mini"
                    type="success">已完成</el-tag>
            <el-tag v-if="scope.row.lesson_status == 5"
                    size="mini"
                    type="success">已完结</el-tag>
            <el-tag v-if="scope.row.lesson_status == 6"
                    size="mini">已暂停</el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="company_name"
                         label="机构简称"
                         align="center"
                         width="120"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.company_name || "-" }}
          </template>
        </el-table-column>
        <el-table-column label="学生姓名"
                         width="220"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.dispatch_log_student_name}}
            <!-- @click="handleToStudentList(scope.row)" -->
            <!-- {{ formateMember(scope.row.student_realnames) }} -->
          </template>
        </el-table-column>
        <el-table-column label="授课老师"
                         width="130"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-link type="primary"
                     :underline="false"
                     @click="handleToTeacherInfo(scope.row)">
              {{ scope.row.teacher_realname }}
            </el-link>
          </template>
        </el-table-column>
        <!-- <el-table-column label="课时费"
                         width="150"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.final_real_lesson_hour_amount || 0 }}元/小时
          </template>
        </el-table-column> -->
        <el-table-column label="基础课时费"
                         width="150"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.final_real_salary_profit_fix_amount !=null"> {{ scope.row.final_real_salary_profit_fix_amount || 0 }}元/小时</span>
            <span v-else> {{ scope.row.final_real_lesson_hour_amount || 0 }}元/小时</span>

          </template>
        </el-table-column>
        <el-table-column label="结课奖励"
                         width="150"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.final_real_bonus_profit_fix_amount || 0 }}元/小时</span>

          </template>
        </el-table-column>

        <el-table-column label="课时备注"
                         width="150"
                         align="center"
                         prop="remark"
                         show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="课酬状态"
                         width="80"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.senior_course_order_status">
              <el-tag size="small"
                      v-if="scope.row.senior_course_order_status == 0"
                      type="warning">待审核</el-tag>
              <el-tag size="small"
                      v-else-if="scope.row.senior_course_order_status == 1"
                      type="success">已通过</el-tag>
              <el-tag size="small"
                      v-else-if="scope.row.senior_course_order_status == 2"
                      type="danger">已拒绝</el-tag>
              <el-tag size="small"
                      v-else-if="scope.row.senior_course_order_status == 3"
                      type="success">已提现</el-tag>
            </div>
            <div v-else>暂无</div>
          </template>
        </el-table-column>
        <el-table-column prop="senior_course_order_name"
                         label="课酬单名称"
                         align="center"
                         width="200"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.senior_course_order_name
                ? scope.row.senior_course_order_name
                : "暂无"
            }}
          </template>
        </el-table-column>

        <el-table-column label="机构课酬状态"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag v-if="scope.row.company_lesson_status == 0"
                    size="mini"
                    type="warning">不可用</el-tag>
            <el-tag v-if="scope.row.company_lesson_status == 1"
                    size="mini">待生成</el-tag>
            <el-tag v-if="scope.row.company_lesson_status == 2"
                    size="mini"
                    type="success">已生成</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="机构账单状态"
                         width="120"
                         align="center"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag size="small"
                    v-if="scope.row.company_service_order_status == 0"
                    type="warning">待确定</el-tag>
            <el-tag size="small"
                    v-else-if="scope.row.company_service_order_status == 1">待支付</el-tag>
            <el-tag size="small"
                    v-else-if="scope.row.company_service_order_status == 2"
                    type="success">已支付</el-tag>
            <el-tag size="small"
                    v-else-if="scope.row.company_service_order_status == 3"
                    type="success">已完成</el-tag>
            <el-tag size="small"
                    v-else-if="scope.row.company_service_order_status == 4"
                    type="info">已取消</el-tag>
            <div v-else>暂无</div>
          </template>
        </el-table-column>
        <el-table-column prop="senior_course_order_name"
                         label="机构账单名称"
                         align="center"
                         width="200"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{
              scope.row.company_service_order_name
                ? scope.row.company_service_order_name
                : "暂无"
            }}
          </template>
        </el-table-column>
        <!--  -->
        <el-table-column label="匹配师"
                         align="center"
                         width="130">
          <template slot-scope="scope">
            {{
              scope.row.allocation_manage_nickname
                ? scope.row.allocation_manage_nickname
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column align="center"
                         label="修改日期"
                         width="150">
          <template slot-scope="scope">
            {{
              scope.row.update_time
                ? $moment(scope.row.update_time).format("YYYY-MM-DD HH:mm")
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="name"
                         label="操作"
                         width="120"
                         align="center"
                         fixed="right">
          <template slot-scope="scope">
            <div class="templateLink">
              <el-link type="primary"
                       :underline="false"
                       @click="handleEdit(scope.row)">编辑</el-link>
              <el-link type="danger"
                       :underline="false"
                       @click="handleDel(scope.row)">删除</el-link>
              <!-- <el-link
                type="primary"
                :underline="false"
                @click="handleToConfirm(scope.row)"
                >确认详情</el-link
              >
              <el-link
                :data-clipboard-text="clipboardText"
                type="primary"
                class="copy"
                :underline="false"
                @click="handleCopy(scope.row)"
                >复制链接</el-link
              > -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 16px">
        <el-button size="mini"
                   @click="handleToBill">生成课酬单</el-button>
        <el-button size="mini"
                   @click="handleToCompany">生成机构账单</el-button>
        <el-button size="mini"
                   @click="handleToPayStatus">更新机构课酬状态</el-button>
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <RecordStudentScore :isVisible="scoreDialog"
                        @close="scoreDialog = false"
                        @submit="fetchList()"
                        :id="recordId" />
    <RecordStudentList :isVisible="studentDialog"
                       @close="studentDialog = false"
                       @submit="fetchList()"
                       :id="recordId" />
    <RecordTeacherInfo :isVisible="infoDialog"
                       @close="infoDialog = false"
                       @submit="fetchList()"
                       :id="teacherId" />
    <AddSeniorBill :isVisible="createbillDialog"
                   @close="createbillDialog = false"
                   @submit="fetchList()"
                   :content="AddSeniorBillContent" />

    <AddSeniorCompany :isVisible="createCompanyDialog"
                      @close="createCompanyDialog = false"
                      @submit="fetchList()"
                      :content="AddSeniorCompany" />
    <!--     <InviteToConfirm
      :isVisible="InviteToConfirmDialog"
      @close="InviteToConfirmDialog=false"
      :id="confirmId"
    /> -->
    <NewClassHour :isVisible="newClassHourIsVisible"
                  @close="newClassHourIsVisible = false"
                  @submit="fetchList()">
    </NewClassHour>

    <Edit ref="edit"
          @submit="fetchList()" />

    <ClassPayStatus ref="classPayStatus"
                    @submit="fetchList()" />
  </div>
</template>

<script>
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import RecordStudentScore from '@/components/DialogComponents/ClassService/RecordStudentScore.vue'
import RecordStudentList from '@/components/DialogComponents/ClassService/RecordStudentList.vue'
import RecordTeacherInfo from '@/components/DialogComponents/ClassService/RecordTeacherInfo.vue'
import AddSeniorBill from '@/components/DialogComponents/Classregistration/AddSeniorBill.vue'
import AddSeniorCompany from '@/components/DialogComponents/Classregistration/AddSeniorCompany.vue'

import InviteToConfirm from '@/components/DialogComponents/ClassService/InviteToConfirm.vue'
import NewClassHour from '@/components/DialogComponents/ClassService/NewClassHour.vue'
import Edit from '@/components/DialogComponents/Classregistration/Edit.vue'

import Clipboard from 'clipboard'
import ClassPayStatus from '../../../components/DialogComponents/ClassPayStatus/ClassPayStatus.vue'
import {
  lessonLoglist,
  createuserfollow,
  deleteuserfollow,
  dispatchLessonLogDel,
} from '@/request/api'
export default {
  components: {
    TableTittle,
    RecordStudentScore,
    RecordStudentList,
    RecordTeacherInfo,
    AddSeniorBill,
    NewClassHour,
    AddSeniorCompany,
    Edit,
    ClassPayStatus,
  },
  data() {
    return {
      clipboardText: '',
      newClassHourIsVisible: false,
      TableName: '记录列表',
      tableData: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        force_delete_types: 2,
      },
      pageTotal: 0,
      chooseList: [],
      recordId: null,
      scoreDialog: false,
      studentDialog: false,
      teacherId: null,
      infoDialog: false,
      plan_start_time: '',
      filters: {
        lesson_status: [
          { label: '已完成', value: 3 },
          { label: '已完结', value: 5 },
        ],
        senior_course_order_status: [
          { label: '待审核', value: 0 },
          { label: '已通过', value: 1 },
          { label: '已拒绝', value: 2 },
          { label: '已提现', value: 3 },
        ],
        serviceList: [
          { label: '待确定', value: '0' },
          { label: '待支付', value: '1' },
          { label: '已支付', value: '2' },
          { label: '已完成', value: '3' },
          { label: '已取消', value: '4' },
        ],
        companyLessonStatus: [
          { label: '不可用', value: '0' },
          { label: '待生成', value: '1' },
          { label: '已生成', value: '2' },
        ],
      },
      createbillDialog: false,
      AddSeniorBillContent: [],
      unitConversion: 1,
      lesson_statuses: '',
      lesson: '',
      InviteToConfirmDialog: false,
      confirmId: null,
      lock: true,
      createCompanyDialog: false,
      AddSeniorCompany: [],
    }
  },
  created() {
    this.parseQuery()
    this.fetchList()
    this.$store.dispatch('getStaffOptions')
    this.$store.dispatch('getCompanyList', { data_range_tags: '3' })
  },
  watch: {
    // 时间数据监听
    plan_start_time(value) {
      if (value) {
        this.query.start_create_time = this.$moment(value[0]).valueOf()
        this.query.end_create_time = this.$moment(value[1]).valueOf()
      } else {
        this.query.start_create_time = ''
        this.query.end_create_time = ''
        this.fetchList(this.tableTab)
      }
    },
  },
  computed: {},
  methods: {
    handleEdit(row) {
      this.$refs.edit.open(row)
    },
    handleDel(row) {
      this.$confirm('是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          console.log('删除')
          dispatchLessonLogDel({
            id: row.id,
          }).then((res) => {
            if (res.data.code == 0) {
              this.fetchList(true)
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
      //
    },
    addClass() {
      this.newClassHourIsVisible = true
      // console.log('sdsd', this.newClassHourIsVisible)
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },
    async fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)

      /*     if (params.lesson_statuses) {
            params.lesson_statuses = params.lesson_statuses.join(',')
          } */
      if (params.senior_course_order_statuses) {
        params.senior_course_order_statuses =
          params.senior_course_order_statuses.join(',')
      }
      if (this.lesson_statuses == 1) {
        params.lesson_statuses = '0,1,6'
      } else if (this.lesson_statuses == 7) {
        params.lesson_statuses = '1'
      } else {
        params.lesson_statuses = this.lesson_statuses
      }

      if (
        params.company_service_order_statuses &&
        params.company_service_order_statuses.length > 0
      ) {
        params.company_service_order_statuses =
          params.company_service_order_statuses.join(',')
      }
      if (
        params.allocation_manage_ids &&
        params.allocation_manage_ids.length > 0
      ) {
        params.allocation_manage_ids = params.allocation_manage_ids.join(',')
      }
      params.is_statistic_lesson_company = 1
      params.lesson_statuses = this.lesson_statuses
      if (this.query.company_lesson_statuses instanceof Array) {
        params.company_lesson_statuses =
          this.query.company_lesson_statuses.join(',')
      } else {
        params.company_lesson_statuses = ''
      }
      if (this.lesson == 1) {
        params.start_final_real_lesson_start_time = 0
        params.end_final_real_lesson_start_time = Number(
          this.$moment()
            .subtract(1, 'weeks') // 回退到上周
            .startOf('week') // 获取上周的周日 00:00:00
            .endOf('day') // 设置为 23:59:59.999
            .valueOf()
        ) // 获取时间戳
        params.final_real_settle_cycle_types = 2
        params.lesson_statuses = 3
      }
      lessonLoglist(params).then((response) => {
        let res = response.data
        this.tableData = res.data.list || []
        this.pageTotal = res.data.count
      })
      this.syncQuery()
    },

    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
      if (this.query.lesson_statuses && this.query.lesson_statuses.length) {
        this.query.lesson_statuses = this.query.lesson_statuses
          .split(',')
          .map((v) => {
            return Number(v)
          })
      }
      if (
        this.query.senior_course_order_statuses &&
        this.query.senior_course_order_statuses.length
      ) {
        this.query.senior_course_order_statuses =
          this.query.senior_course_order_statuses.split(',').map((v) => {
            return Number(v)
          })
      }
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      if (params.lesson_statuses) {
        params.lesson_statuses = params.lesson_statuses
          .filter((v) => v)
          .join(',')
      }
      if (params.senior_course_order_statuses) {
        params.senior_course_order_statuses =
          params.senior_course_order_statuses.filter((v) => v).join(',')
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    formateMember(val) {
      if (val) {
        let val_arr = val.split(',')
        if (val_arr.length > 3) {
          return val.split(',').slice(0, 3).join('，') + '等'
        } else {
          return val.split(',').slice(0, 3).join('，')
        }
      } else {
        return '无'
      }
    },
    formateCourse_stage(val) {
      let stages = {
        0: '基础',
        1: '强化',
        2: '冲刺',
      }
      return stages[val]
    },
    handleToScoreDetail(row) {
      if (row.student_apprise_teacher_average_score) {
        this.recordId = row.id
        this.scoreDialog = true
      }
    },
    handleToStudentList(row) {
      this.recordId = row.id
      this.studentDialog = true
    },
    handleToTeacherInfo(row) {
      this.teacherId = row.teacher_id
      this.infoDialog = true
    },
    handleToConfirm(row) {
      window.open(`/classservice/record/confirm?lesson_log_id=${row.id}`)

      /*   this.$router.push(`/classservice/record/confirm?lesson_log_id=${row.id}`) */
    },
    handleSelectionChangeTab0(val) {
      this.chooseList = val
    },
    handleToBill() {
      if (!this.chooseList.length) {
        return this.$message.error('请选择数据')
      }
      let arr = this.chooseList.filter((v, i) => {
        return (
          v.teacher_id != this.chooseList[0].teacher_id ||
          v.grade_id != this.chooseList[0].grade_id
        )
      })
      if (arr.length) {
        this.$message.error('请选择同个老师并且同个班级')
      } else {
        this.createbillDialog = true
        this.AddSeniorBillContent = this.chooseList
      }
    },

    handleToPayStatus() {
      if (!this.chooseList.length) {
        return this.$message.error('请选择数据')
      }
      let ids = this.chooseList.map((item) => item.id)
      this.$refs.classPayStatus.open(ids)
    },
    // 选择机构
    handleToCompany() {
      if (!this.chooseList.length) {
        return this.$message.error('请选择数据')
      }
      let arr = this.chooseList.filter((v, i) => {
        return (
          v.company_id != this.chooseList[0].company_id ||
          v.company_lesson_status != 1
        )
      })

      if (arr.length) {
        this.$message.error('请选择同个机构，并且机构课酬状态为待生成')
      } else {
        this.createCompanyDialog = true
        this.AddSeniorCompany = this.chooseList
      }
    },
    handleCommand(e) {
      this.unitConversion = e
      this.$refs['popover'].doClose()
    },
    handleToInvite(row) {
      this.InviteToConfirmDialog = true
      this.confirmId = row.id
    },
    // 列表关注创建
    followCreate(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 7,
          follow_id: item.id,
        }
        createuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 500)
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 7,
          follow_id: item.id,
        }
        deleteuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 300)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  .left {
    text-align: left;
  }

  .star {
    width: 18px;
    height: 18px;
  }
}
</style>
