<template>
  <div class="content">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>课时</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/classservice/class' }">学员管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="button-wrap">
        <el-button icon="el-icon-download"
                   @click="handleExport"
                   size="small">导出</el-button>
      </div>
    </div>
    <el-card style="margin-top: 20px">
      <el-form class="search-wrap"
               ref="searchForm"
               :inline="true"
               :model="query"
               size="small"
               label-position="left">
        <el-form-item label="机构简称">
          <el-input v-model.trim="query.company_name"
                    placeholder="请输入机构简称"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="学员信息">
          <el-input v-model.trim="query.keyword"
                    placeholder="输入学员姓名或手机号"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>

        <el-form-item style="float: right">
          <el-radio-group v-model="query.archive"
                          @input="fetchList(true)">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="1">已归档</el-radio-button>
            <el-radio-button label="0">未归档</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <deviceLine style="margin-bottom: 16px"></deviceLine>
        <el-form-item label="班级名称">
          <el-input v-model.trim="query.grade_keyword"
                    placeholder="请输入班级关键字"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="考研年份">
          <el-select v-model="query.apply_year"
                     placeholder="请选择考研年份"
                     @change="fetchList(true)"
                     clearable>
            <el-option label="2023考研"
                       value="2023"></el-option>
            <el-option label="2024考研"
                       value="2024"></el-option>
            <el-option label="2025考研"
                       value="2025"></el-option>
            <el-option label="2026考研"
                       value="2026"></el-option>
            <el-option label="2027考研"
                       value="2027"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报考信息">
          <el-input v-model.trim="query.college_info_keyword"
                    placeholder="输入报考院校/学院/专业/方向"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="辅导科目">
          <el-input v-model.trim="query.subject_keyword"
                    placeholder="输入辅导科目"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>

        <!-- <el-form-item label="院校">
          <el-input v-model.trim="query.college_keyword" placeholder="请输入院校" @input="fetchList(true)"
            clearable></el-input>
        </el-form-item>
        <el-form-item label="专业">
          <el-input v-model.trim="query.major_keyword" placeholder="请输入专业" @input="fetchList(true)" clearable></el-input>
        </el-form-item> -->

        <!--       <el-form-item label="课程状态">
          <el-select
            v-model="query.course_statuses"
            placeholder="请选择"
            @change="fetchList(true)"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="(item,index) in filters.course_status"
              :key="index"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="课程状态">
          <el-select v-model="query.student_sync_statuses"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="(item, index) in filters.student_sync_statuses"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="报名时间">
          <el-date-picker class="datetime"
                          v-model="apply_time"
                          type="datetimerange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="fetchList(true)"
                          clearable
                          :default-time="['00:00:00', '23:59:59']">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="学管记录">
          <el-select v-model="query.last_manage"
                     placeholder="请选择订单状态"
                     @change="fetchList(true)"
                     clearable
                     collapse-tags>
            <el-option v-for="item in timestampList"
                       :key="item.id"
                       :label="item.label"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最近上课">
          <el-select v-model="query.last_lesson_time"
                     placeholder="请选择订单状态"
                     @change="fetchList(true)"
                     clearable
                     collapse-tags>
            <el-option v-for="item in timestampList"
                       :key="item.id"
                       :label="item.label"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="学管老师">
          <el-select v-model="query.learn_managers"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     multiple
                     collapse-tags>
            <el-option v-for="item in $store.state.staffOptions.filter((v) => {
                return (
                  v.depts.findIndex((t) => {
                    return t.id == 4 && v.status == 1;
                  }) != -1
                );
              })"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="对接老师">
          <el-select v-model="query.manage_watcher_id"
                     placeholder="请选择"
                     @change="fetchList(true)"
                     clearable
                     collapse-tags>
            <el-option v-for="item in $store.state.staffOptions"
                       :key="item.id"
                       :label="item.nickname"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="教务老师"
                      prop="watcher_id">
          <!--   -->
          <el-select @change="fetchList(true)"
                     v-model="query.watcher_id"
                     placeholder="请选择"
                     filterable
                     clearable>
            <el-option v-for="(item, index) in affairsTeacherList"
                       :key="index"
                       :label="item.alias"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="跟进标签"
                      prop="watcher_id">
          <el-select @change="fetchList(true)"
                     v-model="query.follow_up_tag_idses"
                     placeholder="请选择"
                     multiple
                     filterable
                     clearable>
            <el-option v-for="(item, index) in followUpTag"
                       :key="index"
                       :label="item.tag"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="跟进备注">
          <el-input v-model.trim="query.follow_up_remark_keyword"
                    placeholder="输入跟进备注"
                    @input="fetchList(true)"
                    clearable></el-input>
        </el-form-item>

        <!-- <el-form-item label="服务类别">
          <el-select v-model="query.record_time" placeholder="请选择服务时间" @change="fetchList(true)" clearable>
            <el-option v-for="(item, index) in timeArr" :key="index" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="query.service_record_type_id" placeholder="请选择服务类型" @change="fetchList(true)" clearable
            style="margin-left: 12px;" :disabled="!query.record_time">
            <el-option v-for="item in options" :key="item.id" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="query.service_record_frequecy" placeholder="请选择服务频次" @change="fetchList(true)"
            style="margin-left: 12px;" :disabled="!query.service_record_type_id" clearable>
            <el-option label="少于1次" :value="1">
            </el-option>
            <el-option label="少于3次" :value="3">
            </el-option>
            <el-option label="少于5次" :value="5">
            </el-option>
            <el-option label="少于7次" :value="7">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="最新记录">
          <el-input v-model.trim="query.recent_record_keyword" placeholder="请输入最新记录内容" @input="fetchList(true)"
            clearable></el-input>
        </el-form-item>
        <el-form-item label="学员标签">
          <el-select v-model="query.manage_tags_search" placeholder="请选择学员标签" @change="fetchList(true)" clearable>
            <el-option v-for="item in tagOptions" :key="item.id" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px"
             class="card">
      <!-- <div class="card-header">
        <TableTittle :title="TableName"></TableTittle>
        <el-form label-width="70px" size="small" style="margin-bottom:0px;display:flex;" label-position="right" inline>
          <el-form-item label="只看关注" label-width="120">
            <el-switch v-model="query.follow" :active-value="1" :inactive-value="0" @change="fetchList(true)">
            </el-switch>
          </el-form-item>
          <el-form-item label-width="32px">
            <el-radio-group v-model="query.docking_statuses" @change="fetchList(true)">
              <el-radio-button label="">全部</el-radio-button>
              <el-badge :value="uncheck" class="item" :hidden="uncheck == 0">
                <el-radio-button :label="1">待分配</el-radio-button>
              </el-badge>
              <el-badge :value="statusCount" class="item" :hidden="statusCount == 0">
                <el-radio-button :label="0">待联系</el-radio-button>
              </el-badge>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div> -->
      <div class="tabulation">
        <div class="radio-group">
          <el-radio-group v-model="filtrate"
                          @change="filtrateChange"
                          size="small">
            <el-radio-button label="1">全部</el-radio-button>
            <el-radio-button label="2">仅关注</el-radio-button>
            <el-badge :value="distributionNum"
                      :max="99"
                      :hidden="distributionNum == 0">
              <el-radio-button label="3">待联系</el-radio-button>
            </el-badge>
          </el-radio-group>
        </div>
        <el-tabs v-model="activeName"
                 @tab-click="handleClick">
          <el-tab-pane label="订单列表"
                       name="first">
            <el-table :data="tableData"
                      :header-cell-style="{
                textAlign: 'center',
                color: '#909399',
                fontFamily: 'PingFang SC',
                fontSize: '14px',
                fontWeight: '400',
                backgroundColor: '#F2F6FC',
              }"
                      :cell-style="{ fontSize: '13px' }"
                      @selection-change="handleSelectionChange"
                      border>
              <el-table-column type="selection"
                               width="50"
                               align="center">
              </el-table-column>
              <el-table-column prop="date"
                               label="关注"
                               width="50"
                               fixed="left"
                               align="center">
                <template slot-scope="scope">
                  <img alt=""
                       srcset=""
                       class="star"
                       src="@/assets/images/star-fill.png"
                       @click="followDelete(scope.row)"
                       v-show="scope.row.follow" />
                  <img src="@/assets/images/star.png"
                       alt=""
                       srcset=""
                       class="star"
                       v-show="!scope.row.follow"
                       @click="followCreate(scope.row)" />
                </template>
              </el-table-column>

              <el-table-column label="学员姓名"
                               width="100"
                               align="center"
                               fixed="left"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-link type="primary"
                           :underline="false"
                           @click="openStudentInfoDialog(scope.row)">{{ scope.row.realname }}</el-link>
                </template>
              </el-table-column>
              <el-table-column label="报名时间"
                               align="center"
                               width="120">
                <template slot-scope="scope">
                  {{
                    scope.row.apply_time
                      ? $moment(scope.row.apply_time).format("YYYY-MM-DD")
                      : ""
                  }}
                </template>
              </el-table-column>

              <el-table-column label="课程状态"
                               align="center"
                               width="80">
                <template slot-scope="scope">
                  <el-tag size="mini"
                          v-if="scope.row.student_sync_status == 1"
                          type="warning">待对接</el-tag>
                  <el-tag size="mini"
                          v-if="scope.row.student_sync_status == 2"
                          type="warning">待分配</el-tag>
                  <el-tag size="mini"
                          v-if="scope.row.student_sync_status == 3">匹配中</el-tag>
                  <el-tag size="mini"
                          v-if="scope.row.student_sync_status == 4">上课中</el-tag>
                  <el-tag size="mini"
                          v-if="scope.row.student_sync_status == 5"
                          type="info">已终止</el-tag>
                  <el-tag size="mini"
                          v-if="scope.row.student_sync_status == 6"
                          type="danger">已结束</el-tag>
                  <!--        <el-tag
              size="small"
              v-if="scope.row.docking_status == 0"
              type="info"
            >未对接</el-tag>
            <el-tag
              size="small"
              v-else-if="scope.row.course_status == 1"
              type="warning"
            >待上课</el-tag>
            <el-tag
              size="small"
              v-else-if="scope.row.course_status == 2"
            >上课中</el-tag>
            <el-tag
              size="small"
              type="info"
              v-else-if="scope.row.course_status == 3"
            >上课结束</el-tag> -->
                </template>
              </el-table-column>
              <el-table-column label="所在班级"
                               width="200"
                               align="left"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-dropdown trigger="click"
                               v-if="!scope.row.grade_names">
                    <el-link type="primary"
                             :underline="false">{{ formateMember(scope.row.grade_names) }}
                    </el-link>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="a">
                        <div class="dropdown-item"
                             @click="AddStudentClass(scope.row)">
                          新建班级
                        </div>
                      </el-dropdown-item>
                      <el-dropdown-item command="b">
                        <div class="dropdown-item"
                             @click="joinClass(scope.row)">
                          加入班级
                        </div>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <div v-else>
                    <el-link type="primary"
                             :underline="false"
                             @click="opengradeDialog(scope.row)">
                      {{ formateGrade_names(scope.row.grade_names) }}
                    </el-link>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="课程进度"
                               width="120"
                               align="center"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-progress :text-inside="true"
                               :stroke-width="20"
                               :percentage="
                      scope.row.course_progress
                        ? parseFloat(
                            (Number(scope.row.course_progress) * 100).toFixed(2)
                          )
                        : 0
                    "></el-progress>
                </template>
              </el-table-column>
              <el-table-column label="报考信息"
                               width="400"
                               align="left"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>
                    {{
                      scope.row.apply_year ? `${scope.row.apply_year}年` : ""
                    }}
                  </span>
                  /
                  <span class="pointer"
                        @click="institutionOpen(scope.row)">{{ scope.row.apply_college_name }} </span>/ ({{ scope.row.apply_major_code }}){{
                    scope.row.apply_major_name
                  }}
                  /
                  {{ scope.row.apply_academy_name }}
                  /
                  <!-- <span
                    v-for="(item, index) in scope.row.subjects || []"
                    :key="index"
                  >
                    ({{ item.subject_code }}){{ item.subject_name }}
                  </span> -->
                  <span v-if="scope.row.apply_direction_name">
                    {{
                      scope.row.apply_direction_code
                        ? `(${scope.row.apply_direction_code})${scope.row.apply_direction_name}`
                        : scope.row.apply_direction_name
                    }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column label="辅导科目"
                               width="160"
                               align="left"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-for="(item, index) in scope.row.subjects || []"
                        :key="index">
                    ({{ item.subject_code }}){{ item.subject_name }}
                  </span>
                </template>
              </el-table-column>

              <el-table-column width="100"
                               align="center"
                               show-overflow-tooltip>
                <template slot="header">
                  <el-popover ref="popover">
                    <span class="el-dropdown-link"
                          slot="reference">
                      报名课时<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <div class="popover-item"
                         @click="handleCommand(1)">
                      小时
                    </div>
                    <div class="popover-item"
                         @click="handleCommand(2)">
                      课时
                    </div>
                  </el-popover>
                </template>
                <template slot-scope="scope">
                  <div v-if="unitConversion == 1">
                    {{ scope.row.service_hours }}小时
                  </div>
                  <div v-if="unitConversion == 2">
                    {{
                      (
                        scope.row.service_hours /
                        scope.row.company.lesson_hour_hour_rate
                      ).toFixed(2)
                    }}课时
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="复习基础"
                               width="240"
                               align="left"
                               prop="coach_need"
                               show-overflow-tooltip>
              </el-table-column>
              <el-table-column label="参考书目"
                               width="240"
                               align="left"
                               prop="refer_book"
                               show-overflow-tooltip>
              </el-table-column>

              <el-table-column prop="remark"
                               align="left"
                               label="服务备注"
                               width="300"
                               show-overflow-tooltip>
              </el-table-column>

              <el-table-column label="跟进标签"
                               align="left"
                               width="200">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.follow_up_tag_ids"
                             multiple
                             filterable
                             allow-create
                             collapse-tags
                             default-first-option
                             placeholder="请选择跟进标签"
                             @change="
                      (...event) => {
                        tagsChange(event, scope.row);
                      }
                    ">
                    <el-option v-for="item in followUpTag"
                               :key="item.id"
                               :label="item.tag"
                               :value="item.id">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="跟进备注"
                               align="left"
                               width="260"
                               prop=""
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input @input="followUpRemark($event, scope.row)"
                            v-model="scope.row.follow_up_remark"
                            placeholder="请输入跟进备注"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="最近上课时间"
                               align="center"
                               width="150">
                <template slot-scope="scope">
                  {{
                    scope.row.last_lesson_time 
                      ? $moment(scope.row.last_lesson_time ).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column label="最近记录时间"
                               width="200"
                               align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.last_manage_record_create_time
                      ? $moment(
                          scope.row.last_manage_record_create_time
                        ).format("YYYY-MM-DD HH:mm")
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column label="最近记录内容"
                               width="200"
                               align="center">
                <template slot-scope="scope">
                  {{
                    scope.row.last_manage_record_content_text
                      ?
                          scope.row.last_manage_record_content_text
                      : ""
                  }}
                </template>
              </el-table-column>
              <el-table-column label="机构简称"
                               align="center"
                               width="130"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.company ? scope.row.company.name : "" }}
                </template>
              </el-table-column>
              <el-table-column label="对接老师"
                               align="center"
                               width="130"
                               show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ scope.row.docking_man_nickname || "无" }}</span>
                </template>
              </el-table-column>
              <el-table-column label="学管老师"
                               align="center"
                               width="130">
                <template slot-scope="scope">
                  {{
                    scope.row.learn_manager
                      ? scope.row.learn_manager.nickname
                      : ""
                  }}
                </template>
              </el-table-column>

              <el-table-column prop="name"
                               label="教务老师"
                               width="140"
                               align="center">
                <template slot-scope="scope">
                  <span>
                    {{
                      scope.row.watcher
                        ? scope.row.watcher.map((item) => item.alias).join(",")
                        : "-"
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="创建老师"
                               width="140"
                               align="center"
                               prop="manage_creator.nickname">
              </el-table-column>

              <el-table-column label="创建时间"
                               align="center"
                               width="150">
                <template slot-scope="scope">
                  {{
                    scope.row.create_time
                      ? $moment(scope.row.create_time).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : ""
                  }}
                </template>
              </el-table-column>

              <el-table-column label="更新时间"
                               align="center"
                               width="150">
                <template slot-scope="scope">
                  {{
                    scope.row.update_time
                      ? $moment(scope.row.update_time).format(
                          "YYYY-MM-DD HH:mm"
                        )
                      : ""
                  }}
                </template>
              </el-table-column>

              <!-- <el-table-column
                fixed="left"
                label="学习记录"
                align="center"
                width="120"
              >

                <template slot-scope="scope">
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="handleToGo('/classservice/record', scope.row)"
                    >课时</el-link
                  >
                  <el-link
                    type="primary"
                    :underline="false"
                    @click="
                      handleToGo('/classservice/plan/calendar', scope.row)
                    "
                    >课程表</el-link
                  >
                </template>
              </el-table-column> -->

              <!--
              <el-table-column
                label="最新记录"
                align="left"
                width="260"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div class="img-content">
                    <img
                      src="@/assets/images/128@2x.png"
                      alt=""
                      v-if="scope.row.recent_record_content_img"
                      @click="
                        openViewer(
                          JSON.parse(scope.row.recent_record_content_img)
                        )
                      "
                    />
                    {{
                      scope.row.recent_record_content_text
                        ? scope.row.recent_record_content_text
                        : "无"
                    }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="apply_year"
                label="考研年份"
                align="center"
                width="80"
              >
                <template slot-scope="scope">
                  {{ scope.row.apply_year ? `${scope.row.apply_year}年` : "" }}
                </template>
              </el-table-column>

              <el-table-column width="120" align="center" show-overflow-tooltip>
                <template slot="header">
                  <el-popover ref="popover">
                    <span class="el-dropdown-link" slot="reference">
                      已安排课时<i
                        class="el-icon-arrow-down el-icon--right"
                      ></i>
                    </span>
                    <div class="popover-item" @click="handleCommandOther(1)">
                      小时
                    </div>
                    <div class="popover-item" @click="handleCommandOther(2)">
                      课时
                    </div>
                  </el-popover>
                </template>
                <template slot-scope="scope">
                  <div v-if="unitConversion2 == 1">
                    {{ scope.row.sum_student_lesson_hour_arranged || 0 }}小时
                  </div>
                  <div v-if="unitConversion2 == 2">
                    {{
                      Number(
                        (
                          scope.row.sum_student_lesson_hour_arranged /
                          scope.row.company.lesson_hour_hour_rate
                        ).toFixed(2)
                      )
                    }}课时
                  </div>
                </template>
              </el-table-column>
              <el-table-column width="120" align="center" show-overflow-tooltip>
                <template slot="header">
                  <el-popover ref="popover">
                    <span class="el-dropdown-link" slot="reference">
                      已上课时<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <div class="popover-item" @click="handleCommandThree(1)">
                      小时
                    </div>
                    <div class="popover-item" @click="handleCommandThree(2)">
                      课时
                    </div>
                  </el-popover>
                </template>
                <template slot-scope="scope">
                  <div v-if="unitConversion3 == 1">
                    {{ scope.row.sum_student_lesson_log_lesson_hour || 0 }}小时
                  </div>
                  <div v-if="unitConversion3 == 2">
                    {{
                      Number(
                        (
                          scope.row.sum_student_lesson_log_lesson_hour /
                          scope.row.company.lesson_hour_hour_rate
                        ).toFixed(2)
                      )
                    }}课时
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                v-for="(item, index) in options"
                :key="index"
                :label="item.value"
                width="120"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <div :class="{ red: formateValue(scope.row, item) == 0 }">
                    {{ formateValue(scope.row, item) }}次
                  </div>
                </template>
              </el-table-column> -->

              <!-- <el-table-column
                prop="name"
                label="学员操作"
                width="120"
                align="center"
                fixed="right"
              >
                <template slot-scope="scope">
                  <div class="templateLink">
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="handleToEdit(scope.row)"
                      >编辑</el-link
                    >
                    <el-link
                      type="success"
                      :underline="false"
                      @click="handleToChangeStatus(scope.row)"
                      v-if="scope.row.docking_status == 0"
                      >已对接</el-link
                    >
                  </div>
                </template>
              </el-table-column> -->
              <el-table-column prop="name"
                               label="记录操作"
                               width="200"
                               align="center"
                               fixed="right">
                <template slot-scope="scope">
                  <div class="templateLink">
                    <el-link type="primary"
                             :underline="false"
                             @click="handleSchedule(scope.row)">进度</el-link>
                    <el-link type="primary"
                             :underline="false"
                             @click="handleToLog(scope.row)">记录</el-link>
                    <div class="badge">
                      <el-badge :value="scope.row.record_alt_unreaded_count"
                                class="item"
                                :hidden="!scope.row.record_alt_unreaded_count">
                        <el-link type="primary"
                                 :underline="false"
                                 @click="handleToLogDetail(scope.row)">查看</el-link>
                      </el-badge>
                    </div>
                  </div>
                </template></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="数据列表"
                       name="second"> 数据列表 </el-tab-pane>
        </el-tabs>
      </div>

      <div style="margin-top: 16px">
        <el-button size="mini"
                   @click="joinClassList">分配班级</el-button>
        <el-button size="mini"
                   @click="batchToChangeStatus">已对接</el-button>
        <!-- <el-button size="mini" @click="editLearn_manager">指定学管师</el-button>
        <el-button size="mini" @click="editMange_watcher">更换对接人</el-button>
        <el-button size="mini" @click="termination">批量终止</el-button> -->
        <div class="block">
          <el-pagination background
                         layout="total, prev, pager, next, sizes, jumper"
                         :current-page="Number(query.pageIndex)"
                         :page-sizes="[10, 20, 50, 100, 500]"
                         :page-size="Number(query.pageSize)"
                         :total="Number(pageTotal)"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </el-card>
    <StudentInfo :isVisible="StudentInfoDialog"
                 :content="StudentInfoContent"
                 @close="StudentInfoDialog = false"
                 @submit="fetchList" />
    <AddStudentClass :student_id="student_id"
                     :student_realname="student_realname"
                     :service_hour="service_hour"
                     :isVisible="createDialog"
                     @close="createDialog = false"
                     @submit="fetchList"
                     @getList="fetchList" />
    <ChooseClass :isVisible="chooseDialog"
                 @close="chooseDialog = false"
                 @submit="fetchList"
                 :student_id="chooseId"
                 :student_realname="student_realname"
                 :service_hour="service_hour" />

    <EditStudent :isVisible="editDialog"
                 @close="editDialog = false"
                 @submit="fetchList"
                 :id="editId" />
    <AppointLearnManager :isVisible="AppointLearnManagerDialog"
                         @close="AppointLearnManagerDialog = false"
                         @submit="fetchList"
                         :ids="editIds" />
    <EditManageWatcher :isVisible="EditManageWatcherDialog"
                       @close="EditManageWatcherDialog = false"
                       @submit="fetchList"
                       :ids="editIds" />
    <GradeList :isVisible="gradeDialog"
               @close="gradeDialogClose"
               @submit="fetchList"
               :chooseId="chooseId"
               :student_realname="student_realname"
               :student_id="nowStudent_id" />
    <Log :isVisible="logDialog"
         @close="logDialog = false"
         @submit="fetchList"
         :student_id="log_student_id"></Log>
    <el-image-viewer v-if="showViewer"
                     :url-list="imgs"
                     :zIndex="999999999"
                     :on-close="closeViewer" />
    <InstitutionDetails ref="institutionDetails"></InstitutionDetails>
    <schedule :isVisible="scheduleVisible"
              :scheduleRow="scheduleRow"
              @colse="scheduleVisible=false"></schedule>
  </div>
</template>

<script>
import StudentInfo from '@/components/DialogComponents/ClassService/StudentInfo.vue'
import AddStudentClass from '@/components/DialogComponents/ClassService/AddStudentClass.vue'
import ChooseClass from '@/components/DialogComponents/ClassService/ChooseClass.vue'
import EditStudent from '@/components/DialogComponents/ClassService/EditStudent.vue'
import AppointLearnManager from '@/components/DialogComponents/ClassService/AppointLearnManager.vue'
import EditManageWatcher from '@/components/DialogComponents/ClassService/EditManageWatcher.vue'
import GradeList from '@/components/DialogComponents/ClassService/GradeList.vue'
import Log from '@/components/DialogComponents/ClassService/Log.vue'
import schedule from '@/components/DialogComponents/ClassService/schedule.vue'
import TableTittle from '../../../components/TableTittle/TableTittle.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import InstitutionDetails from '@/components/DialogComponents/InstitutionDetails/index.vue'
import deviceLine from '../../../components/Line/Line.vue'
import {
  studentlist,
  editstudent,
  dictionaryroot,
  createuserfollow,
  deleteuserfollow,
  companystudentexport,
  editstudentlist,
  companyuserlist,
  followUpTagList,
  followUpTagAdd,
} from '@/request/api'
import { number } from 'mathjs'
export default {
  components: {
    TableTittle,
    StudentInfo,
    AddStudentClass,
    ChooseClass,
    EditStudent,
    AppointLearnManager,
    EditManageWatcher,
    GradeList,
    Log,
    schedule,
    ElImageViewer,
    InstitutionDetails,
    deviceLine,
  },
  data() {
    return {
      scheduleRow: {},
      scheduleVisible: false,
      affairsTeacherList: [],
      activeName: 'first',
      filtrate: '1',
      distributionNum: 0,

      TableName: '学员列表',
      tableData: [],
      query: {
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
        docking_statuses: '',
        is_statistic_service_record: 1,
        archive: 0,
      },
      pageTotal: 0,
      chooseList: [],
      StudentInfoDialog: false,
      StudentInfoContent: {},
      createDialog: false,
      chooseDialog: false,
      chooseId: null,
      editId: null,
      editDialog: false,
      AppointLearnManagerDialog: false,
      editIds: [],
      EditManageWatcherDialog: false,
      filters: {
        course_status: [
          { label: '待上课', value: 1 },
          { label: '上课中', value: 2 },
          { label: '上课结束', value: 3 },
        ],
        student_sync_statuses: [
          { label: '待对接', value: 1 },
          { label: '待分配', value: 2 },
          { label: '匹配中', value: 3 },
          { label: '上课中', value: 4 },
          { label: '已终止', value: 5 },
          { label: '已结束', value: 6 },
        ],
      },
      student_id: null,
      unitConversion: 1,
      unitConversion2: 1,
      unitConversion3: 1,
      gradeDialog: false,
      nowStudent_id: null,
      service_hour: 0,
      student_realname: '',
      apply_time: '',
      statusCount: 0,
      uncheck: 0,
      logDialog: false,
      log_student_id: null,
      timestampList: [
        {
          label: '1天内',
          value: 86400000,
          id: 1,
          type: 1,
        },
        {
          label: '7天内',
          value: 86400000 * 7,
          id: 2,
          type: 1,
        },
        {
          label: '超7天',
          value: 86400000 * 7,
          id: 3,
          type: 0,
        },
        {
          label: '超15天',
          value: 86400000 * 15,
          id: 4,
          type: 0,
        },
        {
          label: '超30天',
          value: 86400000 * 30,
          id: 5,
          type: 0,
        },
      ],
      timeArr: [
        {
          label: '全部',
          value: '全部',
        },
        {
          label: '一天内',
          value: [
            Number(this.$moment().startOf('day').format('x')) - 86400000,
            Number(this.$moment().startOf('day').format('x')),
          ],
        },
        {
          label: '七天内',
          value: [
            Number(this.$moment().startOf('day').format('x')) - 604800000,
            Number(this.$moment().startOf('day').format('x')),
          ],
        },
        {
          label: '十五天内',
          value: [
            Number(this.$moment().startOf('day').format('x')) - 1296000000,
            Number(this.$moment().startOf('day').format('x')),
          ],
        },
        {
          label: '一个月内',
          value: [
            Number(this.$moment().startOf('day').format('x')) - 2592000000,
            Number(this.$moment().startOf('day').format('x')),
          ],
        },
      ],
      // timeArr: [
      //   {
      //     label: '全部',
      //     value: "全部"
      //   },
      //   {
      //     label: '一天内',
      //     value: [Number(this.$moment().startOf('day').format('x')), Number(this.$moment().startOf('day').format('x')) + 86400000],
      //   },
      //   {
      //     label: '七天内',
      //     value: [Number(this.$moment().startOf('day').format('x')), Number(this.$moment().startOf('day').format('x')) + 604800000],
      //   },
      //   {
      //     label: '十五天内',
      //     value: [Number(this.$moment().startOf('day').format('x')), Number(this.$moment().startOf('day').format('x')) + 1296000000],
      //   },
      //   {
      //     label: '一个月内',
      //     value: [Number(this.$moment().startOf('day').format('x')), Number(this.$moment().startOf('day').format('x')) + 2592000000],
      //   },
      // ],
      options: [],
      tagOptions: [],
      lock: true,
      showViewer: false,
      imgs: [],
      followUpTag: [],
    }
  },
  created() {
    this.fetchDictionary()
    this.fetchTagDictionary()
    // this.parseQuery();
    this.getAffairsTeacher()
    // this.getDistribution();
    this.fetchList()
    this.getFollowUpTagList()
    this.$store.dispatch('getStaffOptions')
  },
  watch: {
    // 时间数据监听
    apply_time(value) {
      if (value) {
        this.query.start_apply_time = this.$moment(value[0]).valueOf()
        this.query.end_apply_time = this.$moment(value[1]).valueOf()
      } else {
        this.query.start_apply_time = ''
        this.query.end_apply_time = ''
        this.fetchList(true)
      }
    },
  },
  computed: {},
  methods: {
    gradeDialogClose() {
      this.gradeDialog = false
    },
    handleSchedule(row) {
      this.scheduleRow = row
      this.scheduleVisible = true
    },
    getFollowUpTagList() {
      followUpTagList({ isPaging: 0 }).then((res) => {
        this.followUpTag = res.data.data.list || []
        // console.log("resssss",res)
      })
    },
    getAffairsTeacher() {
      let query = {
        isPaging: 0,
        cid: localStorage.getItem('cid'),
      }
      companyuserlist(query).then((res) => {
        this.affairsTeacherList = res.data.data.list || []
        // console.log('this.affairsTeacherList:', this.affairsTeacherList)
      })
    },
    filtrateChange(e) {
      this.query.follow = 0
      this.query.docking_statuses = ''
      if (e == 2) {
        this.query.follow = 1
      } else if (e == 3) {
        this.query.docking_statuses = 0
      }
      this.fetchList(true)
    },
    handleClick(tab, event) {
      if (tab.name == 'first') {
        // this.query.is_statistic_data_table = "";
        this.fetchList(true)
      } else {
        // this.query.is_statistic_data_table = 1;
        // this.fetchList(true)
      }
    },
    institutionOpen(row) {
      this.$refs.institutionDetails.open(row.apply_college_code)
    },
    termination() {
      // console.log("list",this.chooseList)
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要终止的数据')
        return false
      }
      let data = []
      this.chooseList.forEach((item) => {
        data.push({
          id: item.id,
          course_status: 4,
        })
      })
      this.$confirm(`是否确定要终止?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          editstudentlist({
            rows: data,
          }).then((res) => {
            if (res.data.code == 0) {
              this.$message.success('终止成功')
              this.fetchList(true)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    fetchDictionary() {
      dictionaryroot({ code: 'LearnManageServiceType' }).then((response) => {
        let res = response.data
        this.options = res.data.values
      })
    },
    fetchTagDictionary() {
      dictionaryroot({ code: 'StudentTag' }).then((response) => {
        let res = response.data
        this.tagOptions = res.data.values
      })
    },
    fetchCheckCount() {
      studentlist({
        is_has_learn_manager: 0,
        isPaging: 1,
        pageIndex: 1,
        pageSize: 10,
      }).then((response) => {
        let res = response.data
        this.uncheck = res.data.count
      })
    },
    fetchDocking_statusCount() {
      const params = Object.assign({}, this.query, {
        pageIndex: 1,
        pageSize: 10,
        docking_statuses: 0,
        isPaging: 1,
      })
      if (params.course_statuses) {
        params.course_statuses = params.course_statuses.join(',')
      }
      if (params.learn_managers) {
        params.learn_managers = params.learn_managers.join(',')
      }
      if (params.docking_mans) {
        params.docking_mans = params.docking_mans.join(',')
      }
      if (params.student_sync_statuses) {
        params.student_sync_statuses = params.student_sync_statuses.join(',')
      }
      if (params.record_time == '全部') {
        params.record_time = ''
      }
      if (this.query.last_manage) {
        let obj = this.timestampList.find(
          (item) => item.id == this.query.last_manage
        )
        params.is_exist_manage_record = obj.type
        params.start_last_manage_record_time =
          this.$moment().unix() * 1000 - obj.value
        params.end_last_manage_record_time = this.$moment().unix() * 1000
      }

      if (this.query.last_lesson_time) {
        let obj = this.timestampList.find(
          (item) => item.id == this.query.last_lesson_time
        )
        params.is_exist_lesson_time = obj.type
        params.start_last_lesson_time = this.$moment().unix() * 1000 - obj.value
        params.end_last_lesson_time = this.$moment().unix() * 1000
      }
      params.is_get_last_record_lesson = 1
      delete params.last_manage
      studentlist(params).then((response) => {
        let res = response.data
        this.statusCount = res.data.count
        console.log('this.statusCount', this.statusCount)
      })
    },
    handleSizeChange(val) {
      this.query.pageSize = val
      this.fetchList()
    },
    handleCurrentChange(val) {
      this.query.pageIndex = val
      this.fetchList()
    },

    getDistribution() {
      let query = {
        isPaging: 0,
        docking_statuses: 0,
        is_has_learn_manager: '',
      }
      studentlist(query).then((response) => {
        let res = response.data
        this.distributionNum = res.data.count || 0
      })
    },
    fetchList(isReset = false) {
      if (isReset) {
        this.$set(this.query, 'pageIndex', 1)
      }
      const params = Object.assign({}, this.query)
      if (params.course_statuses) {
        params.course_statuses = params.course_statuses.join(',')
      }
      if (params.learn_managers) {
        params.learn_managers = params.learn_managers.join(',')
      }

      if (params.docking_mans) {
        params.docking_mans = params.docking_mans.join(',')
      }
      if (params.student_sync_statuses) {
        params.student_sync_statuses = params.student_sync_statuses.join(',')
      }
      if (params.record_time) {
        if (params.record_time != '全部') {
          params.service_record_start_time = params.record_time[0]
          params.service_record_end_time = params.record_time[1]
        } else {
          params.record_time = ''
        }
      }
      if (params.docking_statuses == 1) {
        params.is_has_learn_manager = 0
        params.docking_statuses = ''
      } else {
        params.is_has_learn_manager = ''
      }

      if (params.follow_up_tag_idses) {
        params.follow_up_tag_idses = params.follow_up_tag_idses.join(',')
      }

      if (this.query.last_manage) {
        let obj = this.timestampList.find(
          (item) => item.id == this.query.last_manage
        )
        params.is_exist_manage_record = obj.type
        params.start_last_manage_record_time =
          this.$moment().unix() * 1000 - obj.value
        params.end_last_manage_record_time = this.$moment().unix() * 1000
      }
      if (this.query.last_lesson_time) {
        let obj = this.timestampList.find(
          (item) => item.id == this.query.last_lesson_time
        )
        params.is_exist_lesson_time = obj.type
        params.start_last_lesson_time = this.$moment().unix() * 1000 - obj.value
        params.end_last_lesson_time = this.$moment().unix() * 1000
      }
      params.is_get_last_record_lesson = 1
      delete params.last_manage
      this.getDistribution()
      studentlist(params).then((response) => {
        let res = response.data
        this.pageTotal = res.data.count
        console.log('条数', res)
        this.tableData = []
        this.tableData =
          res.data.count > 0
            ? res.data.list.map((v) => {
                return {
                  ...v,
                  follow_up_tag_ids:
                    v.follow_up_tag_ids && v.follow_up_tag_ids.length
                      ? v.follow_up_tag_ids.split(',').map((t) => Number(t))
                      : [],

                  manage_tags_arr:
                    v.manage_tags && v.manage_tags.length
                      ? v.manage_tags.split(',').map((t) => {
                          return Number(t)
                        })
                      : [],
                }
              })
            : []
        // console.log(this.tableData);

        this.fetchDocking_statusCount()
        this.fetchCheckCount()
      })
      // this.syncQuery();
    },
    // 解析URL请求参数
    parseQuery() {
      Object.keys(this.$route.query).forEach((key) => {
        this.query[key] = this.$route.query[key]
      })
      if (this.query.course_statuses && this.query.course_statuses.length) {
        this.query.course_statuses = this.query.course_statuses
          .split(',')
          .map((v) => {
            return Number(v)
          })
      }
      if (this.query.learn_managers && this.query.learn_managers.length) {
        this.query.learn_managers = this.query.learn_managers
          .split(',')
          .map((v) => {
            return Number(v)
          })
      }
      if (this.query.docking_mans && this.query.docking_mans.length) {
        this.query.docking_mans = this.query.docking_mans
          .split(',')
          .map((v) => {
            return Number(v)
          })
      }
      if (
        this.query.student_sync_statuses &&
        this.query.student_sync_statuses.length
      ) {
        this.query.student_sync_statuses = this.query.student_sync_statuses
          .split(',')
          .map((v) => {
            return Number(v)
          })
      }
      this.query.follow = Number(this.query.follow)

      delete this.query.service_record_type_id
      delete this.query.service_record_frequecy
      delete this.query.service_record_start_time
      delete this.query.service_record_end_time
      delete this.query.record_time
    },
    // 同步URL请求参数
    syncQuery() {
      const params = Object.assign({}, this.query)
      let isSync = Object.keys(params).some(
        (key) => this.$route.query[key] != params[key]
      )
      if (params.course_statuses) {
        params.course_statuses = params.course_statuses
          .filter((v) => v)
          .join(',')
      }
      if (params.learn_managers) {
        params.learn_managers = params.learn_managers.filter((v) => v).join(',')
      }
      if (params.docking_mans) {
        params.docking_mans = params.docking_mans.filter((v) => v).join(',')
      }
      if (params.student_sync_statuses) {
        params.student_sync_statuses = params.student_sync_statuses
          .filter((v) => v)
          .join(',')
      }
      isSync &&
        this.$router.replace({
          query: Object.assign({}, this.$route.query, params),
        })
    },
    handleSelectionChange(val) {
      this.chooseList = val
    },
    formateMember(val) {
      if (val) {
        let val_arr = val.split(',')
        if (val_arr.length > 3) {
          return val.split(',').slice(0, 3).join('，') + '等'
        } else {
          return val.split(',').slice(0, 3).join('，')
        }
      } else {
        return '分配班级'
      }
    },
    collegeFormate(row) {
      if (
        row.apply_college_name &&
        row.apply_major_code &&
        row.apply_major_name
      ) {
        return `${row.apply_college_name},（${row.apply_major_code}）${row.apply_major_name}`
      } else {
        return '暂无定校'
      }
    },
    formateManage_Watcher(val) {
      if (!val) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += item.nickname + ','
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },
    openStudentInfoDialog(row) {
      this.StudentInfoContent = Object.assign({}, row)
      this.StudentInfoDialog = true
    },
    handleCommand(e) {
      this.unitConversion = e
      this.$refs['popover'].doClose()
    },
    handleCommandOther(e) {
      this.unitConversion2 = e
      this.$refs['popover'].doClose()
    },
    handleCommandThree(e) {
      this.unitConversion3 = e
      this.$refs['popover'].doClose()
    },
    AddStudentClass(row) {
      this.student_id = row.id
      this.service_hour = row.service_hours
      this.student_realname = row.realname
      this.createDialog = true
    },
    joinClass(row) {
      this.service_hour = row.service_hours
      this.chooseDialog = true
      this.student_realname = row.realname
      this.chooseId = [row.id]
    },
    handleToClassRecord(index, row) {
      let ids = row.grade_ids.split(',')
      this.$router.push(`/classservice/record?class_id=${ids[index]}`)
    },
    handleToEdit(row) {
      this.editId = row.id
      this.editDialog = true
    },
    editLearn_manager() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要修改的数据')
        return false
      }
      this.editIds = this.chooseList
      this.AppointLearnManagerDialog = true
    },
    joinClassList() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要修改的数据')
        return false
      }
      this.chooseId = this.chooseList.map((v) => {
        return v.id
      })
      this.chooseDialog = true
    },
    editMange_watcher() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要修改的数据')
        return false
      }
      this.editIds = this.chooseList
      this.EditManageWatcherDialog = true
    },
    //移除班级
    handleTorRemove(row) {
      this.$confirm('确定要将该学员从所有班级移除吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let query = {
          id: row.id,
          arr_grade: [],
        }
        editstudent(query).then((res) => {
          this.fetchList()
        })
      })
    },
    handleToGo(url, row) {
      window.open(`${url}?student_id=${row.id}`)
      /*   this.$router.push(`${url}?student_id=${row.id}`) */
    },
    formateGrade_names(val) {
      let arr = val ? val.split(',') : []
      if (arr.length > 1) {
        return `${arr.slice(0, 1).join('，')}等${arr.length}个班级`
      } else {
        return arr.join(',')
      }
    },
    opengradeDialog(row) {
      this.nowStudent_id = row.id
      this.student_realname = row.realname
      this.gradeDialog = true
      this.chooseId = [row.id]
    },
    batchToChangeStatus() {
      if (this.chooseList.length == 0) {
        this.$message.error('还没有选择要修改的数据')
        return false
      }
      let list = this.chooseList.filter((item) => item.docking_status == 0)
      if (!list || list.length <= 0) {
        this.$message.error('请选择未对接数据')
        return false
      }

      this.$confirm('确定批量已对接吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        list.forEach((item) => {
          let query = {
            id: item.id,
            docking_status: 1,
          }
          editstudent(query).then((res) => {
            this.fetchList()
          })
        })
      })
    },

    handleToChangeStatus(row) {
      this.$confirm('确定已对接该学员吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let query = {
          id: row.id,
          docking_status: 1,
        }
        editstudent(query).then((res) => {
          this.fetchList()
        })
      })
    },
    handleToLog(row) {
      this.logDialog = true
      this.log_student_id = row.id
    },
    handleToLogDetail(row) {
      window.open(`/classservice/student/record?student_id=${row.id}`)
      // this.$router.push(
      //   `/classservice/student/record?student_id=${row.id}`
      // );
    },
    formateValue(item, value) {
      let ind = item.arr_statistic_service_record
        ? item.arr_statistic_service_record.findIndex((v) => {
            return v.service_type_id == value.id
          })
        : -1
      if (ind > -1) {
        return item.arr_statistic_service_record[ind].service_count
      } else {
        return 0
      }
    },
    followUpRemark(e, row) {
      // console.log("e:", e, row.id);
      editstudent({
        id: row.id,
        follow_up_remark: e,
      }).then((res) => {
        // this.$message.success("修改成功");
        // this.fetchList();
      })
    },
    tagsChange(e, row) {
      // console.log("e:", e);
      let name = e[0].filter((item) => typeof item === 'string')
      let ids = e[0].filter((item) => typeof item === 'number') || []
      // console.log("ids:", ids);
      // console.log("name:", name); res.data.data.insertId
      if (name && name.length > 0) {
        followUpTagAdd({
          tag: name[0],
          sort: 1,
        }).then((res) => {
          ids.push(res.data.data.insertId)
          this.getFollowUpTagList()
          editstudent({
            id: row.id,
            follow_up_tag_ids: ids.join(','),
          }).then((res) => {
            this.$message.success('修改成功')
            this.fetchList()
          })
        })
      } else {
        editstudent({
          id: row.id,
          follow_up_tag_ids: ids.join(','),
        }).then((res) => {
          this.$message.success('修改成功')
          this.fetchList()
        })
      }
      // let data = {
      //   manage_tags: e[0] && e[0].length ? e[0].join(",") : "",
      //   id: item.id,
      // };

      // let data = {
      //   manage_tags: e[0] && e[0].length ? e[0].join(",") : "",
      //   id: item.id,
      // };
      // editstudent(data).then((res) => {
      //   if (res.data.code == 0) {
      //     this.$message.success("修改成功");
      //     this.fetchList();
      //   }
      // });
    },
    // 列表关注创建
    followCreate(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 8,
          follow_id: item.id,
        }
        createuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 500)
      }
    },
    // 列表关注删除
    followDelete(item) {
      let flag = false
      if (this.lock) {
        this.lock = false
        let query = {
          type: 8,
          follow_id: item.id,
        }
        deleteuserfollow(query).then((res) => {
          this.fetchList()
        })
        clearTimeout(flag)
        flag = setTimeout(() => {
          this.lock = true
        }, 300)
      }
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    },
    openViewer(url) {
      this.imgs = url.map((v) => {
        return v.url
      })
      this.showViewer = true
    },
    handleExport() {
      this.$confirm('是否导出数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const params = Object.assign({}, this.query)
        if (params.course_statuses) {
          params.course_statuses = params.course_statuses.join(',')
        }
        if (params.learn_managers) {
          params.learn_managers = params.learn_managers.join(',')
        }
        if (params.docking_mans) {
          params.docking_mans = params.docking_mans.join(',')
        }
        if (params.student_sync_statuses) {
          params.student_sync_statuses = params.student_sync_statuses.join(',')
        }
        if (params.record_time) {
          if (params.record_time != '全部') {
            params.service_record_start_time = params.record_time[0]
            params.service_record_end_time = params.record_time[1]
          } else {
            params.record_time = ''
          }
          // params.service_record_start_time = params.record_time[0]
          // params.service_record_end_time = params.record_time[1]
        }
        if (params.docking_statuses == 1) {
          params.is_has_learn_manager = 0
          params.docking_statuses = ''
        } else {
          params.is_has_learn_manager = ''
        }
        companystudentexport(params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success('导出成功')
            //创建a标签用于跳转至下载链接
            var str = res.data.data.url.replace(
              /http:/,
              window.location.protocol
            )
            this.$store.commit('downLoad', str)
          }
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.dropdown-item {
  height: 100%;
  width: 100%;
}

/deep/.el-badge__content {
  z-index: 99;
}

.red {
  color: #f36d78;
}

.templateLink {
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge {
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.star {
  width: 18px;
  height: 18px;
}

.card {
  /deep/.el-input__inner {
    border: none !important;
    background-color: transparent;
  }

  .img-content {
    display: flex;

    img {
      width: 22px;
      height: 22px;
      cursor: pointer;
      margin-right: 12px;
    }
  }
}
</style>
