<template>
  <div class="content">
    <el-dialog append-to-body
               top="5vh"
               :title="title"
               :visible.sync="dialogFormVisible"
               @close="close"
               :close-on-click-modal="false"
               width="626px">
      <div class="head">
        <div class="button-set">
          <el-badge :value="record_msg_unreaded_count"
                    :hidden="record_msg_unreaded_count == 0"
                    :max="99"
                    style="margin-right: 8px">
            <el-button type="primary"
                       size="small"
                       @click="handleToOrderRecord">沟通记录</el-button>
          </el-badge>
          <el-badge :hidden="allocation_order_teacher_unconfirm_count == 0"
                    :value="allocation_order_teacher_unconfirm_count"
                    :max="99"
                    style="margin-right: 8px">
            <el-button type="primary"
                       size="small"
                       @click="openEditMatching">修改匹配</el-button>
          </el-badge>

          <el-button type="danger"
                     size="small"
                     v-if="order && order.status != 6"
                     @click="cancelMatching">取消匹配</el-button>
        </div>
        <el-tabs v-model="activeName"
                 type="card"
                 @tab-click="handleClick">
          <el-tab-pane label="老师列表"
                       name="first"> </el-tab-pane>
          <el-tab-pane label="确认列表"
                       name="second"></el-tab-pane>
        </el-tabs>

        <div v-if="activeName == 'first'">
          <el-empty :image-size="200"
                    v-if="!results || results.length <= 0"></el-empty>
          <div class="card"
               v-for="(item, index) in results"
               :key="index">
            <div class="card-title">{{item.hide_name_status== 1? `${item.realname}(${item.nickname})`:item.realname }}</div>

            <div class="descriptions">
              <div class="chunk"
                   style="margin-right: 77px">
                <div class="chunk-row">
                  <div>性别</div>
                  <div>{{ item.gender == 1 ? "男" : "女" }}</div>
                </div>
                <div class="chunk-row">
                  <div>班级授课状态</div>
                  <div>
                    <el-tag size="mini"
                            v-show="item.teacher_grade_status == 1">正常</el-tag>
                    <el-tag size="mini"
                            type="info"
                            v-show="item.teacher_grade_status == 0">终止</el-tag>
                  </div>
                </div>
                <div class="chunk-row">
                  <div>创建日期</div>
                  <div>
                    {{
                      item.create_time
                        ? $moment(item.create_time).format("YYYY-MM-DD HH:mm")
                        : ""
                    }}
                  </div>
                </div>
              </div>

              <div class="chunk">
                <div class="chunk-row">
                  <div>课时费</div>
                  <div>
                    <span v-if="item.teacher_salary_split_type==1">
                      {{
                      item.teacher_lesson_hour_amount
                        ? item.teacher_lesson_hour_amount.toFixed(2)
                        : 0
                    }}元/小时

                    </span>
                    <span v-if="item.teacher_salary_split_type==2"> {{item.teacher_salary_profit_fix_amount ?item.teacher_salary_profit_fix_amount.toFixed(2) :0}} +
                      {{item.teacher_bonus_profit_fix_amount?item.teacher_bonus_profit_fix_amount.toFixed(2):0}}元/小时</span>

                    <!-- {{
                      item.teacher_lesson_hour_amount
                        ? item.teacher_lesson_hour_amount.toFixed(2)
                        : 0
                    }}元/小时 -->
                  </div>
                </div>
                <!-- <div class="chunk-row">
                  <div>创建人</div>
                  <div>6231</div>
                </div> -->
                <div class="chunk-row">
                  <div>修改日期</div>
                  <div>
                    {{
                      item.update_time
                        ? $moment(item.update_time).format("YYYY-MM-DD HH:mm")
                        : ""
                    }}
                  </div>
                </div>

                <div class="chunk-row">
                  <div>备注</div>
                  <div>{{ item.manage_remark || "--" }}</div>
                </div>
              </div>
            </div>

            <div class="button-set-bottom">
              <el-button size="small"
                         @click="handleToDetail(item)">查看详情</el-button>
              <el-button size="small"
                         @click="handleToEditLessonHourAmount(item)">修改课时费</el-button>
              <el-button type="danger"
                         size="small"
                         plain
                         v-if="item.teacher_grade_status == 1"
                         @click="handleToChangeStatus(item, '确定要将老师移除班级吗', 0)">移除班级</el-button>
              <el-button size="small"
                         @click="
                  handleToChangeStatus(
                    item,
                    '确定要将老师重新加入班级吗',
                    1
                  )
                "
                         v-if="item.teacher_grade_status == 0">加入班级</el-button>

              <!-- <el-link
              type="primary"
              :underline="
              false"
              @click="handleToChangeStatus(scope.row,'确定要将老师重新加入班级吗',1)"
              v-if="scope.row.teacher_grade_status==0"
            >加入班级</el-link> -->
            </div>
          </div>
        </div>

        <div v-else
             v-loading="loading">
          <el-empty :image-size="200"
                    v-if="!confirm_result || confirm_result.length <= 0"></el-empty>
          <div class="card"
               v-for="(item, index) in confirm_result"
               :key="index">
            <div class="card-title">{{item.hide_name_status== 1? `${item.realname}(${item.nickname})`:item.realname }}</div>
            <div class="descriptions">
              <div class="chunk"
                   style="margin-right: 77px">
                <div class="chunk-row">
                  <div>ID</div>
                  <div>{{ item.id }}</div>
                </div>
                <div class="chunk-row">
                  <div>状态</div>
                  <div>
                    <el-tag size="small"
                            v-if="formateStatus(item, 0)"
                            type="warning">待确认</el-tag>
                    <el-tag size="small"
                            v-if="formateStatus(item, 1)"
                            type="success">已确认</el-tag>
                    <el-tag type="danger"
                            size="small"
                            v-if="formateStatus(item, 2)">已驳回</el-tag>
                  </div>
                </div>
                <div class="chunk-row">
                  <div>录取学院</div>
                  <div>
                    {{ item.recruit_college_name }}
                  </div>
                </div>
                <div class="chunk-row">
                  <div>课时费</div>
                  <div>
                    <span v-if="item.salary_split_type==1"> {{
                      item.sign_order_lesson_hour_amount
                        ? item.sign_order_lesson_hour_amount
                        : 0
                    }}元/小时</span>
                    <span v-if="item.salary_split_type==2">
                      {{item.salary_profit_fix_amount ? item.salary_profit_fix_amount:0}} + {{item.bonus_profit_fix_amount ?item.bonus_profit_fix_amount:0 }}

                    </span>
                    <!-- {{
                      item.sign_order_lesson_hour_amount
                        ? item.sign_order_lesson_hour_amount
                        : 0
                    }}元/小时 -->
                  </div>
                </div>
                <div class="chunk-row">
                  <div>手机号</div>
                  <div>{{ item.mobile }}</div>
                </div>
                <div class="chunk-row">
                  <div>匹配师</div>
                  <div>
                    {{ formateAllocation_manage_id(item) }}
                  </div>
                </div>
                <div class="chunk-row">
                  <div>签约课时费</div>
                  <div>{{ item.fee_amount ? item.fee_amount : 0 }}元/小时</div>
                </div>
              </div>

              <div class="chunk">
                <div class="chunk-row">
                  <div>年级</div>
                  <div>{{ item.recruit_year }}年</div>
                </div>
                <div class="chunk-row">
                  <div>录取院校</div>
                  <div>
                    {{ item.recruit_academy_name }}
                  </div>
                </div>
                <div class="chunk-row">
                  <div>录取专业</div>
                  <div>
                    <!-- ({{item.recruit_major_code}}) -->
                    {{ item.recruit_major_name }}
                  </div>
                </div>
                <div class="chunk-row">
                  <div>本单课时</div>
                  <div>
                    {{
                      item.sign_order_lesson_hour
                        ? item.sign_order_lesson_hour
                        : 0
                    }}小时
                  </div>
                </div>
                <div class="chunk-row">
                  <div>微信号</div>
                  <div>{{ item.wechat }}</div>
                </div>
                <div class="chunk-row">
                  <div>签约有效期</div>
                  <div>
                    {{
                      item.contract_end_time
                        ? $moment(item.contract_end_time).format(
                            "YYYY-MM-DD HH:mm"
                          )
                        : ""
                    }}
                  </div>
                </div>
                <div class="chunk-row">
                  <div>平均课时费</div>
                  <div>
                    {{
                     (item.teacher_average_lesson_hour_amount
                        ? item.teacher_average_lesson_hour_amount.toFixed(2)
                        : 0) 
                    }}元/小时
                  </div>
                </div>
              </div>
            </div>

            <div class="button-set-bottom">
              <el-button size="small"
                         @click="handleToDetail(item)">查看详情</el-button>
              <el-button type="primary"
                         size="small"
                         @click="handleToConfirm(item)">确认</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <ChooseTeacher :id="ChooseTeacherId"
                   :isVisible="chooseDialog"
                   @close="chooseDialog = false"
                   @submit="fetchDetail()" />
    <EditLessonHourAmount :id="editId"
                          :gradTd="id"
                          :class_id="this.id"
                          :isVisible="editDialog"
                          @close="editDialog = false"
                          @submit="fetchDetail()" />
    <SeniorDetail :isVisible="SeniorDetailDialog"
                  @close="SeniorDetailDialog = false"
                  :id="detailId" />
    <ConfirmGradeTeacher :isVisible="confirmDialog"
                         @close="confirmDialog = false"
                         :id="confirmId"
                         @submit="fetchDetail" />
    <EditMatching :isVisible="editMatchingDialog"
                  @close="editMatchingDialog = false"
                  :id="editMatchingId"
                  @submit="fetchDetail"
                  :teachers="teachers" />

    <MatchingCancle :isVisible="cancelDialog"
                    @close="cancelDialog = false"
                    :id="order_id"
                    @submit="fetchDetail" />
    <el-image-viewer v-if="showViewer"
                     :url-list="imgs"
                     :zIndex="999999999"
                     :on-close="closeViewer"
                     :initial-index="initialIndex" />
    <Record :teacher_allocation_order_id="order_id"
            :isVisible="recordDialog"
            @close="recordDialog = false"
            :grade_id="id"
            @submit="fetchDetail()"
            :type="'grade_read'"></Record>
  </div>
</template>

<script>
import {
  gradeOne,
  gradeEdit,
  commonHandleLogCreate,
  teacherlist,
  gradelist,
  taOne,
  taRecordList,
} from '@/request/api'
import ChooseTeacher from '@/components/DialogComponents/ClassService/ChooseTeacher.vue'
import EditLessonHourAmount from '@/components/DialogComponents/ClassService/EditLessonHourAmount.vue'
import SeniorDetail from '@/components/DialogComponents/TeacherResources/SeniorDetail.vue'
import ConfirmGradeTeacher from '@/components/DialogComponents/TeacherResources/ConfirmGradeTeacher.vue'
import EditMatching from '@/components/DialogComponents/TeacherResources/EditMatching.vue'
import MatchingCancle from '@/components/DialogComponents/TeacherResources/MatchingCancle.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import Record from '@/components/DialogComponents/TeacherResources/Record.vue'
export default {
  components: {
    ChooseTeacher,
    EditLessonHourAmount,
    SeniorDetail,
    ConfirmGradeTeacher,
    EditMatching,
    MatchingCancle,
    ElImageViewer,
    Record,
  },
  data() {
    return {
      initialIndex: 0,
      title: '班级老师',
      dialogFormVisible: false,
      activeName: 'first',
      id: null,
      order_id: null,
      pageTotal: 10,
      results: [],
      ChooseTeacherId: null,
      chooseDialog: false,
      editId: null,
      editDialog: false,
      confirm_result: [],
      editMatchingId: null,
      editMatchingDialog: false,
      SeniorDetailDialog: false,
      detailId: null,
      confirmDialog: false,
      confirmId: null,
      order: null,
      loading: false,
      cancelDialog: false,
      cancelId: null,
      teachers: [],
      expands: [],
      showViewer: false,
      imgs: [],
      recordDialog: false,
      detail: {},
      record_msg_unreaded_count: 0,
      allocation_order_teacher_unconfirm_count: 0,
    }
  },
  created() {},
  methods: {
    open(data) {
      this.results = []
      this.confirm_result = []
      // console.log('data:', data)
      this.id = data.id
      this.order_id = data.confirmOrder_id
      if (data.type == '确认列表') {
        this.activeName = 'second'
      } else {
        this.activeName = 'first'
      }

      this.dialogFormVisible = true

      this.fetchDetail()
    },
    close() {
      this.$emit('submit')
      this.dialogFormVisible = false
      // console.log('close:')
    },
    handleToOrderRecord() {
      this.recordDialog = true
    },
    handleClick() {},
    closeViewer() {
      this.showViewer = false
    },
    openViewer(url, index) {
      this.imgs = url.map((v) => {
        return v.url
      })
      this.initialIndex = index
      this.showViewer = true
    },
    expandChange(row) {
      let params = {
        teacher_allocation_order_id: this.$route.params.id,
        teacher_id: row.id,
        isPaging: 0,
      }
      taRecordList(params).then((response) => {
        let res = response.data
        this.$set(row, 'records', res.data.list)
      })
    },
    fetchOrderDetail() {
      taOne({
        id: this.order_id,
      }).then((response) => {
        let res = response.data
        this.order = res.data || []
      })
    },
    emitClose() {
      this.$emit('close')
    },
    fetchDetail() {
      gradeOne({ id: this.id }).then((response) => {
        this.fetchOrderDetail()
        let res = response.data
        this.detail = res.data
        this.results = res.data.teachers
        console.log('this.results:', this.results)
        this.fetchConfirmDetail()
      })
    },
    fetchConfirmDetail() {
      gradelist({ ids: this.id }).then((response) => {
        let res = response.data
        // console.log("ll", res);
        this.teachers = res.data.list[0].allocation_order_teacher_refs
        this.record_msg_unreaded_count =
          res.data.list[0].record_msg_unreaded_count || 0
        this.allocation_order_teacher_unconfirm_count =
          res.data.list[0].allocation_order_teacher_unconfirm_count || 0
        this.order_teachers = res.data.list[0].allocation_order_teacher_refs
        if (res.data.list[0].allocation_order_teacher_refs) {
          let ids = res.data.list[0].allocation_order_teacher_refs
            .map((v) => {
              return v.teacher_id
            })
            .join(',')
          if (ids) {
            this.fetchConfirmList(
              ids,
              res.data.list[0].allocation_order_obj
                ? res.data.list[0].allocation_order_obj.id
                : ''
            )
          } else {
            this.confirm_result = []
          }
        }
      })
    },
    fetchConfirmList(ids, order_id = '') {
      this.loading = true
      const params = {
        isPaging: 0,
        ids: ids,
        handle_teacher_allocaton_order_id: order_id,
      }
      teacherlist(params).then((response) => {
        let res = response.data
        this.confirm_result = res.data.list || []
        console.log('this.confirm_result:', this.confirm_result)
        this.loading = false
      })
    },
    // 选择
    handleSelectionChange(val) {
      this.selections = val
      this.diffSelection()
    },
    handleClose() {
      // 检索条件重置
      this.query = {
        keyword: '',
        type: '',
        status: '',
        pageIndex: 1,
        pageSize: 10,
        isPaging: 1,
      }
      this.$emit('close')
    },

    collegeFormate(row) {
      if (
        row.apply_college_name &&
        row.apply_major_code &&
        row.apply_major_name
      ) {
        return `${row.apply_college_name},（${row.apply_major_code}）${row.apply_major_name}`
      } else {
        return '暂无定校'
      }
    },
    formateManage_Watcher(val) {
      if (!val) {
        return '无'
      }
      var str = ''
      val.forEach((item) => {
        str += item.nickname + ','
      })
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (str.length > 0) {
        return (str = str.substr(0, str.length - 1))
      } else {
        return '无'
      }
    },
    handleToChoose() {
      this.ChooseTeacherId = this.id
      this.chooseDialog = true
    },
    handleToSubmit() {
      this.$emit('submit')
      this.handleClose()
    },
    handleToEditLessonHourAmount(row) {
      console.log('kkk', row)
      this.editId = row.id
      this.editDialog = true
    },
    handleToChangeStatus(row, text, status) {
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        row.teacher_grade_status = status
        let query = {
          id: this.id,
          arr_teacher: this.results.map((v) => {
            return {
              teacher_id: v.id,
              teacher_grade_status: v.teacher_grade_status,
            }
          }),
        }
        gradeEdit(query).then((res) => {
          if (res.data.code == 0) {
            this.createLog(row, status)
          }
        })
      })
    },
    createLog(row, status) {
      let query = {
        ref_id: this.id,
        handle_type: 0,
        handle_child_type: status == 1 ? 4 : 5,
        handle_content:
          status == 1
            ? `邀请${row.realname}老师加入班级，课时费${
                row.teacher_lesson_hour_amount || 0
              }元/小时`
            : `将${row.realname}老师移出了班级`,
      }
      commonHandleLogCreate(query).then((res) => {
        if (res.data.code == 0) {
          this.fetchDetail()
          this.$emit('submit')
        }
      })
    },
    formateAllocation_manage_id(row) {
      // console.log("this.order_teachers:", this.order_teachers);
      var obj
      var ids
      if (this.order_teacher && this.order_teacher.length) {
        obj = this.order_teachers.find((v) => {
          return v.teacher_id == row.id
        })
        ids = obj ? obj.allocation_manage_id : null
      } else {
        return '无'
      }

      //  console.log('ids:', ids)
      if (ids) {
        let arr = ids
          .toString()
          .split(',')
          .map((v) => {
            return this.$store.state.staffOptions.find((t) => {
              return t.id == v
            })?.nickname
          })
        return arr.join(',')
      } else {
        return '无'
      }
    },
    openEditMatching() {
      this.editMatchingId = this.order_id
      this.editMatchingDialog = true
    },
    formateStatus(row, status) {
      // console.log('xxx',this.order_teachers.length);
      if (this.order_teachers && this.order_teachers.length) {
        let number = this.order_teachers.find((v) => {
          return v.teacher_id == row.id
        }).confirm_status
        if (number == status) {
          return true
        } else {
          return false
        }
      }
    },
    handleToDetail(row) {
      this.detailId = row.id
      this.SeniorDetailDialog = true
    },
    handleToConfirm(row) {
      let id = this.order_teachers.find((v) => {
        return v.teacher_id == row.id
      })?.id
      this.confirmId = id
      this.confirmDialog = true
    },
    formateAltManager(ids) {
      if (ids) {
        let str = ids.split(',').map((v) => {
          return `@${
            this.$store.state.staffOptions.find((t) => {
              return t.id == v
            }).nickname
          }`
        })

        return str.join(',')
      } else {
        return ''
      }
    },
    cancelMatching() {
      this.cancelDialog = true
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.el-badge__content.is-fixed {
  right: 14px;
  z-index: 999999 !important;
}

.button-set > .el-badge:nth-of-type(2) /deep/.el-badge__content.is-fixed {
  right: 318px;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item {
  background: #ecf2fe;
  border-top: 2px solid #ecf2fe;
}
/deep/.el-tabs__item.is-active {
  background: white !important;
  border-top: 2px solid #1890ff !important;
}
div {
  box-sizing: border-box;
}
.head {
  position: relative;
  .button-set {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
  }

  .card {
    width: 100%;
    // height: 317px;
    margin-top: 7px;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.08),
      0px 16px 24px 2px rgba(0, 0, 0, 0.04),
      0px 6px 30px 5px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 16px 18px;
    margin-bottom: 20px;
    .card-title {
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 22px;
    }
    .descriptions {
      display: flex;
      justify-content: space-between;
      .chunk {
        flex: 1;
        .chunk-row {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          line-height: 22px;
          display: flex;
          margin-top: 8px;
          justify-content: space-between;
          & > div:nth-of-type(1) {
            flex-shrink: 0;
            color: #252525;
            width: 90px;
          }
          & > div:nth-of-type(2) {
            color: #6b6b6b;
          }
        }
      }
    }

    .button-set-bottom {
      margin-top: 18px;
      display: flex;
      justify-content: flex-end;
      // flex-direction: flex-start;
    }
  }
}
</style>